import {
  collection,
  query,
  orderBy,
  Unsubscribe,
  where,
  Timestamp,
  QuerySnapshot,
  DocumentData,
  DocumentReference,
  updateDoc
} from "firebase/firestore"
import { firestoreDb } from "firebase.init"
import { User } from "firebase/auth"
import { UserProfileData } from "types/user/user-profile-data"
import { firestoreDebounce } from "forge/core/utils/firestore"
import { ForgeEncryption } from "forge/core/services/encryption"

class MilestonesFirestore {
  private user: User
  private userProfileData: UserProfileData
  private encryptionService: ForgeEncryption

  constructor(user: User, userProfileData: UserProfileData, encryptionService: ForgeEncryption) {
    this.user = user
    this.userProfileData = userProfileData
    this.encryptionService = encryptionService
  }

  getPersonalMilestonesLive = (onEvent: (milestones: any[]) => void): Unsubscribe => {
    const startDate = new Date()
    startDate.setMonth(startDate.getMonth() - 1)

    const endDate = new Date()
    endDate.setMonth(endDate.getMonth() + 1)

    let collectionRef = query(
      collection(firestoreDb, `users/${this.user.uid}/milestones`),
      where("startDate", ">=", Timestamp.fromDate(startDate)),
      where("startDate", "<=", Timestamp.fromDate(endDate)),
      orderBy("startDate", "asc")
    )

    return firestoreDebounce(collectionRef, (snapshot: QuerySnapshot<DocumentData, DocumentData>) => {
      onEvent(
        snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
      )
    })
  }

  getOrganizationMilestonesLive = (onEvent: (profileOptions: any[]) => void): Unsubscribe => {
    if (this.userProfileData?.doesUserBelongsToAnOrganization) {
      const startDate = new Date()
      startDate.setMonth(startDate.getMonth() - 1)

      const endDate = new Date()
      endDate.setMonth(endDate.getMonth() + 1)

      const collectionRef = query(
        collection(firestoreDb, `organizations/${this.userProfileData.organization?.id}/milestones`),
        where("startDate", ">=", Timestamp.fromDate(startDate)),
        where("startDate", "<", Timestamp.fromDate(endDate)),
        orderBy("startDate")
      )

      return firestoreDebounce(collectionRef, (snapshot: QuerySnapshot<DocumentData, DocumentData>) => {
        onEvent(
          snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
        )
      })
    }

    return
  }

  updateMilestoneCompletion = async (ref: DocumentReference, completed: boolean): Promise<void> => {
    await updateDoc(ref, { completed })
    return
  }
}

export default MilestonesFirestore
