import React, { ReactNode } from "react"
import { CommitmentsContextProvider } from "forge/commitments/services/CommitmentsContext"
import { AlertsContextProvider } from "forge/home/services/AlertsContext"
import { MilestonesContextProvider } from "forge/milestones/services/MilestonesContext"

interface Props {
  children: ReactNode
}

const ContextWrapper: React.FC<Props> = ({ children }) => {
  return (
    <CommitmentsContextProvider>
      <AlertsContextProvider>
        <MilestonesContextProvider>{children}</MilestonesContextProvider>
      </AlertsContextProvider>
    </CommitmentsContextProvider>
  )
}

export default ContextWrapper
