import { OutlookRecurrence } from "./outlook-recurrence"

export class ForgeRecurrence {
  recurrent: boolean
  recurrenceId?: string
  googleRecurrence?: string[]
  outlookRecurrence?: OutlookRecurrence

  constructor({
    recurrent,
    recurrenceId,
    googleRecurrence,
    outlookRecurrence
  }: {
    recurrent: boolean
    recurrenceId?: string
    googleRecurrence?: string[]
    outlookRecurrence?: OutlookRecurrence
  }) {
    this.recurrent = recurrent
    this.recurrenceId = recurrenceId
    this.googleRecurrence = googleRecurrence
    this.outlookRecurrence = outlookRecurrence
  }

  copyWith({
    recurrent,
    recurrenceId,
    googleRecurrence,
    outlookRecurrence
  }: {
    recurrent?: boolean
    recurrenceId?: string
    googleRecurrence?: string[]
    outlookRecurrence?: OutlookRecurrence
  }): ForgeRecurrence {
    return new ForgeRecurrence({
      recurrent: recurrent ?? this.recurrent,
      recurrenceId: recurrenceId ?? this.recurrenceId,
      googleRecurrence: googleRecurrence ?? this.googleRecurrence,
      outlookRecurrence: outlookRecurrence ?? this.outlookRecurrence
    })
  }

  toMap(): Record<string, any> {
    const recurrenceMap: Record<string, any> = {
      recurrent: this.recurrent,
      recurrenceId: this.recurrenceId,
      googleRecurrence: this.googleRecurrence,
      outlookRecurrence: this.outlookRecurrence ? this.outlookRecurrence.toMap() : null
    }

    // Remove undefined properties
    for (const key in recurrenceMap) {
      if (recurrenceMap[key] === undefined) {
        delete recurrenceMap[key]
      }
    }

    return recurrenceMap
  }

  static fromMap(map: Record<string, any>): ForgeRecurrence {
    return new ForgeRecurrence({
      recurrent: map["recurrent"] ?? false,
      recurrenceId: map["recurrenceId"],
      googleRecurrence: map["googleRecurrence"],
      outlookRecurrence: map["outlookRecurrence"] ? OutlookRecurrence.fromMap(map["outlookRecurrence"]) : undefined
    })
  }

  get frequency(): string | null {
    if (this.googleRecurrence && this.googleRecurrence.length > 0) {
      const recurrenceRule = this.googleRecurrence[0]

      if (recurrenceRule.includes("RRULE:FREQ=DAILY;")) {
        return "daily"
      }

      if (recurrenceRule.includes("RRULE:FREQ=WEEKLY;")) {
        return "weekly"
      }

      if (recurrenceRule.includes("RRULE:FREQ=MONTHLY;")) {
        return "monthly"
      }

      if (recurrenceRule.includes("RRULE:FREQ=YEARLY;")) {
        return "yearly"
      }
    } else if (this.outlookRecurrence) {
      const type = this.outlookRecurrence.pattern.type

      if (type === "daily") {
        return "daily"
      }

      if (type === "weekly") {
        return "weekly"
      }

      if (type === "absoluteMonthly") {
        return "absoluteMonthly"
      }

      if (type === "absoluteYearly") {
        return "absoluteYearly"
      }
    }

    return null
  }

  get onADate(): Date | null {
    if (this.googleRecurrence && this.googleRecurrence.length > 0) {
      const recurrenceRule = this.googleRecurrence[0]
      const recurrenceRuleSplit = recurrenceRule.split(";")

      const untilCondition = recurrenceRuleSplit.find(e => e.includes("UNTIL="))
      if (untilCondition) {
        const untilDate = untilCondition.replace("UNTIL=", "")
        let date = new Date(untilDate)

        // Check if the parsed date is valid
        if (isNaN(date.getTime())) {
          // If the parsed date is invalid, use manual parsing approach
          const year = parseInt(untilDate.substring(0, 4))
          const month = parseInt(untilDate.substring(4, 6)) - 1 // Subtract 1 since months are zero-indexed
          const day = parseInt(untilDate.substring(6, 8))

          date = new Date(year, month, day)
        }
        return date
      }
    } else if (this.outlookRecurrence && this.outlookRecurrence.range.type === "endDate") {
      const endDate = this.outlookRecurrence.range.endDate
      if (endDate) {
        let date = new Date(endDate)

        // Check if the parsed date is valid
        if (isNaN(date.getTime())) {
          // If the parsed date is invalid, use manual parsing approach
          const year = parseInt(endDate.substring(0, 4))
          const month = parseInt(endDate.substring(4, 6)) - 1 // Subtract 1 since months are zero-indexed
          const day = parseInt(endDate.substring(6, 8))

          date = new Date(year, month, day)
        }
        return date
      }
    }

    return null
  }

  get xOccurrences(): number | null {
    if (this.googleRecurrence && this.googleRecurrence.length > 0) {
      const recurrenceRule = this.googleRecurrence[0]
      const recurrenceRuleSplit = recurrenceRule.split(";")

      const countCondition = recurrenceRuleSplit.find(e => e.includes("COUNT="))
      if (countCondition) {
        const count = countCondition.replace("COUNT=", "")
        return parseInt(count, 10)
      }
    } else if (this.outlookRecurrence && this.outlookRecurrence.range.type === "numbered") {
      const numberOfOccurrences = this.outlookRecurrence.range.numberOfOccurrences
      if (typeof numberOfOccurrences === "number") {
        return numberOfOccurrences
      } else if (typeof numberOfOccurrences === "string") {
        return parseInt(numberOfOccurrences, 10)
      }
    }

    return null
  }
}
