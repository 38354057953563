import dayjs from "dayjs"

export const timeFrame = (linkedInData: any): string | undefined => {
  var startsAt = toDate(linkedInData?.starts_at)
  var endsAt = toDate(linkedInData?.ends_at)

  if (startsAt && endsAt) {
    return `${dayjs(startsAt).format("MMM. YYYY")} - ${dayjs(endsAt).format("MMM. YYYY")}`
  } else if (startsAt) {
    return `${dayjs(startsAt).format("MMM. YYYY")} - Present`
  }

  return
}

const toDate = (linkedInDate: any): Date | null => {
  if (linkedInDate?.year) {
    const year = linkedInDate.year!
    const month = linkedInDate.month ?? 1
    const day = linkedInDate.day ?? 1
    return new Date(year, month - 1, day) // month is zero-based in JavaScript
  }
  return null
}

export const getBachelors = (education: any[]): any => {
  const bachelorsSynonyms = ["Bachelors", "BA", "BSc", "AB", "BS", "BSBA", "Bachelor", "Bachelor's"]

  return education?.find(educationEntry =>
    bachelorsSynonyms.some(synonym => educationEntry.degree_name && new RegExp(`\\b${synonym}\\b`, "i").test(educationEntry.degree_name))
  )
}
