import { useContext, useEffect, useState } from "react"

// MUI
import { Box, OutlinedInput } from "@mui/material"
import { Search } from "@mui/icons-material"

// Material Dashboard
import MDBox from "components/MDBox"

// Services
import { MembersContext } from "../../services/MembersContext"
import MemberCard from "../MemberCard"
import { ForgeOrganizationMember } from "forge/organization/types/member"
import MDTypography from "components/MDTypography"

function SelectMember({
  onMemberSelected,
  onMemberUnselected,
  filteredIds = []
}: {
  onMemberSelected: (member: any) => void
  onMemberUnselected?: (member: any) => void
  filteredIds?: string[]
}) {
  const { members } = useContext(MembersContext)

  // Members
  const [selectedMembers, setSelectedMembers] = useState([])
  const [baseMembers, setBaseMembers] = useState([])
  const [filteredMembers, setFilteredMembers] = useState([])

  // Search
  const [searchQuery, setSearchQuery] = useState("")

  useEffect(() => {
    setBaseMembers(members.filter(member => member.status === "active"))
  }, [members])

  useEffect(() => {
    setFilteredMembers(filterData(searchQuery, baseMembers).slice(0, 100))
  }, [searchQuery, baseMembers])

  const filterData = (query: string, data: any) => {
    if (!query) {
      return data.filter((e: any) => !filteredIds.includes(e.ref?.id))
    } else {
      return data.filter((e: any) => e?.name?.toLowerCase().includes(query.toLowerCase()) && !filteredIds.includes(e.ref?.id))
    }
  }

  const onMemberTapped = (member: ForgeOrganizationMember) => {
    let selected: any[] = []
    if (selectedMembers.includes(member)) {
      selected = [...selectedMembers]
      selected = selected.filter(e => e.ref?.id !== member.ref?.id)
      onMemberUnselected(member)
    } else {
      selected = [...selectedMembers, member]
      onMemberSelected(member)
    }

    setSelectedMembers(selected)
  }

  return (
    <MDBox mx={3} mt={2}>
      <OutlinedInput
        id="search-bar"
        className="text"
        onInput={e => {
          setSearchQuery((e.target as HTMLInputElement).value)
        }}
        placeholder="Search members"
        fullWidth={true}
        endAdornment={<Search />}
      />
      <Box height={16} />
      {filteredMembers.length > 0 ? (
        filteredMembers.map((member: ForgeOrganizationMember) => <MemberCard onClick={() => onMemberTapped(member)} member={member} />)
      ) : (
        <MDTypography variant="body2" fontWeight="normal" fontSize="14px" align="center" style={{ color: "gray" }}>
          None of the members of your organization members match your query
        </MDTypography>
      )}
    </MDBox>
  )
}

// Setting default values for the props of SelectMember
SelectMember.defaultProps = {
  onMemberSelected: null
}

export default SelectMember
