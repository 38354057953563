export class ContactField {
  fieldType: string
  type: string
  value: string
  favorite: boolean
  favoriteType?: string
  sourceValue?: any

  constructor({
    fieldType,
    type,
    value,
    favorite = false,
    favoriteType,
    sourceValue
  }: {
    fieldType: string
    type: string
    value: string
    favorite?: boolean
    favoriteType?: string
    sourceValue?: any
  }) {
    this.fieldType = fieldType
    this.type = type
    this.value = value
    this.favorite = favorite
    this.favoriteType = favoriteType
    this.sourceValue = sourceValue
  }

  copyWith({ fieldType, type, value, favorite, favoriteType, sourceValue }: Partial<ContactField>): ContactField {
    return new ContactField({
      fieldType: fieldType ?? this.fieldType,
      type: type ?? this.type,
      value: value ?? this.value,
      favorite: favorite ?? this.favorite,
      favoriteType: favoriteType ?? this.favoriteType,
      sourceValue: sourceValue ?? this.sourceValue
    })
  }

  toMap(): Record<string, any> {
    return {
      fieldType: this.fieldType,
      type: this.type,
      value: this.value,
      favorite: this.favorite,
      favoriteType: this.favoriteType,
      sourceValue: this.sourceValue
    }
  }

  static fromMap(map: Record<string, any>): ContactField {
    return new ContactField({
      fieldType: map["fieldType"] ?? "",
      type: map["type"] ?? "",
      value: map["value"] ?? "",
      favorite: map["favorite"] ?? false,
      favoriteType: map["favoriteType"],
      sourceValue: map["sourceValue"]
    })
  }
}
