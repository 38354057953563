// MUI
import { Box, Drawer, IconButton, Stack, TextField, useMediaQuery } from "@mui/material"

// Material Dashboard
import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"

import { useContext, useEffect, useState } from "react"
import theme from "assets/theme"
import { ForgeOrganizationTeam } from "forge/organization/types/team"
import { Delete, Edit, PersonAdd, Save, Search } from "@mui/icons-material"
import { ForgeMemberAvatar } from "forge/core/components/ForgeAvatar"
import MemberCard from "forge/organization/members/components/MemberCard"
import SelectMember from "forge/organization/members/components/SelectMember"
import { TeamsContext } from "../../services/TeamsContext"
import { ForgeOrganizationMember } from "forge/organization/types/member"
import { Can } from "forge/organization/rbac/CanContext"
import { DisabledTooltip } from "forge/organization/rbac/DisabledTooltip"
import ForgeCta from "forge/core/components/ForgeCta"
import MDButton from "components/MDButton"

function TeamDrawer({ openDrawer, handleClose, team }: { openDrawer: boolean; handleClose: () => void; team?: ForgeOrganizationTeam }) {
  // UI
  const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"))
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  // Context
  const { addTeamMember, updateTeam, deleteTeam } = useContext(TeamsContext)

  // State
  const [searchQuery, setSearchQuery] = useState<string>("")
  const [addMember, setAddMember] = useState<boolean>(false)
  const [edit, setEdit] = useState<boolean>(false)
  const [name, setName] = useState<string>(team?.name)
  const [description, setDescription] = useState<string>(team?.description)

  useEffect(() => {
    setName(team?.name)
    setDescription(team?.description)
  }, [team])

  const onMemberSelected = (member: ForgeOrganizationMember) => {
    addTeamMember(team?.ref.id, member.ref.id)
    team.membersRefs.push(member.ref)
    team.members.push(member)
    setAddMember(false)
  }

  const internalHandleClose = () => {
    setSearchQuery(undefined)
    setAddMember(false)
    handleClose()
  }

  const handleUpdateTeam = async () => {
    team.name = name
    team.description = description
    setEdit(false)
    await updateTeam(team?.ref.id, team)
  }

  const handleDeleteTeam = async () => {
    await deleteTeam(team?.ref.id)
    handleClose()
  }

  if (!team) {
    return null
  }

  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      onClose={internalHandleClose}
      PaperProps={{
        sx: {
          maxHeight: "96%",
          width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%"
        }
      }}
    >
      {/* Header */}
      <div style={{ backgroundColor: "black", padding: 24 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="start">
          <Stack direction="column" spacing={0.5} width="100%">
            {edit ? (
              <TextField
                id="team-name"
                variant="outlined"
                style={{ flex: 1, width: "-webkit-fill-available" }}
                inputProps={{ style: { color: "white" } }}
                value={name}
                onChange={e => setName(e.target.value)}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    handleUpdateTeam()
                  }
                }}
              />
            ) : (
              <MDTypography variant="h5" style={{ color: "white" }}>
                {team?.name}
              </MDTypography>
            )}

            {edit ? (
              <TextField
                id="team-description"
                variant="outlined"
                style={{ flex: 1, width: "-webkit-fill-available" }}
                inputProps={{ style: { color: "white" } }}
                value={description}
                onChange={e => setDescription(e.target.value)}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    handleUpdateTeam()
                  }
                }}
              />
            ) : (
              <MDTypography variant="body2" style={{ color: "white" }}>
                {team?.description}
              </MDTypography>
            )}
            <MDBox display="flex" alignItems="center">
              <ForgeMemberAvatar member={team?.createdBy} />
              <MDTypography variant="body2" ml={2} style={{ color: "white" }}>
                {team?.createdBy?.name}
              </MDTypography>
            </MDBox>
          </Stack>

          <Stack direction="row" spacing={0.5}>
            <IconButton onClick={() => setEdit(!edit)}>{edit ? <Save style={{ color: "white" }} /> : <Edit style={{ color: "white" }} />}</IconButton>
            <IconButton onClick={() => handleDeleteTeam()}>
              <Delete style={{ color: "white" }} />
            </IconButton>
          </Stack>
        </Stack>
      </div>

      {/* Body */}
      {addMember ? (
        <MDBox height={"-webkit-fill-available"}>
          <SelectMember onMemberSelected={onMemberSelected} filteredIds={team?.membersRefs?.map(member => member.id)} />
        </MDBox>
      ) : (
        <MDBox mx={3} mt={2} height={"-webkit-fill-available"}>
          <TextField
            id="title"
            label="Search contact"
            variant="outlined"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            style={{ flex: 1, width: "100%" }}
            InputLabelProps={{ style: { color: "gray" } }}
            InputProps={{
              endAdornment: <Search fontSize="small" />
            }}
          />
          <Box height={16} />
          {team?.members.slice(0, 100).map(member => (
            <MemberCard member={member} />
          ))}
        </MDBox>
      )}

      {/* Footer */}
      <Box
        sx={{
          display: "flex",
          gap: 1,
          p: 1.5,
          pb: 2,
          borderTop: "1px solid",
          borderColor: "divider",
          alignSelf: "center",
          width: "100%",
          justifyContent: "space-between"
        }}
      >
        {addMember ? (
          <MDButton
            variant="outlined"
            color="dark"
            style={{
              width: "-webkit-fill-available",
              marginLeft: "24px",
              marginRight: "24px"
            }}
            onClick={() => setAddMember(false)}
          >
            Cancel
          </MDButton>
        ) : (
          <Can I="add-members" this="teams" passThrough>
            {(allowed: boolean) => (
              <DisabledTooltip allowed={allowed}>
                <ForgeCta
                  onClick={() => setAddMember(true)}
                  cta={`Add a Member to ${team?.name}`}
                  icon={<PersonAdd fontSize="small" />}
                  fontWeight="normal"
                  fontSize="14px"
                  buttonSize="2rem"
                  margin="0 auto"
                  marginBottom={0}
                  marginTop={0}
                />
              </DisabledTooltip>
            )}
          </Can>
        )}
      </Box>
    </Drawer>
  )
}

// Setting default values for the props of TeamDrawer
TeamDrawer.defaultProps = {
  handleCloseDrawer: null
}

export default TeamDrawer
