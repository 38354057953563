/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom"

// @mui material components
import Card from "@mui/material/Card"
import Icon from "@mui/material/Icon"

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import MDButton from "components/MDButton"
import { Stack } from "@mui/material"

// Declaring props types for DefaultPricingCard
interface Props {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark" | "white"
  badge?: {
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark"
    label: string
  }
  plan?: {
    title: string
    description: string
  }
  price: {
    display?: boolean
    currency: string
    value: string
    type: string
    type2?: string
  }
  specifications: {
    label: string
    includes?: boolean
  }[]
  action: {
    type: "external" | "internal"
    route: string
    label: string
    color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark"
    variant?: "text" | "contained" | "gradient" | "outlined"
    onClick?: any
  }
  shadow?: boolean
  left?: boolean
  right?: boolean
  [key: string]: any
}

function DefaultPricingCard({ color, plan, badge, price, specifications, action, shadow, left, right }: Props): JSX.Element {
  const renderSpecifications = specifications.map(({ label, includes }, index) => (
    <MDBox key={label} display="flex" alignItems="center" py={1} px={4} style={{ background: index % 2 ? "#d3d3d359" : undefined }}>
      <MDBox display="flex" justifyContent="center" alignItems="center" width="1.5rem" height="1.5rem" mr={2} mt={-0.125}>
        <MDTypography variant="body1" color={color === "white" ? "text" : "white"} sx={{ lineHeight: 0 }}>
          <Icon>{includes ? "done" : "remove"}</Icon>
        </MDTypography>
      </MDBox>
      <MDTypography variant="body2" color={color === "white" ? "text" : "white"} fontWeight="regular">
        {label}
      </MDTypography>
    </MDBox>
  ))

  return (
    <Card
      sx={{
        boxShadow: theme => (shadow ? theme.shadows[8] : "none"),
        // boxShadow: ({ boxShadows: { lg } }) => (shadow ? lg : "none"),
        border: ({ boxShadows: { lg } }) => (shadow ? "none" : "1px solid lightgray"),
        borderTop: ({ boxShadows: { lg } }) => (shadow ? "none" : "1px solid lightgray"), // Border on top
        borderRight: ({ boxShadows: { lg } }) => (shadow ? "none" : left ? "none" : "1px solid lightgray"), // Border on right
        borderBottom: ({ boxShadows: { lg } }) => (shadow ? "none" : "1px solid lightgray"), // Border on bottom
        borderLeft: ({ boxShadows: { lg } }) => (shadow ? "none" : right ? "none" : "1px solid lightgray") // Border on left
      }}
      style={{ zIndex: shadow ? 1 : 0 }}
    >
      <MDBox bgColor={color} variant={color === "white" ? "contained" : "gradient"} borderRadius="xl">
        {badge && (
          <MDBox bgColor={badge.color} width="max-content" px={4} pt={0} pb={0.5} mx="auto" mt={-1.375} borderRadius="section" lineHeight={1}>
            <MDTypography variant="caption" textTransform="uppercase" fontWeight="medium" color={badge.color === "light" ? "dark" : "white"}>
              {badge.label}
            </MDTypography>
          </MDBox>
        )}
        <MDBox pt={3} pb={2} px={3} textAlign="center">
          <MDBox my={1} height={"12vh"}>
            {plan && (
              <>
                <MDTypography variant="h5" color={color === "white" ? "dark" : "white"} style={{ paddingBottom: 4 }}>
                  {plan.title}
                </MDTypography>
                <MDTypography variant="body2" color={color === "white" ? "dark" : "white"} lineHeight={1.5}>
                  {plan.description}
                </MDTypography>
              </>
            )}
          </MDBox>
        </MDBox>
        <MDBox height={"20vh"}>{renderSpecifications}</MDBox>
        <MDBox pb={3} px={3}>
          <MDBox py={3}>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
              <MDTypography
                display="inline"
                component="small"
                variant="h5"
                color={(price.display ?? true ? color === "white" : !(color === "white")) ? "dark" : "white"}
                verticalAlign="top"
              >
                {price.currency}
              </MDTypography>
              <MDTypography variant="h1" color={(price.display ?? true ? color === "white" : !(color === "white")) ? "dark" : "white"}>
                {price.value}
              </MDTypography>
              <MDBox width={8} />
              <MDTypography
                display="inline"
                component="small"
                variant="h6"
                color={(price.display ?? true ? color === "white" : !(color === "white")) ? "dark" : "white"}
                lineHeight={1}
              >
                {price.type}
              </MDTypography>
            </Stack>
            <MDTypography
              textAlign="center"
              variant="body2"
              color={(price.display ?? true ? color === "white" : !(color === "white")) ? "dark" : "white"}
              lineHeight={1}
            >
              {price.type2}
            </MDTypography>
          </MDBox>
          {action.type === "internal" ? (
            <MDBox mt={3}>
              <MDButton
                // component={Link}
                // to={action.route}
                onClick={action.onClick}
                variant={action.variant ?? "gradient"}
                color={action.color}
                fullWidth
              >
                {action.label}&nbsp;
              </MDButton>
            </MDBox>
          ) : (
            <MDBox mt={3}>
              <MDButton component="a" href={action.route} target="_blank" rel="noreferrer" variant="gradient" color={action.color} fullWidth>
                {action.label}&nbsp;
              </MDButton>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    </Card>
  )
}

// Declaring default props for DefaultPricingCard
DefaultPricingCard.defaultProps = {
  color: "white",
  shadow: true
}

export default DefaultPricingCard
