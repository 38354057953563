import { UserPipelineConfig } from "./user-pipeline-config"
import { UserStats } from "./user-stats"
import { ForgeOAuthCredentials } from "./forge-oauth-credentials"
import { User } from "firebase/auth"
import { hubspotForecastOptions, salesforceForecastOptions } from "types/pipeline/opportunity"
import { Crm } from "types/pipeline/crm"
import { ForgeEncryption } from "forge/core/services/encryption"
import { UserEmail } from "./user-email"

export class UserProfileData {
  displayName: string
  firstName: string
  lastName: string
  preferredName: string
  emails: UserEmail[]
  company: string
  linkedInUrl: string
  profilePicture: string
  isRealtor: boolean
  pipelineUserConfig: UserPipelineConfig
  isAnonymous: boolean
  userStats?: UserStats | null
  platform?: string
  platformVersion?: string
  device?: string
  appVersion?: string
  signInMethod?: string
  accessToCalendar?: boolean
  accessToContacts?: boolean
  notificationsDisabled?: boolean
  googleCredentials?: ForgeOAuthCredentials[]
  microsoftCredentials?: ForgeOAuthCredentials[]
  termsAndConditionsAcceptedVersion?: string
  privacyPolicyAcceptedVersion?: string
  zoomUrl?: string
  encryption?: { sealdIdentity: string }
  organization?: {
    id: string
    idPendingActivation: string
    pendingActivation: boolean
    pendingMigration: boolean
    rejected: boolean
  }

  // Stripe
  stripeCustomerId?: string
  stripeSubscriptionId?: string
  stripeData?: {
    customer?: any
    subscription?: any
  }

  constructor({
    displayName,
    firstName,
    lastName,
    preferredName,
    emails,
    company,
    linkedInUrl,
    profilePicture,
    isRealtor,
    pipelineUserConfig,
    isAnonymous = false,
    userStats,
    platform,
    platformVersion,
    device,
    appVersion,
    signInMethod,
    accessToCalendar,
    accessToContacts,
    notificationsDisabled,
    googleCredentials,
    microsoftCredentials,
    termsAndConditionsAcceptedVersion,
    privacyPolicyAcceptedVersion,
    zoomUrl,
    encryption,
    organization,
    stripeCustomerId,
    stripeSubscriptionId,
    stripeData
  }: {
    displayName: string
    firstName: string
    lastName: string
    preferredName: string
    emails: UserEmail[]
    company: string
    linkedInUrl: string
    profilePicture: string
    isRealtor: boolean
    pipelineUserConfig: UserPipelineConfig
    isAnonymous?: boolean
    userStats: UserStats | null
    platform?: string
    platformVersion?: string
    device?: string
    appVersion?: string
    signInMethod?: string
    accessToCalendar?: boolean
    accessToContacts?: boolean
    notificationsDisabled?: boolean
    googleCredentials?: ForgeOAuthCredentials[]
    microsoftCredentials?: ForgeOAuthCredentials[]
    termsAndConditionsAcceptedVersion?: string
    privacyPolicyAcceptedVersion?: string
    zoomUrl?: string
    encryption?: { sealdIdentity: string }
    organization?: {
      id: string
      idPendingActivation: string
      pendingActivation: boolean
      pendingMigration: boolean
      rejected: boolean
    }
    stripeCustomerId?: string
    stripeSubscriptionId?: string
    stripeData?: {
      customer?: any
      subscription?: any
    }
  }) {
    this.displayName = displayName
    this.firstName = firstName
    this.lastName = lastName
    this.preferredName = preferredName
    this.emails = emails
    this.company = company
    this.linkedInUrl = linkedInUrl
    this.profilePicture = profilePicture
    this.isRealtor = isRealtor
    this.pipelineUserConfig = pipelineUserConfig
    this.isAnonymous = isAnonymous
    this.userStats = userStats
    this.platform = platform
    this.platformVersion = platformVersion
    this.device = device
    this.appVersion = appVersion
    this.signInMethod = signInMethod
    this.accessToCalendar = accessToCalendar
    this.accessToContacts = accessToContacts
    this.notificationsDisabled = notificationsDisabled
    this.googleCredentials = googleCredentials
    this.microsoftCredentials = microsoftCredentials
    this.termsAndConditionsAcceptedVersion = termsAndConditionsAcceptedVersion
    this.privacyPolicyAcceptedVersion = privacyPolicyAcceptedVersion
    this.zoomUrl = zoomUrl
    this.encryption = encryption
    this.organization = organization
    this.stripeCustomerId = stripeCustomerId
    this.stripeSubscriptionId = stripeSubscriptionId
    this.stripeData = stripeData
  }

  copyWith({
    displayName,
    firstName,
    lastName,
    preferredName,
    emails,
    company,
    linkedInUrl,
    profilePicture,
    isRealtor,
    pipelineUserConfig,
    isAnonymous,
    userStats,
    platform,
    platformVersion,
    device,
    appVersion,
    signInMethod,
    accessToCalendar,
    accessToContacts,
    notificationsDisabled,
    googleCredentials,
    microsoftCredentials,
    termsAndConditionsAcceptedVersion,
    privacyPolicyAcceptedVersion,
    zoomUrl,
    encryption,
    organization,
    stripeCustomerId,
    stripeSubscriptionId,
    stripeData
  }: Partial<UserProfileData> = {}): UserProfileData {
    return new UserProfileData({
      displayName: displayName ?? this.displayName,
      firstName: firstName ?? this.firstName,
      lastName: lastName ?? this.lastName,
      preferredName: preferredName ?? this.preferredName,
      emails: emails ?? this.emails,
      company: company ?? this.company,
      linkedInUrl: linkedInUrl ?? this.linkedInUrl,
      profilePicture: profilePicture ?? this.profilePicture,
      isRealtor: isRealtor ?? this.isRealtor,
      pipelineUserConfig: pipelineUserConfig ?? this.pipelineUserConfig,
      isAnonymous: isAnonymous ?? this.isAnonymous,
      userStats: userStats ?? this.userStats,
      platform: platform ?? this.platform,
      platformVersion: platformVersion ?? this.platformVersion,
      device: device ?? this.device,
      appVersion: appVersion ?? this.appVersion,
      signInMethod: signInMethod ?? this.signInMethod,
      accessToCalendar: accessToCalendar ?? this.accessToCalendar,
      accessToContacts: accessToContacts ?? this.accessToContacts,
      notificationsDisabled: notificationsDisabled ?? this.notificationsDisabled,
      googleCredentials: googleCredentials ?? this.googleCredentials,
      microsoftCredentials: microsoftCredentials ?? this.microsoftCredentials,
      termsAndConditionsAcceptedVersion: termsAndConditionsAcceptedVersion ?? this.termsAndConditionsAcceptedVersion,
      privacyPolicyAcceptedVersion: privacyPolicyAcceptedVersion ?? this.privacyPolicyAcceptedVersion,
      zoomUrl: zoomUrl ?? this.zoomUrl,
      encryption: encryption ?? this.encryption,
      organization: organization ?? this.organization,
      stripeCustomerId: stripeCustomerId ?? this.stripeCustomerId,
      stripeSubscriptionId: stripeSubscriptionId ?? this.stripeSubscriptionId,
      stripeData: stripeData ?? this.stripeData
    })
  }

  toMap(): { [key: string]: any } {
    return {
      displayName: this.displayName,
      firstName: this.firstName,
      lastName: this.lastName,
      preferredName: this.preferredName,
      emails: this.emails.map(e => e.toMap()),
      company: this.company,
      linkedInUrl: this.linkedInUrl,
      profilePicture: this.profilePicture,
      isRealtor: this.isRealtor,
      pipelineUserConfig: this.pipelineUserConfig.toMap(),
      isAnonymous: this.isAnonymous,
      userStats: this.userStats?.toMap(),
      platform: this.platform,
      platformVersion: this.platformVersion,
      device: this.device,
      appVersion: this.appVersion,
      signInMethod: this.signInMethod,
      accessToCalendar: this.accessToCalendar,
      accessToContacts: this.accessToContacts,
      notificationsDisabled: this.notificationsDisabled,
      googleCredentials: this.googleCredentials?.map(e => e.toMap()),
      microsoftCredentials: this.microsoftCredentials?.map(e => e.toMap()),
      termsAndConditionsAcceptedVersion: this.termsAndConditionsAcceptedVersion,
      privacyPolicyAcceptedVersion: this.privacyPolicyAcceptedVersion,
      zoomUrl: this.zoomUrl,
      encryption: this.encryption,
      organization: this.organization,
      stripeCustomerId: this.stripeCustomerId,
      stripeSubscriptionId: this.stripeSubscriptionId,
      stripeData: this.stripeData
    }
  }

  static async fromMap(map: { [key: string]: any }, currentUser: User, encryptionService: ForgeEncryption): Promise<UserProfileData> {
    let displayNameSplit = currentUser.displayName?.split(" ")

    return new UserProfileData({
      displayName: currentUser.displayName ?? map.displayName ?? "",
      firstName: map.firstName ?? (displayNameSplit && displayNameSplit.length > 0 ? displayNameSplit[0].trim() : ""),
      lastName: map.lastName ?? (displayNameSplit && displayNameSplit.length > 1 ? displayNameSplit[1].trim() : ""),
      preferredName: map.preferredName ?? "",
      emails: Array.isArray(map.emails) ? map.emails.map(e => UserEmail.fromMap(e)) : [],
      company: map.company ?? "",
      linkedInUrl: map.linkedInUrl ?? "",
      profilePicture: map.profilePicture ?? "",
      isRealtor: map.isRealtor ?? false,
      pipelineUserConfig: UserPipelineConfig.fromMap(map.pipelineUserConfig ?? {}),
      isAnonymous: map.isAnonymous ?? false,
      userStats: map.userStats ? UserStats.fromMap(map.userStats) : null,
      platform: map.platform,
      platformVersion: map.platformVersion,
      device: map.device,
      appVersion: map.appVersion,
      signInMethod: map.signInMethod,
      accessToCalendar: map.accessToCalendar,
      accessToContacts: map.accessToContacts,
      notificationsDisabled: map.notificationsDisabled,
      googleCredentials: Array.isArray(map.googleAuthCredentials)
        ? await Promise.all(map.googleAuthCredentials.map((e: any) => ForgeOAuthCredentials.fromMap(e, encryptionService)))
        : [],
      microsoftCredentials: Array.isArray(map.microsoftAuthCredentials)
        ? await Promise.all(map.microsoftAuthCredentials.map((e: any) => ForgeOAuthCredentials.fromMap(e, encryptionService)))
        : [],
      termsAndConditionsAcceptedVersion: map.termsAndConditionsAcceptedVersion,
      privacyPolicyAcceptedVersion: map.privacyPolicyAcceptedVersion,
      zoomUrl: map.zoomUrl,
      encryption: map.encryption,
      organization: map.organization,
      stripeCustomerId: map.stripeCustomerId,
      stripeSubscriptionId: map.stripeSubscriptionId,
      stripeData: map.stripeData
    })
  }

  get forecastOptions(): { [key: string]: any }[] | null {
    if (this.pipelineUserConfig.integration === Crm.hubspot) {
      return hubspotForecastOptions
    } else if (this.pipelineUserConfig.integration === Crm.salesforce) {
      return salesforceForecastOptions
    }

    return null
  }

  get doesUserBelongsToAnOrganization(): boolean {
    return this.organization?.id && !(this.organization?.pendingActivation ?? false) && !(this.organization?.pendingMigration ?? false)
  }
}
