export enum NewsSortBy {
  recency = "NewsSortBy.recency",
  contacts = "NewsSortBy.contacts"
}

export const NewsSortByExtended = {
  ...NewsSortBy,
  getName(type: NewsSortBy): any {
    switch (type) {
      case NewsSortBy.recency:
        return "Most Recent"
      case NewsSortBy.contacts:
        return "Most Contacts"
      default:
        return ""
    }
  }
}
