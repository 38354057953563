import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"

import MDBox from "components/MDBox"

import bgImage from "assets/forge/images/forge.png"
import { Card, Drawer, useMediaQuery } from "@mui/material"
import { News } from "types/news/news"
import MDTypography from "components/MDTypography"
import NewsDetail from "../NewsDetail"
import { useState } from "react"
import theme from "assets/theme"
import { ArrowForward } from "@mui/icons-material"
import { ForgeAvatarGroup } from "forge/core/components/ForgeAvatar"

const formatter = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  hour12: true
})

function NewsCard({ article }: { article: News }) {
  const [openDrawer, setOpenDrawer] = useState(false)
  const handleOpenDrawer = () => setOpenDrawer(true)
  const handleCloseDrawer = () => setOpenDrawer(false)

  return (
    <MDBox sx={{ height: "100%" }}>
      <NewsDetail openDrawer={openDrawer} handleCloseDrawer={handleCloseDrawer} article={article} />
      <Card sx={{ height: "100%" }} onClick={handleOpenDrawer} style={{ cursor: "pointer" }}>
        <MDTypography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical"
          }}
          height={"50px"}
          mx={2}
          mt={2}
          fontSize="0.7rem"
          variant="body2"
          color="gray"
        >
          {formatter.format(new Date(article.publishedAt * 1000))} · {article.source}
        </MDTypography>
        <MDBox display="flex" alignItems="center">
          <MDBox>
            <CardMedia
              sx={{ height: 86, width: 86, marginTop: 1 }}
              style={{
                backgroundSize: article.image ? "100%" : "70%",
                backgroundColor: "black"
              }}
              image={article.image ?? bgImage}
              title={article.title}
            />
          </MDBox>
          <MDBox mr={2} mt={0.5} lineHeight={1}>
            <MDTypography
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 4
              }}
              style={{ "line-height": "1.4em" }}
              display="block"
              variant="h6"
              fontWeight="medium"
            >
              {article.title}
            </MDTypography>
          </MDBox>
        </MDBox>
        <CardContent sx={{ height: "100%", padding: "0.5rem 1rem 0rem 1rem" }}>
          <Typography
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 5,
              fontSize: "0.875rem"
            }}
            variant="body2"
            color="text.secondary"
          >
            {article.description}
          </Typography>
        </CardContent>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1} ml={3} mr={2} mb={2}>
          <ForgeAvatarGroup contacts={article.affectedContactsRecords} />
          <CardActions>
            <Button size="small" onClick={handleOpenDrawer} style={{ textTransform: "none", fontWeight: "400" }}>
              Read More <ArrowForward />
            </Button>
          </CardActions>
        </MDBox>
      </Card>
    </MDBox>
  )
}

// Setting default values for the props of NewsCard
NewsCard.defaultProps = {
  article: null
}

export default NewsCard
