// @mui material components

import Card from "@mui/material/Card"

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox"

// Material Dashboard 2 PRO React context
import SideNavItemTile from "forge/core/components/SideNavItemTile"
import { Can } from "forge/organization/rbac/CanContext"
import { DisabledTooltip } from "forge/organization/rbac/DisabledTooltip"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

function OrganizationSideNav(): JSX.Element {
  // State
  const [section, setSection] = useState("organization")

  // Location
  const location = useLocation()
  const hash = location.hash
  useEffect(() => {
    if (hash) {
      const timeoutId = setTimeout(() => {
        handleClick(hash.replace("#", ""))
      }, 500)

      // Clean up the timeout if the component unmounts or hash changes
      return () => clearTimeout(timeoutId)
    }
  }, [hash])

  const sideNavItems = [
    { icon: "info", label: "information", href: "organization" },
    // { icon: "safety_divider", label: "divisions", href: "divisions" },
    { icon: "receipt_long", label: "billing", href: "billing" },
    { icon: "handshake", label: "CRM", href: "crm" },
    { icon: "person", label: "members", href: "members" },
    { icon: "groups", label: "teams", href: "teams" },
    { icon: "lock_open", label: "roles", href: "roles" }
  ]

  const renderSideNavItems = sideNavItems.map((item, key) => {
    const customError = "Only the account administrator may access this section"

    return (
      <Can I="view" this={item.href} passThrough>
        {allowed => (
          <DisabledTooltip allowed={allowed} message={item.href === "billing" ? customError : undefined}>
            <SideNavItemTile sideNavItem={item} selected={section === item.href} itemKey={key} handleClick={handleClick} enabled={allowed} />
          </DisabledTooltip>
        )}
      </Can>
    )
  })

  const handleClick = (targetId: string) => {
    const element = document.getElementById(targetId)

    if (element) {
      var headerOffset = 24
      var elementPosition = element.getBoundingClientRect().top
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset
      setSection(targetId)
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      })
    }
  }

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "24px",
        marginBottom: 3
      }}
    >
      <MDBox component="ul" display="flex" flexDirection="column" p={2} m={0} sx={{ listStyle: "none" }}>
        {renderSideNavItems}
      </MDBox>
    </Card>
  )
}

export default OrganizationSideNav
