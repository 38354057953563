import { collection, doc, DocumentData, getDocs, orderBy, query, QuerySnapshot, Unsubscribe, updateDoc, where } from "firebase/firestore"
import { firestoreDb } from "firebase.init"
import { User } from "firebase/auth"
import { UserProfileData } from "types/user/user-profile-data"
import { UserSettings } from "types/user/user-settings"
import { ForgeEncryption } from "forge/core/services/encryption"
import { firestoreDebounce } from "forge/core/utils/firestore"

class SettingsFirestore {
  private user: User
  private userProfileData: UserProfileData
  private encryptionService: ForgeEncryption

  constructor(user: User, userProfileData: UserProfileData, encryptionService: ForgeEncryption) {
    this.user = user
    this.userProfileData = userProfileData
    this.encryptionService = encryptionService
  }

  updateUserSettings = async (userSettings: UserSettings): Promise<void> => {
    const documentRef = doc(firestoreDb, `users/${this.user.uid}`)

    await updateDoc(documentRef, {
      settings: userSettings.toMap()
    })
    return
  }

  getPersonalInvoicesLive = (onEvent: (invoices: any[]) => void): Unsubscribe => {
    const collectionRef = query(collection(firestoreDb, `users/${this.user.uid}/invoices`), orderBy("created", "asc"), where("status", "==", "paid"))
    getDocs(collectionRef)

    return firestoreDebounce(collectionRef, (snapshot: QuerySnapshot<DocumentData, DocumentData>) => {
      onEvent(snapshot.docs.map(doc => doc.data()))
    })
  }

  getOrganizationInvoicesLive = (onEvent: (invoices: any[]) => void): Unsubscribe => {
    const collectionRef = query(
      collection(firestoreDb, `organizations/${this.userProfileData.organization?.id}/invoices`),
      orderBy("created", "asc"),
      where("status", "==", "paid")
    )

    return firestoreDebounce(collectionRef, (snapshot: QuerySnapshot<DocumentData, DocumentData>) => {
      onEvent(snapshot.docs.map(doc => doc.data()))
    })
  }
}

export default SettingsFirestore
