import { useNavigate } from "react-router-dom"
import { StarBorderOutlined } from "@mui/icons-material"
import { Card, Tooltip } from "@mui/material"

import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import { ForgeMemberAvatar } from "forge/core/components/ForgeAvatar"
import { ForgeOrganizationMember } from "forge/organization/types/member"
import { useState } from "react"

function MemberTile({
  member,
  onClick,
  color = "black",
  prefixText,
  size = "normal"
}: {
  member: ForgeOrganizationMember
  onClick?: any
  color?: string
  prefixText?: string
  size?: "small" | "normal"
}) {
  // State
  const [avatarDiameter, setAvatarDiameter] = useState<number>(size === "small" ? 24 : 40)

  // Handlers to update state on hover
  const handleMouseEnter = () => {
    if (size === "small") {
      setAvatarDiameter(44)
    }
  }

  const handleMouseLeave = () => {
    if (size === "small") {
      setAvatarDiameter(24)
    }
  }

  return (
    <MDBox display="flex" alignItems="center" m={size === "small" ? 0 : 1} onClick={onClick}>
      <Tooltip title={member.name}>
        <MDBox onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <ForgeMemberAvatar member={member} diameter={avatarDiameter} />
        </MDBox>
      </Tooltip>
      <MDBox ml={0.5} lineHeight={0} sx={{ width: "-webkit-fill-available" }}>
        <MDTypography
          fontWeight={size === "small" ? "normal" : "bold"}
          fontSize={size === "small" ? "0.75rem" : "1rem"}
          lineHeight={1}
          color={color}
          style={{ color }}
        >
          {prefixText}
          {member.name}
        </MDTypography>
      </MDBox>
    </MDBox>
  )
}

export default MemberTile
