// @material-ui core components

// Material Dashboard 2 PRO React TS components
import MDTypography from "components/MDTypography"

// Settings page components
import { Grid, Stack, Tab, Tabs } from "@mui/material"

import { useCallback, useState, useEffect, useContext } from "react"
import { loadStripe } from "@stripe/stripe-js"
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js"
import MDBox from "components/MDBox"
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard"
import ForgeLoading from "forge/shared/ForgeLoading/ForgeLoading"
import forgeBlackLogo from "assets/forge/svgs/forge_black.svg"
import { AuthContext } from "context"
import SettingsApi from "forge/settings/services/api"

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

function SubscriptionFlow(): JSX.Element {
  // Context
  const { getCurrentUser } = useContext(AuthContext)

  // Services
  const { user, encryptionService, userProfileData } = getCurrentUser()
  const settingsApi = new SettingsApi(user, userProfileData, encryptionService)

  // State
  const [tabValue, setTabValue] = useState<number>(1)
  const [selectedPlan, setSelectedPlan] = useState<string>()
  const [isComplete, setIsComplete] = useState(false)

  const handleSetTabValue = (event: any, newValue: any) => {
    setTabValue(newValue)
  }

  // Stripe
  const [clientSecret, setClientSecret] = useState(null)
  const fetchClientSecret = useCallback(() => {
    // Create a Checkout Session
    return settingsApi.createCheckoutSession(selectedPlan, !!!userProfileData.organization?.id).then(data => {
      setClientSecret(data.clientSecret)
      return data.clientSecret
    })
  }, [selectedPlan])

  useEffect(() => {
    if (selectedPlan) fetchClientSecret()
  }, [fetchClientSecret])

  const handleComplete = () => {
    return setIsComplete(true)
  }

  return (
    <MDBox style={{ padding: "0px 8px 24px 8px" }}>
      {!selectedPlan && (
        <Stack alignItems={"center"}>
          <Tabs value={tabValue} onChange={handleSetTabValue} style={{ width: "40%", marginBottom: 24 }}>
            <Tab
              id="monthly"
              label={
                <MDBox py={0.5} px={2} color="inherit">
                  Billed Monthly
                </MDBox>
              }
            />
            <Tab
              id="annual"
              label={
                <MDBox py={0.5} px={2} color="inherit">
                  Billed Yearly
                </MDBox>
              }
            />
          </Tabs>
          <Grid container justifyContent="center" px={3}>
            <Grid item xs={12} lg={4}>
              <DefaultPricingCard
                color={"white"}
                shadow={false}
                left={true}
                plan={{ title: "Free", description: `Ideal for individuals.` }}
                price={{ currency: "$", value: "0", type: "/ month" }}
                specifications={[
                  { label: "1 seat", includes: true },
                  { label: "Apple & Google integrations", includes: true },
                  { label: "Add unlimited connections", includes: true },
                  { label: "Desktop & app access", includes: true }
                ]}
                action={{
                  type: "internal",
                  route: "/",
                  color: "secondary",
                  variant: "text",
                  label: "Current Plan",
                  onClick: () => {}
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DefaultPricingCard
                color={"white"}
                plan={{ title: "Professional", description: `Best for growing businesses. Everything in the "Free" plan, plus: ` }}
                price={{
                  currency: "$",
                  value: tabValue ? "83.33" : "99.99",
                  type: "/ month",
                  type2: tabValue ? "Per user, billed annually" : "Per user"
                }}
                specifications={[
                  { label: "1-10 seats", includes: true },
                  { label: "Outlook integration", includes: true },
                  { label: "Connect your CRM", includes: true },
                  { label: "Invite team members", includes: true }
                ]}
                action={{
                  type: "internal",
                  route: "/",
                  color: "info",
                  label: "Subscribe",
                  onClick: () => setSelectedPlan(tabValue ? "yearly" : "monthly")
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DefaultPricingCard
                color={"white"}
                shadow={false}
                right={true}
                plan={{
                  title: "Enterprise",
                  description: `For organizations with a sales team. Everything in the "Professional" plan, plus;`
                }}
                price={{ currency: "", value: ".", type: "", display: false }}
                specifications={[
                  { label: "Unlimited seats", includes: true },
                  { label: "Increased collaboration", includes: true }
                ]}
                action={{
                  type: "internal",
                  route: "/",
                  color: "secondary",
                  variant: "outlined",
                  label: "Contact Us",
                  onClick: () => {
                    window.open("https://www.forgeyourcrm.com/contact-sales", "_blank")
                  }
                }}
              />
            </Grid>
          </Grid>
        </Stack>
      )}
      {selectedPlan &&
        !isComplete &&
        (clientSecret ? (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{
              fetchClientSecret,
              onComplete: handleComplete
            }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        ) : (
          <ForgeLoading loading loadingType="inline" style={{ width: "256px", margin: "0 auto" }} />
        ))}

      {isComplete && (
        <Stack alignItems={"center"} justifyContent={"center"}>
          <img src={forgeBlackLogo} style={{ height: "30px" }} alt="Forge" />
          <MDTypography variant="h5" color={"black"}>
            Thanks for subscribing!
          </MDTypography>
          <MDBox height={20} />
          <MDTypography variant="body2" color={"black"} textAlign={"center"}>
            You have successfully subscribed to Forge!
            <br />
            We will send you an email to confirm your subscription.
          </MDTypography>
          <MDBox height={16} />
        </Stack>
      )}
    </MDBox>
  )
}

export default SubscriptionFlow
