import MDBadge from "components/MDBadge"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import MDTypography from "components/MDTypography"
import { CalendarType, CalendarTypeExtended } from "types/calendar/calendar"
import ForgeLoading from "forge/shared/ForgeLoading/ForgeLoading"

function CalendarTypeTile({
  calendarType,
  enabled = false,
  loading = false,
  disabledText = "Not Configured",
  onSetupTapped
}: {
  calendarType: CalendarType
  enabled?: boolean
  loading?: boolean
  disabledText?: string
  onSetupTapped?: () => void
}): JSX.Element {
  return (
    <MDBox display="flex" justifyContent="space-between" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
      <MDBox display="flex" alignItems="center">
        <img
          src={CalendarTypeExtended.getIcon(calendarType)}
          alt={`${CalendarTypeExtended.getName(calendarType)} logo`}
          style={{ margin: "10px", width: "32px" }}
        />
        <MDBox ml={2}>
          <MDTypography variant="h5" fontWeight="medium">
            {CalendarTypeExtended.getName(calendarType)}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox display="flex" alignItems={{ xs: "flex-start", sm: "center" }} flexDirection={{ xs: "column", sm: "row" }}>
        {loading && <ForgeLoading loading loadingType="small" style={{ width: "50px" }} />}
        <MDBox mx={{ xs: 0, sm: 2 }} mb={{ xs: 1, sm: 0 }}>
          {enabled ? (
            <MDBadge variant="contained" color="success" badgeContent="enabled" container />
          ) : (
            <MDTypography variant="button" color="text" fontWeight="regular">
              {disabledText}
            </MDTypography>
          )}
        </MDBox>
        {onSetupTapped && (
          <MDButton variant="outlined" color="dark" size="small" style={{ textTransform: "none" }} onClick={onSetupTapped}>
            {enabled ? "Modify" : "Set Up"}
          </MDButton>
        )}
      </MDBox>
    </MDBox>
  )
}

export default CalendarTypeTile
