import { firestoreDb } from "firebase.init"
import { User } from "firebase/auth"
import { collection, getDocs, query, where, orderBy, Timestamp, Unsubscribe } from "firebase/firestore"
import { ForgeEncryption } from "forge/core/services/encryption"
import { startOfDay } from "forge/core/utilities"
import { firestoreDebounce } from "forge/core/utils/firestore"
import { UserProfileData } from "types/user/user-profile-data"

class HomeFirestore {
  private user: User
  private userProfileData: UserProfileData
  private encryptionService: ForgeEncryption

  constructor(user: User, userProfileData: UserProfileData, encryptionService: ForgeEncryption) {
    this.user = user
    this.userProfileData = userProfileData
    this.encryptionService = encryptionService
  }

  getAlerts = (onEvent: (alerts: any[]) => void): Unsubscribe => {
    // Start Date
    const today = startOfDay(new Date())

    // End Date
    const future = new Date()
    future.setDate(future.getDate() + 7)

    const collectionRef = query(
      collection(firestoreDb, `users/${this.user.uid}/alerts`),
      where("startDate", ">=", Timestamp.fromDate(today)),
      where("startDate", "<=", Timestamp.fromDate(future)),
      where("completed", "==", false),
      orderBy("startDate", "asc")
    )

    return firestoreDebounce(collectionRef, async snapshot => {
      const alerts = snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }))

      onEvent(alerts)
    })
  }
}

export default HomeFirestore
