import { useContext, useEffect, useState } from "react"

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout"

import firebase from "firebase/compat/app"
import { auth } from "firebaseui"
import "firebaseui/dist/firebaseui.css"
import { AuthContext } from "context"

// Image
import bgImage from "assets/forge/images/forge.png"
import { createUseStyles } from "react-jss"
import SignInButton from "../components/SignInButton"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import { Link } from "react-router-dom"
import { CONTENT_TYPE, getEntries } from "../services/contentful"
import ForgeLoading from "forge/shared/ForgeLoading/ForgeLoading"

const styles = createUseStyles({
  ssoButtons: {
    "& .firebaseui-idp-button": {
      background: "white !important",
      borderRadius: "8px"
    },
    "& .firebaseui-idp-text": {
      color: "#757575 !important"
    },
    "& .firebaseui-idp-icon": {
      filter: "brightness(0) !important"
    }
  }
})

function SignIn(): JSX.Element {
  const [content, setContent] = useState({})
  // UI
  const classes = styles()
  // Context
  const { login, loggingIn, microsoftSso } = useContext(AuthContext)

  useEffect(() => {
    const ui = auth.AuthUI.getInstance() || new auth.AuthUI(firebase.auth())
    try {
      ui.start("#firebaseui-auth-container", {
        signInFlow: "popup",
        // signInSuccessUrl: "/home",
        callbacks: {
          signInSuccessWithAuthResult: function (authResult, _) {
            // Do something with the returned AuthResult.
            // Return type determines whether we continue the redirect
            // automatically or whether we leave that to developer to handle.
            login()
            return false
          },
          signInFailure: function (error) {
            // Some unrecoverable error occurred during sign-in.
            // Return a promise when error handling is completed and FirebaseUI
            // will reset, clearing any UI. This commonly occurs for error code
            // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
            // occurs. Check below for more details on this.
            console.log(JSON.stringify(error))
            return
          },
          uiShown: function () {
            // The widget is rendered.
          }
        },
        signInOptions: [
          // List of OAuth providers supported.
          {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            scopes: ["email", "profile"]
          },
          "apple.com"
        ]
      })

      const getContent = async () => {
        const result = await getEntries({ content_type: CONTENT_TYPE, "fields.slug": "sign-in-page-config" })
        setContent(result)
      }
      getContent()
    } catch (error) {
      console.warn(error)
    }
  }, [login])

  return (
    <IllustrationLayout title="Sign In" description="Select a provider below to sign in" illustration={bgImage} content={content}>
      {loggingIn ? (
        <ForgeLoading loading={loggingIn} loadingType="login" />
      ) : (
        <div>
          <div id="firebaseui-auth-container" className={classes.ssoButtons}></div>
          <SignInButton ssoOption="microsoft" cta="Sign in" onClick={() => microsoftSso()} />
        </div>
      )}
      <MDBox mt={7} mb={1} textAlign="center">
        <MDTypography variant="button" color="text">
          Don't have an account yet?{" "}
          <MDTypography component={Link} to="/create-account" variant="button" color="info" fontWeight="medium" textGradient>
            Sign Up
          </MDTypography>
        </MDTypography>
      </MDBox>
    </IllustrationLayout>
  )
}

export default SignIn
