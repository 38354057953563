import { firestoreDb } from "firebase.init"
import { User } from "firebase/auth"
import { DocumentReference, Timestamp, Unsubscribe, collection, doc, getDoc, onSnapshot, orderBy, query, updateDoc, where } from "firebase/firestore"
import { ForgeEncryption } from "forge/core/services/encryption"
import { ForgeCalendar } from "types/calendar/calendar"
import { CalendarEvent } from "types/calendar/calendar-event"
import { Call } from "types/pipeline/call"
import { UserProfileData } from "types/user/user-profile-data"

class CalendarFirestoreService {
  private user: User
  private userProfileData: UserProfileData
  private encryptionService: ForgeEncryption

  constructor(user: User, userProfileData: UserProfileData, encryptionService: ForgeEncryption) {
    this.user = user
    this.userProfileData = userProfileData
    this.encryptionService = encryptionService
  }

  getCalendars = (onEvent: (calendars: ForgeCalendar[]) => void): Unsubscribe => {
    const collectionRef = collection(
      firestoreDb,
      `users/${this.user.uid}/calendars`
      // `users/s52iBhT2HUYhsHyUF60bprNX8lf1/calendars`
    )

    return onSnapshot(collectionRef, async snapshot => {
      const calendarsData = snapshot.docs.map(doc => ForgeCalendar.fromMap(doc.data()))
      onEvent(calendarsData)
    })
  }

  updateCalendar = async (calendar: ForgeCalendar): Promise<void> => {
    const documentRef = doc(firestoreDb, `users/${this.user.uid}/calendars/${calendar.id}`)

    await updateDoc(documentRef, calendar.toMap())
    return
  }

  getEventsLive = (startDate: Date, onEvent: (events: CalendarEvent[]) => void): Unsubscribe => {
    const collectionRef = query(
      collection(firestoreDb, `users/${this.user.uid}/events`),
      // collection(firestoreDb, `users/7YZiqhhboeUnDkdJZ60oT4GrHTs1/events`),
      where("startDate", ">=", Timestamp.fromDate(startDate)),
      orderBy("startDate", "asc")
    )

    return onSnapshot(collectionRef, async snapshot => {
      const eventsData = snapshot.docs.map(doc => CalendarEvent.fromMap(doc.data(), this.user))
      onEvent(eventsData)
    })
  }

  getEvent = async (userId: string, eventId: string): Promise<any | null> => {
    const docSnapshot = await getDoc(doc(firestoreDb, `users/${userId}/events/${eventId}`))

    if (!docSnapshot.exists()) {
      return null
    }

    return { ...docSnapshot.data(), id: docSnapshot.id }
  }

  getEventCall = async (callRef: DocumentReference): Promise<Call | undefined> => {
    const docSnapshot = await getDoc(callRef)

    if (!docSnapshot.exists()) return

    return await Call.fromMap(
      {
        ...docSnapshot.data(),
        isOrganizationCall: callRef.path.includes("organizations")
      },
      this.encryptionService
    )
  }
}

export default CalendarFirestoreService
