export const DEFAULT_STAGES = {
  default: [
    {
      label: "Appointment Scheduled",
      displayOrder: 0,
      metadata: {
        isClosed: false,
        probability: 0.2
      },
      id: "appointmentscheduled",
      archived: false
    },
    {
      label: "Qualified To Buy",
      displayOrder: 1,
      metadata: {
        isClosed: false,
        probability: 0.4
      },
      id: "qualifiedtobuy",
      archived: false
    },
    {
      label: "Presentation Scheduled",
      displayOrder: 2,
      metadata: {
        isClosed: false,
        probability: 0.6
      },
      id: "presentationscheduled",
      archived: false
    },
    {
      label: "Decision Maker Bought-In",
      displayOrder: 3,
      metadata: {
        isClosed: false,
        probability: 0.8
      },
      id: "decisionmakerboughtin",
      archived: false
    },
    {
      label: "Contract Sent",
      displayOrder: 4,
      metadata: {
        isClosed: false,
        probability: 0.9
      },
      id: "contractsent",
      archived: false
    },
    {
      label: "Closed Won",
      displayOrder: 5,
      metadata: {
        isClosed: true,
        probability: 1
      },
      id: "closedwon",
      archived: false
    },
    {
      label: "Closed Lost",
      displayOrder: 6,
      metadata: {
        isClosed: true,
        probability: 0
      },
      id: "closedlost",
      archived: false
    }
  ]
}
