import React, { ReactNode } from "react"
import { ContactContextProvider } from "../services/ContactContext"
import { MilestonesContextProvider } from "../services/MilestonesContext"
import { CommitmentsContextProvider } from "forge/commitments/services/CommitmentsContext"
import { KnowledgeContextProvider } from "forge/knowledge/services/KnowledgeContext"
import { NewsContextProvider } from "forge/outreach/news/services/NewsContext"
import { ListenContextProvider } from "../services/ListenContext"
import { CallsContextProvider } from "forge/pipeline/calls/services/CallsContext"
import { LinkedInProfileContextProvider } from "../services/LinkedInProfileContext"

interface Props {
  children: ReactNode
  contact: any
}

const ContextWrapper: React.FC<Props> = ({ children, contact }) => {
  return (
    <ContactContextProvider contact={contact}>
      <KnowledgeContextProvider contact={contact}>
        <CommitmentsContextProvider contact={contact} isContactPage>
          <MilestonesContextProvider contact={contact}>
            <NewsContextProvider contact={contact}>
              <ListenContextProvider contact={contact}>
                <CallsContextProvider contact={contact} isContactPage>
                  <LinkedInProfileContextProvider contact={contact}>{children}</LinkedInProfileContextProvider>
                </CallsContextProvider>
              </ListenContextProvider>
            </NewsContextProvider>
          </MilestonesContextProvider>
        </CommitmentsContextProvider>
      </KnowledgeContextProvider>
    </ContactContextProvider>
  )
}

export default ContextWrapper
