import { User } from "firebase/auth"
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions"
import { ForgeEncryption } from "forge/core/services/encryption"
import { VirgilService } from "forge/core/services/encryption/virgil"
import HttpService from "forge/core/services/http.service"
import { UserEmail } from "types/user/user-email"
import { UserProfileData } from "types/user/user-profile-data"

class SettingsApi {
  private user: User
  private userProfileData: UserProfileData
  private encryptionService: ForgeEncryption

  constructor(user: User, userProfileData: UserProfileData, encryptionService: ForgeEncryption) {
    this.user = user
    this.userProfileData = userProfileData
    this.encryptionService = encryptionService
  }

  updateBasicInfo = async (data: {
    firstName: string
    lastName: string
    preferredName: string
    company: string
    linkedInUrl: string
    emails: UserEmail[]
  }): Promise<any> => {
    const endpoint = `user`

    return await HttpService.patch(endpoint, { userData: data })
  }

  createOrganizationConnection = async (integration: string): Promise<any> => {
    try {
      const functions = getFunctions()
      if (process.env.REACT_APP_DEV === "true") {
        connectFunctionsEmulator(functions, "0.0.0.0", 5001)
      }
      const callable = httpsCallable(functions, "pipeline-createOrganizationConnection")
      let result = await callable({ integration: integration })

      return result.data
    } catch (error) {
      console.warn(error)
    }
  }

  verifyOrganizationMembership = async (integration: string): Promise<any> => {
    try {
      const functions = getFunctions()
      if (process.env.REACT_APP_DEV === "true") {
        connectFunctionsEmulator(functions, "0.0.0.0", 5001)
      }
      const callable = httpsCallable(functions, "pipeline-verifyorganizationmembership")
      let result = await callable({ integration: integration })

      return result.data
    } catch (error) {
      console.warn(error)
    }
  }

  verifyGoogleEmail = async (code: string, account: string): Promise<any> => {
    const endpoint = `user/auth/email/google?code=${code}&account=${account}`
    return await HttpService.get(endpoint)
  }

  verifyMicrosoftEmail = async (code: string, account: string): Promise<any> => {
    const endpoint = `user/auth/email/microsoft?code=${code}&account=${account}`
    return await HttpService.get(endpoint)
  }

  authGoogleCalendar = async (code: string): Promise<any> => {
    const endpoint = `calendars/auth/google?code=${code}`

    return await HttpService.get(endpoint)
  }

  authMicrosoftCalendar = async (code: string): Promise<any> => {
    const endpoint = `calendars/auth/microsoft?code=${code}`

    return await HttpService.get(endpoint)
  }

  getCalendars = async (): Promise<any> => {
    try {
      const functions = getFunctions()
      if (process.env.REACT_APP_DEV === "true") {
        connectFunctionsEmulator(functions, "0.0.0.0", 5001)
      }
      const callable = httpsCallable(functions, "calendars-getcalendars")

      const now = new Date()
      const oneHourFromNow = new Date(now.getTime() + 60 * 60 * 1000)
      let result = await callable({
        googleAuthCredentials: this.userProfileData.googleCredentials.map(credential => credential.toMap()) ?? [],
        microsoftAuthCredentials: this.userProfileData.microsoftCredentials.map(credential => credential.toMap()) ?? []
      })

      return result.data
    } catch (error) {
      console.warn(error)
    }
  }

  createCheckoutSession = async (selectedPlan: string, individualPlan: boolean): Promise<any> => {
    if (selectedPlan) {
      const endpoint = `billing/create-checkout-session`
      return HttpService.post(endpoint, { plan: selectedPlan, individualPlan })
    }
  }

  cancelSubscription = async (subscriptionId: string): Promise<any> => {
    if (subscriptionId) {
      const endpoint = `billing/subscription/${subscriptionId}`
      return HttpService.delete(endpoint)
    }
  }
}

export default SettingsApi
