import { useContext, useEffect, useRef, useState } from "react"

// @mui material components
import Card from "@mui/material/Card"
import Divider from "@mui/material/Divider"

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"

// Material Dashboard 2 PRO React TS components
import { AuthContext, CalendarsContext } from "context"
import { CalendarType, ForgeCalendar } from "types/calendar/calendar"
import CalendarTile from "./CalendarTile"
import CalendarTypeTile from "./CalendarTypeTile"
import { useGoogleLogin } from "@react-oauth/google"
import SettingsApi from "forge/settings/services/api"
import EventsApi from "forge/calendar/services/api"
import { getAuth } from "firebase/auth"
import BillingDialog from "../SettingsBilling/BillingDialog"
import { OrganizationContext } from "forge/organization/services/OrganizationContext"
import { Stack, Switch } from "@mui/material"
import { SettingsContext } from "forge/settings/services/SettingsContext"

function SettingsCalendars(): JSX.Element {
  // Context
  const { calendars, getCalendarsByType, updateCalendar } = useContext(CalendarsContext)
  const { userProfileData, getCurrentUser } = useContext(AuthContext)
  const { organization } = useContext(OrganizationContext)
  const { updateUserSettings } = useContext(SettingsContext)
  const getCurrentUserRef = useRef(userProfileData)

  // Services
  const { user, userSettings, encryptionService } = getCurrentUser()
  const settingsApi = new SettingsApi(user, getCurrentUserRef.current, encryptionService)

  // State
  const [openBillingDialog, setOpenBillingDialog] = useState(false)
  const [googleCalendars, setGoogleCalendars] = useState<ForgeCalendar[]>([])
  const [outlookCalendars, setOutlookCalendars] = useState<ForgeCalendar[]>([])
  const [localCalendars, setLocalCalendars] = useState<ForgeCalendar[]>([])

  useEffect(() => {
    getCurrentUserRef.current = userProfileData
  }, [userProfileData])

  useEffect(() => {
    setGoogleCalendars(getCalendarsByType(CalendarType.google))
    setOutlookCalendars(getCalendarsByType(CalendarType.outlook))
    setLocalCalendars(getCalendarsByType(CalendarType.local))
  }, [calendars])

  const syncCalendarsAfterAuth = async () => {
    // Redeclare is necessary
    const settingsApi = new SettingsApi(user, getCurrentUserRef.current, encryptionService)
    const eventsApi = new EventsApi(user, getCurrentUserRef.current, encryptionService)

    await settingsApi.getCalendars()
    eventsApi.fetchEvents(getCalendarsByType(CalendarType.google), getCalendarsByType(CalendarType.outlook))
  }

  const onGoogleCalendarSetup = useGoogleLogin({
    flow: "auth-code",
    scope: [
      "openid",
      "email",
      "profile",
      "https://www.googleapis.com/auth/calendar.readonly",
      "https://www.googleapis.com/auth/calendar.events"
    ].join(" "),
    ux_mode: "popup",
    redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URL,
    onSuccess: async tokenResponse => {
      console.log(tokenResponse)
      // handleSuccess(tokenResponse);
      await settingsApi.authGoogleCalendar(tokenResponse.code)
      setTimeout(() => syncCalendarsAfterAuth(), 5000)
    }
  })

  const onOutlookCalendarSetup = async () => {
    if (!organization?.stripeSubscriptionId && !userProfileData?.stripeSubscriptionId) {
      setOpenBillingDialog(true)
      return
    }

    const firebaseAuth = getAuth()
    const token = await firebaseAuth.currentUser.getIdToken()
    if (!token) {
      return
    }

    const endpoint = `/calendars/auth/microsoft?token=${token}`
    const popup = window.open(
      `${process.env.REACT_APP_API_URL}${endpoint}`,
      "targetWindow",
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=500, height=550"
    )

    window.addEventListener("message", async event => {
      let apiUrl = process.env.REACT_APP_API_URL
      if (apiUrl.endsWith("/")) {
        apiUrl = apiUrl.slice(0, -1)
      }

      if (event.origin === apiUrl) {
        if (event.data) {
          let data = event.data

          if (data.code) {
            await settingsApi.authMicrosoftCalendar(data.code)
            setTimeout(() => syncCalendarsAfterAuth(), 5000)
          }
          popup.close()
        }
      }
    })
  }

  return (
    <Card id="calendars">
      <BillingDialog openDialog={openBillingDialog} handleCloseDialog={() => setOpenBillingDialog(false)} />
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">Calendars</MDTypography>
        </MDBox>
        <MDTypography variant="button" color="text">
          Here you can setup and manage your calendar integrations.
        </MDTypography>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <MDTypography variant="button" color="text">
            Automatically add new contacts found in Calendars to Forge
          </MDTypography>
          <Switch
            checked={userSettings.addContactsAutomatically}
            onChange={(_, checked) => updateUserSettings(userSettings.copyWith({ addContactsAutomatically: !userSettings.addContactsAutomatically }))}
          />
        </Stack>
      </MDBox>
      <MDBox pt={2} pb={3} px={3}>
        <CalendarTypeTile
          calendarType={CalendarType.google}
          enabled={userProfileData?.googleCredentials?.length > 0}
          loading={userProfileData?.googleCredentials?.some(e => e.loading)}
          onSetupTapped={() => onGoogleCalendarSetup()}
        />
        {googleCalendars?.map(calendar => (
          <CalendarTile key={calendar.id} calendar={calendar} onEnabled={enabled => updateCalendar(calendar.copyWith({ active: enabled }))} />
        ))}
        <Divider />
        <CalendarTypeTile
          calendarType={CalendarType.outlook}
          enabled={userProfileData?.microsoftCredentials?.length > 0}
          loading={userProfileData?.microsoftCredentials?.some(e => e.loading)}
          onSetupTapped={() => onOutlookCalendarSetup()}
        />
        {outlookCalendars?.map(calendar => (
          <CalendarTile key={calendar.id} calendar={calendar} onEnabled={enabled => updateCalendar(calendar.copyWith({ active: enabled }))} />
        ))}
        <Divider />
        <CalendarTypeTile calendarType={CalendarType.local} disabledText="Only available on mobile" />
        {localCalendars?.map(calendar => (
          <CalendarTile key={calendar.id} calendar={calendar} onEnabled={enabled => updateCalendar(calendar.copyWith({ active: enabled }))} />
        ))}
      </MDBox>
    </Card>
  )
}

export default SettingsCalendars
