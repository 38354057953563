import { ListItem, ListItemText, Stack, Tooltip } from "@mui/material"
import MDBox from "components/MDBox"
import {
  ArrowForwardIos,
  Edit,
  Groups,
  Lock,
  LockOpen,
  NoEncryption,
  NoEncryptionOutlined,
  PersonOff,
  Visibility,
  VisibilityOff
} from "@mui/icons-material"
import { parseKnowledge } from "forge/core/knowledgeParsing"
import { MembersContext } from "forge/organization/members/services/MembersContext"
import { useContext, useEffect, useState } from "react"
import { ForgeOrganizationMember } from "forge/organization/types/member"
import { DocumentReference } from "firebase/firestore"
import MemberTile from "forge/organization/members/components/MemberTile"
import { KnowledgeContext } from "forge/knowledge/services/KnowledgeContext"
import ConfirmationDialog from "forge/core/components/ConfirmationDialog"
import { Knowledge } from "forge/knowledge/schemas/knowledge"
import { AuthContext } from "context"

function KnowledgeCard({
  contact,
  knowledge,
  handleOpenDetailDrawer
}: {
  contact: any
  knowledge: Knowledge
  handleOpenDetailDrawer: (knowledge: any) => void
}) {
  // Context
  const { userId } = useContext(AuthContext)
  const { getMember } = useContext(MembersContext)
  const { markKnowledgeAsPrivate, markKnowledgeAsPublic } = useContext(KnowledgeContext)

  // State
  const [isAuthoredByUser, setIsAuthoredByUser] = useState<boolean>(false)
  const [creatorMember, setMemberCreator] = useState<ForgeOrganizationMember>()
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)

  useEffect(() => {
    if (knowledge?.isOrganizationKnowledge && knowledge?.createdBy && knowledge.createdBy instanceof DocumentReference) {
      setMemberCreator(getMember(knowledge.createdBy.id))
    }
  }, [knowledge])

  useEffect(() => {
    if (knowledge?.isOrganizationKnowledge) {
      setIsAuthoredByUser(true)
      return
    }

    setIsAuthoredByUser(creatorMember && creatorMember.ref.id === userId)
  }, [creatorMember, userId])

  const handleMarkKnowledgeAsPrivate = (e: any) => {
    e?.stopPropagation()
    markKnowledgeAsPrivate(knowledge)
  }

  const handleMarkKnowledgeAsPublic = () => {
    markKnowledgeAsPublic(knowledge)
  }

  const handleCloseConfirmationDialog = (result: boolean): any => {
    setOpenConfirmationDialog(false)
    if (result) {
      handleMarkKnowledgeAsPublic()
    } else {
      handleMarkKnowledgeAsPrivate(undefined)
    }
  }

  const renderConfirmationDialog = (
    <ConfirmationDialog
      openDialog={openConfirmationDialog}
      handleCloseDialog={handleCloseConfirmationDialog}
      title="Are you sure you want to share this insight with your team(s)?"
      description=""
      denyText="Keep private"
      confirmText="Share"
      denyVariant="outlined"
    />
  )

  return (
    <ListItem>
      {renderConfirmationDialog}
      <MDBox
        bgColor={"grey-100"}
        borderRadius="lg"
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "row", sm: "row" }}
        style={{ width: "100%", cursor: "pointer" }}
        mb={2}
        py={1}
        pl={2}
        pr={2}
        onClick={() => handleOpenDetailDrawer(knowledge)}
      >
        <Stack direction="column">
          <ListItemText primary={parseKnowledge(knowledge)} style={{ marginTop: 1, marginBottom: 1 }} />
          {!isAuthoredByUser && creatorMember && knowledge.isOrganizationKnowledge && (
            <MemberTile member={creatorMember} prefixText="Created by " size="small" color="gray" />
          )}
        </Stack>
        <Stack direction="row" spacing={1}>
          {contact?.isOrganizationContact &&
            (knowledge?.isOrganizationKnowledge ? (
              <Groups onClick={handleMarkKnowledgeAsPrivate} />
            ) : (
              <PersonOff
                onClick={e => {
                  e.stopPropagation()
                  return setOpenConfirmationDialog(true)
                }}
              />
            ))}
          {knowledge?.searchTerm?.length > 0 ? <ArrowForwardIos /> : isAuthoredByUser ? <Edit /> : <></>}
        </Stack>
      </MDBox>
    </ListItem>
  )
}

export default KnowledgeCard
