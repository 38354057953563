import { useContext } from "react"
// MUI
import { Divider, Icon } from "@mui/material"

// Material Dashboard
import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"

// Services
import SelectContact from "forge/core/components/SelectContact"
import { AuthContext, ContactsContext } from "context"

function AddContactToOpportunity({
  opportunity,
  handleCloseDrawer,
  clientContacts,
  teamContacts,
  addContactType
}: {
  opportunity: any
  handleCloseDrawer: (contact: any) => void
  clientContacts: any[]
  teamContacts: any[]
  addContactType: string
}) {
  const { userProfileData } = useContext(AuthContext)
  const { getCompanyContacts } = useContext(ContactsContext)
  const companyContacts = getCompanyContacts(addContactType === "client" ? opportunity?.properties?.company.name : userProfileData.company).map(
    contact => contact.id
  )

  return (
    <div style={{ display: "contents" }}>
      {/* Header */}
      <MDBox display="flex" justifyContent="space-between" alignItems="baseline" pt={4} pb={0.5} px={3}>
        <MDBox>
          <MDTypography variant="h5">{"Who do you want to add?"}</MDTypography>
        </MDBox>
        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)"
          })}
          onClick={() => handleCloseDrawer(null)}
        >
          close
        </Icon>
      </MDBox>
      <Divider />

      {/* Body */}
      <SelectContact
        onContactSelected={contact => handleCloseDrawer(contact)}
        filteredIds={addContactType === "client" ? clientContacts.map((e: any) => e.ref?.id) : teamContacts.map((e: any) => e.ref?.id)}
        recommendedIds={companyContacts}
      />
    </div>
  )
}

// Setting default values for the props of AddContactToOpportunity
AddContactToOpportunity.defaultProps = {
  handleCloseDrawer: null
}

export default AddContactToOpportunity
