import { Tooltip } from "@mui/material"

export const DisabledTooltip = ({
  allowed,
  message = "You have insufficient permissions.",
  backgroundColor = "black",
  children
}: {
  allowed: boolean
  message?: string
  backgroundColor?: string
  children: any
}) => {
  return (
    <Tooltip
      title={!allowed && message}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: backgroundColor
          }
        }
      }}
    >
      <div>{children}</div>
    </Tooltip>
  )
}
