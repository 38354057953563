import { firestoreDb } from "firebase.init"
import { User } from "firebase/auth"
import { collection, getDocs, query, where, orderBy, Timestamp, doc, addDoc } from "firebase/firestore"
import { ForgeEncryption } from "forge/core/services/encryption"
import { Knowledge } from "forge/knowledge/schemas/knowledge"
import { UserProfileData } from "types/user/user-profile-data"

class NewsFirestoreService {
  private user: User
  private userProfileData: UserProfileData
  private encryptionService: ForgeEncryption

  constructor(user: User, userProfileData: UserProfileData, encryptionService: ForgeEncryption) {
    this.user = user
    this.userProfileData = userProfileData
    this.encryptionService = encryptionService
  }

  getNewsKnowledgeForContact = async (contactId: string, searchTermsFilter: string[]): Promise<any[]> => {
    const querySnapshot = await getDocs(
      query(
        collection(firestoreDb, `users/${this.user.uid}/contacts/${contactId}/knowledges`),
        where("searchTerm", "array-contains-any", searchTermsFilter.slice(0, 10)),
        orderBy("createdAt", "desc")
      )
    )

    const knowledge = await Promise.all(
      querySnapshot.docs.map(async doc => {
        let knowledge: any = {
          ...doc.data(),
          id: doc.id
        }

        if (knowledge.encrypted) {
          try {
            knowledge.answer = await this.encryptionService.decrypt({
              data: knowledge.answer,
              encrypter: knowledge.encrypter
            })
            knowledge.label = await this.encryptionService.decrypt({
              data: knowledge.label,
              encrypter: knowledge.encrypter
            })
          } catch (error) {
            console.warn(error)
          }
        }

        return knowledge
      })
    )

    const summaryArray: Knowledge[] = []
    const linkedinEducationArray: Knowledge[] = []
    const linkedinExperienceArray: Knowledge[] = []
    const linkedinVolunteerWorkArray: Knowledge[] = []
    const linkedinLocationArray: Knowledge[] = []
    const otherTypeArray: Knowledge[] = []

    knowledge.forEach(knowledge => {
      if (knowledge.type === "KnowledgeType.summary") {
        summaryArray.push(knowledge)
      } else if (knowledge.type === "KnowledgeType.linkedinEducation") {
        linkedinEducationArray.push(knowledge)
      } else if (knowledge.type === "KnowledgeType.linkedinExperience") {
        linkedinExperienceArray.push(knowledge)
      } else if (knowledge.type === "KnowledgeType.linkedinVolunteerWork") {
        linkedinVolunteerWorkArray.push(knowledge)
      } else if (knowledge.type === "KnowledgeType.linkedinLocation") {
        linkedinLocationArray.push(knowledge)
      } else {
        otherTypeArray.push(knowledge)
      }
    })

    let mostRecentSummary: any = null
    if (summaryArray.length > 0) {
      mostRecentSummary = summaryArray.reduce((prev, current) => (prev.createdAt > current.createdAt ? prev : current))
    }

    let mostRecentLinkedinEducation: any = null
    if (linkedinEducationArray.length > 0) {
      mostRecentLinkedinEducation = linkedinEducationArray.reduce((prev, current) => (prev.createdAt > current.createdAt ? prev : current))
    }

    let mostRecentLinkedinExperience: any = null
    if (linkedinExperienceArray.length > 0) {
      mostRecentLinkedinExperience = linkedinExperienceArray.reduce((prev, current) => (prev.createdAt > current.createdAt ? prev : current))
    }

    let mostRecentLinkedinVolunteerWork: any = null
    if (linkedinVolunteerWorkArray.length > 0) {
      mostRecentLinkedinVolunteerWork = linkedinVolunteerWorkArray.reduce((prev, current) => (prev.createdAt > current.createdAt ? prev : current))
    }

    let mostRecentLinkedinLocation: any = null
    if (linkedinLocationArray.length > 0) {
      mostRecentLinkedinLocation = linkedinLocationArray.reduce((prev, current) => (prev.createdAt > current.createdAt ? prev : current))
    }

    const resultArray: any[] = []
    if (mostRecentSummary !== null) resultArray.push(mostRecentSummary)
    if (mostRecentLinkedinEducation !== null) resultArray.push(mostRecentLinkedinEducation)
    if (mostRecentLinkedinExperience !== null) resultArray.push(mostRecentLinkedinExperience)
    if (mostRecentLinkedinVolunteerWork !== null) resultArray.push(mostRecentLinkedinVolunteerWork)
    if (mostRecentLinkedinLocation !== null) resultArray.push(mostRecentLinkedinLocation)
    resultArray.push(...otherTypeArray)

    return resultArray
  }

  createShareLog = async (newsId: number, contactId: string): Promise<void> => {
    try {
      await addDoc(collection(firestoreDb, `users/${this.user.uid}/contacts/${contactId}/shares-log`), {
        newsId,
        createdAt: Timestamp.now()
      })
    } catch (e) {
      console.error("Error writing log: ", e)
    }
  }
}

export default NewsFirestoreService
