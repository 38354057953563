import { ArrowBackIos } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { useNavigate } from "react-router-dom"

function BackButton({ backSteps = -1, color = "black", ...rest }: { backSteps?: number; color?: string; style?: object }) {
  const navigate = useNavigate()

  return (
    <IconButton
      onClick={() => {
        navigate(backSteps)
      }}
      {...rest}
    >
      <ArrowBackIos style={{ color }} />
    </IconButton>
  )
}

export default BackButton
