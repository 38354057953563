// @mui material components

import MDBox from "components/MDBox"
import { Avatar } from "@mui/material"
import { LocationOn } from "@mui/icons-material"
import { parseKnowledge } from "forge/core/knowledgeParsing"
import { getKnowledgeTypeIcon } from "forge/knowledge/schemas/knowledge-type"

function LocationTile({ location, onClick }: { location: any; onClick?: () => void }): JSX.Element {
  return (
    <MDBox display="flex" alignItems="start" lineHeight={1} mb={1.5} style={{ cursor: "pointer" }} onClick={onClick}>
      <Avatar sx={{ bgcolor: "lightgray", width: 44, height: 44, marginTop: "4px" }} variant="rounded" src={location?.logo_url}>
        <LocationOn style={{ color: "white" }} />
      </Avatar>
      <MDBox ml={1}>{parseKnowledge(location)}</MDBox>
      <MDBox flex={1} />
      {getKnowledgeTypeIcon(location?.type)}
    </MDBox>
  )
}

// Setting default values for the props of NewsCard
LocationTile.defaultProps = {
  location: null
}

export default LocationTile
