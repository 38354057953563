import { useState, useEffect, useContext } from "react"
import { useLocation } from "react-router-dom"

// @mui material components
import { Grid, Stack, Divider } from "@mui/material"

// Material Dashboard 2 PRO React components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import Footer from "examples/Footer"
import OrganizationSideNav from "./components/OrganizationSidenav"
import OrganizationMembers from "./members/components/OrganizationMembers"
import OrganizationTeams from "./teams/components/OrganizationTeams"
import OrganizationRoles from "./roles/components/OrganizationRoles"
import OrganizationInfo from "./components/OrganizationInfo"
import SettingsBilling from "../settings/components/SettingsBilling"
import { AuthContext } from "context"
import CreateOrganizationCard from "./components/CreateOrganizationCard"
import JoinOrganizationCard from "./components/JoinOrganizationCard"
import { CrmContextProvider } from "forge/settings/services/CrmContext"
import MDBox from "components/MDBox"
import PendingActivationCard from "./components/PendingActivationCard"
import RejectedCard from "./components/RejectedCard"
import OrganizationActions from "./components/OrganizationActions"
import OrganizationCrmSyncCard from "./components/OrganizationInfo/organization-crm-sync-card"
import OrganizationDivisions from "./components/OrganizationDivisions"
import ForgeCta from "forge/core/components/ForgeCta"
import { Mail } from "@mui/icons-material"
import MDTypography from "components/MDTypography"
import PendingMigrationCard from "./components/PendingMigrationCard"

function OrganizationManagement() {
  // Context
  const { getCurrentUser } = useContext(AuthContext)
  const { userProfileData } = getCurrentUser()

  const handleRequestInvitationEmail = async () => {
    const name = `${userProfileData.firstName} ${userProfileData.lastName}`.trim()
    let mailTitle = `Forge Organization - Invitation Request by ${name}`
    let mailBody = `Dear [Admin's Name],\n\nI hope this message finds you well. Could you kindly invite me to Forge at your earliest convenience? I would greatly appreciate it.\n\nThank you!\n\nBest regards,\n${name}`

    window.location.href = `mailto:?subject=${mailTitle}&body=${encodeURIComponent(mailBody)}`
  }

  if (userProfileData?.organization?.rejected) {
    return (
      <DashboardLayout>
        <Stack direction="row" spacing={5} justifyContent="center">
          <MDBox style={{ marginTop: "18vh", marginBottom: "18vh" }}>
            <RejectedCard />
          </MDBox>
        </Stack>
      </DashboardLayout>
    )
  }

  if (userProfileData?.organization?.pendingMigration) {
    return (
      <DashboardLayout>
        <Stack direction="row" spacing={5} justifyContent="center">
          <MDBox style={{ marginTop: "18vh", marginBottom: "18vh" }}>
            <PendingMigrationCard />
          </MDBox>
        </Stack>
      </DashboardLayout>
    )
  }

  if (userProfileData?.organization?.pendingActivation && !userProfileData?.organization?.id) {
    return (
      <DashboardLayout>
        <Stack direction="row" spacing={5} justifyContent="center">
          <MDBox style={{ marginTop: "18vh", marginBottom: "18vh" }}>
            <PendingActivationCard />
          </MDBox>
        </Stack>
      </DashboardLayout>
    )
  }

  if (!userProfileData?.organization?.id) {
    return (
      <DashboardLayout>
        <Stack direction="row" spacing={5} justifyContent="center">
          <MDBox style={{ marginTop: "18vh", marginBottom: "18vh", justifyContent: "center" }}>
            <CreateOrganizationCard />
            <MDBox width="75%" style={{ margin: "0 auto" }}>
              <ForgeCta
                cta="Need to join an organization with an existing Forge account? Please reach out to the organization’s admin and request an invite."
                icon={<Mail />}
                onClick={handleRequestInvitationEmail}
              />
              <MDTypography variant="body2" fontSize={"medium"} align="center" style={{ color: "gray" }}>
                For security reasons, we cannot identify your organization’s admin, if any. Sorry.
              </MDTypography>
            </MDBox>
          </MDBox>
        </Stack>
      </DashboardLayout>
    )
  }

  return (
    <DashboardLayout>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={2}>
          <OrganizationSideNav />
        </Grid>
        <Grid item xs={12} lg={9.5}>
          <MDBox mb={3}>
            <Grid item xs={12}>
              <OrganizationInfo />
            </Grid>
            <Grid item xs={12}>
              <SettingsBilling section={"organization"} />
            </Grid>
            {/* <Grid item xs={12}>
              <OrganizationDivisions />
            </Grid> */}
            <Grid item xs={12}>
              <OrganizationCrmSyncCard />
            </Grid>
            <Grid item xs={12}>
              <OrganizationMembers />
            </Grid>
            <Grid item xs={12}>
              <OrganizationTeams />
            </Grid>
            <Grid item xs={12}>
              <OrganizationRoles />
            </Grid>
            <Grid item xs={12}>
              <OrganizationActions />
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  )
}

export default OrganizationManagement
