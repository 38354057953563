import { createContext, useContext, useState, useEffect, useRef } from "react"
import { AuthContext, ContactsContext } from "context"
import MilestonesFirestore from "./firestore"

interface MilestonesContextType {
  milestones: any[]
  loading: boolean
}

export const MilestonesContext = createContext<MilestonesContextType>({
  milestones: [],
  loading: false
})

export const MilestonesContextProvider = ({ contact, children }: { contact?: any; children: any }) => {
  const { getCurrentUser, isEncryptionInitialized } = useContext(AuthContext)
  const { contacts, getContacts } = useContext(ContactsContext)
  const contactsContextRef = useRef(getContacts) // Ref to hold the context value

  // Services
  const { user, encryptionService, userProfileData } = getCurrentUser()
  const milestonesFirestoreService = new MilestonesFirestore(user, userProfileData, encryptionService)

  // State
  const [loading, setLoading] = useState<boolean>(true)
  const [milestones, setMilestones] = useState<any[]>([])
  const [personalMilestones, setPersonalMilestones] = useState<any[]>([])
  const [organizationMilestones, setOrganizationMilestones] = useState<any[]>([])

  useEffect(() => {
    setMilestones([...personalMilestones, ...organizationMilestones])
  }, [personalMilestones, organizationMilestones])

  useEffect(() => {
    if (user) {
      const unsubscribe = milestonesFirestoreService.getPersonalMilestonesLive(async milestones => {
        // Decrypt knowledge
        let decrypted = await Promise.allSettled(
          milestones.map(async (milestone: any) => {
            if (encryptionService && milestone.encrypted) {
              milestone.name = await encryptionService.decrypt({
                data: milestone.name,
                encrypter: milestone.encrypter
              })
            }

            return matchContacts(milestone)
          })
        )

        let finalMilestones = decrypted
          .filter((e: any) => e.status === "fulfilled")
          .map<any>((e: any) => e.value)
          .filter((e: any) => !e.completed)

        setLoading(false)
        setPersonalMilestones(finalMilestones)
      })

      return () => unsubscribe()
    }
  }, [isEncryptionInitialized])

  useEffect(() => {
    if (user && userProfileData?.doesUserBelongsToAnOrganization) {
      const unsubscribe = milestonesFirestoreService.getOrganizationMilestonesLive(async milestones => {
        // Decrypt knowledge
        let decrypted = await Promise.allSettled(
          milestones.map(async (milestone: any) => {
            if (encryptionService && milestone.encrypted) {
              milestone.name = await encryptionService.decrypt({
                data: milestone.name,
                encrypter: milestone.encrypter
              })
            }

            return matchContacts(milestone)
          })
        )

        let finalMilestones = decrypted
          .filter((e: any) => e.status === "fulfilled")
          .map<any>((e: any) => e.value)
          .filter((e: any) => !e.completed)

        setLoading(false)
        setOrganizationMilestones(finalMilestones)
      })

      return () => unsubscribe()
    } else {
      setOrganizationMilestones([])
    }
  }, [userProfileData?.organization?.id, userProfileData?.organization?.pendingMigration])

  useEffect(() => {
    contactsContextRef.current = getContacts // Update the ref whenever the context value changes
    setPersonalMilestones(() => {
      for (let milestone of personalMilestones) {
        milestone = matchContacts(milestone)
      }

      return personalMilestones
    })

    setOrganizationMilestones(() => {
      for (let milestone of organizationMilestones) {
        milestone = matchContacts(milestone)
      }

      return organizationMilestones
    })
  }, [contacts])

  const matchContacts = (milestone: any): any => {
    milestone.contacts = contactsContextRef.current(milestone.contactRefs.map((e: any) => e.id))
    return milestone
  }

  return <MilestonesContext.Provider value={{ milestones, loading }}>{children}</MilestonesContext.Provider>
}
