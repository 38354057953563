import { decode } from "html-entities"

export class LinkedInProfileOption {
  kind?: string | null
  title?: string | null
  htmlTitle?: string | null
  link: string
  displayLink?: string | null
  snippet?: string | null
  htmlSnippet?: string | null
  formattedUrl?: string | null
  htmlFormattedUrl?: string | null
  pageMap?: PageMap | null
  level: number
  order: number
  firstNameSimilarity: number

  constructor({
    kind,
    title,
    htmlTitle,
    link,
    displayLink,
    snippet,
    htmlSnippet,
    formattedUrl,
    htmlFormattedUrl,
    pageMap,
    level,
    order,
    firstNameSimilarity
  }: {
    kind?: string | null
    title?: string | null
    htmlTitle?: string | null
    link: string
    displayLink?: string | null
    snippet?: string | null
    htmlSnippet?: string | null
    formattedUrl?: string | null
    htmlFormattedUrl?: string | null
    pageMap?: PageMap | null
    level: number
    order: number
    firstNameSimilarity: number
  }) {
    this.kind = kind
    this.title = title
    this.htmlTitle = htmlTitle
    this.link = link
    this.displayLink = displayLink
    this.snippet = snippet
    this.htmlSnippet = htmlSnippet
    this.formattedUrl = formattedUrl
    this.htmlFormattedUrl = htmlFormattedUrl
    this.pageMap = pageMap
    this.level = level
    this.order = order
    this.firstNameSimilarity = firstNameSimilarity
  }

  copyWith({
    kind,
    title,
    htmlTitle,
    link,
    displayLink,
    snippet,
    htmlSnippet,
    formattedUrl,
    htmlFormattedUrl,
    pageMap,
    level,
    order,
    firstNameSimilarity
  }: {
    kind?: string | null
    title?: string | null
    htmlTitle?: string | null
    link?: string
    displayLink?: string | null
    snippet?: string | null
    htmlSnippet?: string | null
    formattedUrl?: string | null
    htmlFormattedUrl?: string | null
    pageMap?: PageMap | null
    level?: number
    order?: number
    firstNameSimilarity?: number
  }): LinkedInProfileOption {
    return new LinkedInProfileOption({
      kind: kind !== undefined ? kind : this.kind,
      title: title !== undefined ? title : this.title,
      htmlTitle: htmlTitle !== undefined ? htmlTitle : this.htmlTitle,
      link: link !== undefined ? link : this.link,
      displayLink: displayLink !== undefined ? displayLink : this.displayLink,
      snippet: snippet !== undefined ? snippet : this.snippet,
      htmlSnippet: htmlSnippet !== undefined ? htmlSnippet : this.htmlSnippet,
      formattedUrl: formattedUrl !== undefined ? formattedUrl : this.formattedUrl,
      htmlFormattedUrl: htmlFormattedUrl !== undefined ? htmlFormattedUrl : this.htmlFormattedUrl,
      pageMap: pageMap !== undefined ? pageMap : this.pageMap,
      level: level !== undefined ? level : this.level,
      order: order !== undefined ? order : this.order,
      firstNameSimilarity: firstNameSimilarity !== undefined ? firstNameSimilarity : this.firstNameSimilarity
    })
  }

  toMap(): Record<string, any> {
    return {
      kind: this.kind,
      title: this.title,
      htmlTitle: this.htmlTitle,
      link: this.link,
      displayLink: this.displayLink,
      snippet: this.snippet,
      htmlSnippet: this.htmlSnippet,
      formattedUrl: this.formattedUrl,
      htmlFormattedUrl: this.htmlFormattedUrl,
      pagemap: this.pageMap?.toMap(),
      level: this.level,
      order: this.order,
      firstNameSimilarity: this.firstNameSimilarity
    }
  }

  static fromMap(map: Record<string, any>): LinkedInProfileOption {
    return new LinkedInProfileOption({
      kind: map["kind"],
      title: map["title"],
      htmlTitle: map["htmlTitle"],
      link: map["link"],
      displayLink: map["displayLink"],
      snippet: map["snippet"],
      htmlSnippet: map["htmlSnippet"],
      formattedUrl: map["formattedUrl"],
      htmlFormattedUrl: map["htmlFormattedUrl"],
      pageMap: map["pagemap"] !== null ? PageMap.fromMap(map["pagemap"]) : null,
      level: map["level"] !== undefined ? map["level"] : 1,
      order: map["order"] !== undefined ? map["order"] : 0,
      firstNameSimilarity:
        map["firstNameSimilarity"] !== null
          ? typeof map["firstNameSimilarity"] === "number"
            ? map["firstNameSimilarity"]
            : parseFloat(map["firstNameSimilarity"])
          : 0.0
    })
  }

  get profilePicture(): string | null {
    return this.pageMap?.metaTags != null && this.pageMap!.metaTags!.length > 0 ? this.pageMap!.metaTags![0].imageUrl : null
  }

  get initials(): string {
    return this.pageMap?.metaTags != null && this.pageMap!.metaTags!.length > 0 ? this.pageMap!.metaTags![0].initials : ""
  }

  get fullName(): string {
    return this.pageMap?.metaTags != null && this.pageMap!.metaTags!.length > 0 ? this.pageMap!.metaTags![0].fullName : ""
  }

  get cleanTitle(): string {
    return this.pageMap?.metaTags != null && this.pageMap!.metaTags!.length > 0 ? this.pageMap!.metaTags![0].cleanTitle ?? "" : ""
  }
}

class PageMap {
  metaTags?: MetaTags[] | null

  constructor({ metaTags }: { metaTags?: MetaTags[] | null }) {
    this.metaTags = metaTags
  }

  copyWith({ metaTags }: { metaTags?: MetaTags[] | null }): PageMap {
    return new PageMap({
      metaTags: metaTags !== undefined ? metaTags : this.metaTags
    })
  }

  toMap(): Record<string, any> {
    return {
      metatags: this.metaTags?.map(x => x.toMap()) || null
    }
  }

  static fromMap(map: Record<string, any>): PageMap {
    return new PageMap({
      metaTags: map["metatags"] !== null ? map["metatags"]?.map((x: Record<string, any>) => MetaTags.fromMap(x)) : null
    })
  }
}

class MetaTags {
  ogImage?: string | null
  twitterCard?: string | null
  platformWorker?: string | null
  alAndroidPackage?: string | null
  locale?: string | null
  alIosUrl?: string | null
  ogDescription?: string | null
  alIosAppStoreId?: string | null
  platform?: string | null
  twitterImage?: string | null
  profileLastName?: string | null
  twitterSite?: string | null
  profileFirstName?: string | null
  ogType?: string | null
  twitterTitle?: string | null
  alIosAppName?: string | null
  ogTitle?: string | null
  alAndroidUrl?: string | null
  viewport?: string | null
  twitterDescription?: string | null
  ogUrl?: string | null
  alAndroidAppName?: string | null

  constructor({
    ogImage,
    twitterCard,
    platformWorker,
    alAndroidPackage,
    locale,
    alIosUrl,
    ogDescription,
    alIosAppStoreId,
    platform,
    twitterImage,
    profileLastName,
    twitterSite,
    profileFirstName,
    ogType,
    twitterTitle,
    alIosAppName,
    ogTitle,
    alAndroidUrl,
    viewport,
    twitterDescription,
    ogUrl,
    alAndroidAppName
  }: {
    ogImage?: string | null
    twitterCard?: string | null
    platformWorker?: string | null
    alAndroidPackage?: string | null
    locale?: string | null
    alIosUrl?: string | null
    ogDescription?: string | null
    alIosAppStoreId?: string | null
    platform?: string | null
    twitterImage?: string | null
    profileLastName?: string | null
    twitterSite?: string | null
    profileFirstName?: string | null
    ogType?: string | null
    twitterTitle?: string | null
    alIosAppName?: string | null
    ogTitle?: string | null
    alAndroidUrl?: string | null
    viewport?: string | null
    twitterDescription?: string | null
    ogUrl?: string | null
    alAndroidAppName?: string | null
  }) {
    this.ogImage = ogImage
    this.twitterCard = twitterCard
    this.platformWorker = platformWorker
    this.alAndroidPackage = alAndroidPackage
    this.locale = locale
    this.alIosUrl = alIosUrl
    this.ogDescription = ogDescription
    this.alIosAppStoreId = alIosAppStoreId
    this.platform = platform
    this.twitterImage = twitterImage
    this.profileLastName = profileLastName
    this.twitterSite = twitterSite
    this.profileFirstName = profileFirstName
    this.ogType = ogType
    this.twitterTitle = twitterTitle
    this.alIosAppName = alIosAppName
    this.ogTitle = ogTitle
    this.alAndroidUrl = alAndroidUrl
    this.viewport = viewport
    this.twitterDescription = twitterDescription
    this.ogUrl = ogUrl
    this.alAndroidAppName = alAndroidAppName
  }

  copyWith({
    ogImage,
    twitterCard,
    platformWorker,
    alAndroidPackage,
    locale,
    alIosUrl,
    ogDescription,
    alIosAppStoreId,
    platform,
    twitterImage,
    profileLastName,
    twitterSite,
    profileFirstName,
    ogType,
    twitterTitle,
    alIosAppName,
    ogTitle,
    alAndroidUrl,
    viewport,
    twitterDescription,
    ogUrl,
    alAndroidAppName
  }: {
    ogImage?: string | null
    twitterCard?: string | null
    platformWorker?: string | null
    alAndroidPackage?: string | null
    locale?: string | null
    alIosUrl?: string | null
    ogDescription?: string | null
    alIosAppStoreId?: string | null
    platform?: string | null
    twitterImage?: string | null
    profileLastName?: string | null
    twitterSite?: string | null
    profileFirstName?: string | null
    ogType?: string | null
    twitterTitle?: string | null
    alIosAppName?: string | null
    ogTitle?: string | null
    alAndroidUrl?: string | null
    viewport?: string | null
    twitterDescription?: string | null
    ogUrl?: string | null
    alAndroidAppName?: string | null
  }): MetaTags {
    return new MetaTags({
      ogImage: ogImage !== undefined ? ogImage : this.ogImage,
      twitterCard: twitterCard !== undefined ? twitterCard : this.twitterCard,
      platformWorker: platformWorker !== undefined ? platformWorker : this.platformWorker,
      alAndroidPackage: alAndroidPackage !== undefined ? alAndroidPackage : this.alAndroidPackage,
      locale: locale !== undefined ? locale : this.locale,
      alIosUrl: alIosUrl !== undefined ? alIosUrl : this.alIosUrl,
      ogDescription: ogDescription !== undefined ? ogDescription : this.ogDescription,
      alIosAppStoreId: alIosAppStoreId !== undefined ? alIosAppStoreId : this.alIosAppStoreId,
      platform: platform !== undefined ? platform : this.platform,
      twitterImage: twitterImage !== undefined ? twitterImage : this.twitterImage,
      profileLastName: profileLastName !== undefined ? profileLastName : this.profileLastName,
      twitterSite: twitterSite !== undefined ? twitterSite : this.twitterSite,
      profileFirstName: profileFirstName !== undefined ? profileFirstName : this.profileFirstName,
      ogType: ogType !== undefined ? ogType : this.ogType,
      twitterTitle: twitterTitle !== undefined ? twitterTitle : this.twitterTitle,
      alIosAppName: alIosAppName !== undefined ? alIosAppName : this.alIosAppName,
      ogTitle: ogTitle !== undefined ? ogTitle : this.ogTitle,
      alAndroidUrl: alAndroidUrl !== undefined ? alAndroidUrl : this.alAndroidUrl,
      viewport: viewport !== undefined ? viewport : this.viewport,
      twitterDescription: twitterDescription !== undefined ? twitterDescription : this.twitterDescription,
      ogUrl: ogUrl !== undefined ? ogUrl : this.ogUrl,
      alAndroidAppName: alAndroidAppName !== undefined ? alAndroidAppName : this.alAndroidAppName
    })
  }

  toMap(): Record<string, any> {
    return {
      "og:image": this.ogImage,
      "twitter:card": this.twitterCard,
      "platform-worker": this.platformWorker,
      "al:android:package": this.alAndroidPackage,
      locale: this.locale,
      "al:ios:url": this.alIosUrl,
      "og:description": this.ogDescription,
      "al:ios:app_store_id": this.alIosAppStoreId,
      platform: this.platform,
      "twitter:image": this.twitterImage,
      "profile:last_name": this.profileLastName,
      "twitter:site": this.twitterSite,
      "profile:first_name": this.profileFirstName,
      "og:type": this.ogType,
      "twitter:title": this.twitterTitle,
      "al:ios:app_name": this.alIosAppName,
      "og:title": this.ogTitle,
      "al:android:url": this.alAndroidUrl,
      viewport: this.viewport,
      "twitter:description": this.twitterDescription,
      "og:url": this.ogUrl,
      "al:android:app_name": this.alAndroidAppName
    }
  }

  static fromMap(map: Record<string, any>): MetaTags {
    return new MetaTags({
      ogImage: map["og:image"],
      twitterCard: map["twitter:card"],
      platformWorker: map["platform-worker"],
      alAndroidPackage: map["al:android:package"],
      locale: map["locale"],
      alIosUrl: map["al:ios:url"],
      ogDescription: map["og:description"],
      alIosAppStoreId: map["al:ios:app_store_id"],
      platform: map["platform"],
      twitterImage: map["twitter:image"],
      profileLastName: map["profile:last_name"],
      twitterSite: map["twitter:site"],
      profileFirstName: map["profile:first_name"],
      ogType: map["og:type"],
      twitterTitle: map["twitter:title"],
      alIosAppName: map["al:ios:app_name"],
      ogTitle: map["og:title"],
      alAndroidUrl: map["al:android:url"],
      viewport: map["viewport"],
      twitterDescription: map["twitter:description"],
      ogUrl: map["og:url"],
      alAndroidAppName: map["al:android:app_name"]
    })
  }

  get fullName(): string {
    let fullName = ""
    fullName += this.profileFirstName ?? ""
    fullName += " "
    fullName += this.profileLastName ?? ""

    return fullName.trim()
  }

  get initials(): string {
    let result = ""
    if (this.profileFirstName != null && this.profileFirstName != "") {
      result += this.profileFirstName[0]
    }
    if (this.profileLastName != null && this.profileLastName != "") {
      result += this.profileLastName[0]
    }
    return result.toUpperCase()
  }

  get cleanTitle(): string | null {
    let text = decode(this.ogTitle ?? "")
    text = text.replace("| LinkedIn", "")
    text = text.replace(`${this.fullName} - `, "")
    return text.trim() || null
  }

  get imageUrl(): string | null {
    if ((this.ogImage?.includes("dms/image") ?? false) || (this.ogImage?.includes("forge-development.appspot.com") ?? false)) {
      return this.ogImage
    }
    return null
  }
}
