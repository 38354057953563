// prop-types is a library for typechecking of props
import PropTypes from "prop-types"

// @mui material components
import Card from "@mui/material/Card"
import Icon from "@mui/material/Icon"

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"

// Images
import { Avatar, CardMedia, IconButton, Stack } from "@mui/material"
import { ForgeOpportunity } from "types/pipeline/opportunity"
import PieChart from "examples/Charts/PieChart"
import { Pie } from "react-chartjs-2"
import {
  Add,
  AddIcCall,
  ApartmentRounded,
  CalendarMonth,
  CalendarMonthOutlined,
  EditCalendar,
  ForwardToInboxOutlined,
  Mail,
  MailOutlineOutlined,
  TaskAlt
} from "@mui/icons-material"
import { useEffect, useState } from "react"
import BackButton from "forge/core/components/BackButton"

function DealHeaderCard({
  color,
  opportunity,
  handleOpenDrawer,
  handleOpenEventDrawer,
  handleOpenCallDrawer,
  handleOpenCommitmentDrawer
}: {
  color: any
  opportunity: ForgeOpportunity
  handleOpenDrawer: () => void
  handleOpenEventDrawer: () => void
  handleOpenCallDrawer: () => void
  handleOpenCommitmentDrawer: () => void
}) {
  const [stage, setStage] = useState<number>(0)

  useEffect(() => {
    if (opportunity) {
      setStage(opportunity.getPercentageFromStageOpportunity(false))
    }
  }, [opportunity])

  return (
    <Card
      sx={({ palette: { gradients }, functions: { linearGradient }, boxShadows: { xl } }) => ({
        background: gradients[color]
          ? linearGradient(gradients[color].main, gradients[color].state)
          : linearGradient(gradients.dark.main, gradients.dark.state),
        boxShadow: xl,
        position: "relative"
      })}
    >
      <MDBox
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        opacity={0.2}
        sx={{
          backgroundColor: `black`,
          backgroundSize: "cover"
        }}
      />
      <MDBox position="relative" zIndex={2} p={2}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <Stack direction="row">
            <BackButton color="white" />
            <Avatar sx={{ width: 56, height: 56 }} style={{ background: "black" }} variant="rounded" src={opportunity?.properties?.company?.imageUrl}>
              <ApartmentRounded style={{ color: "white" }} />
            </Avatar>
          </Stack>
          <IconButton size="small" aria-label="edit" color="inherit" onClick={handleOpenDrawer}>
            <Icon fontSize="small" style={{ color: "white" }}>
              edit
            </Icon>
          </IconButton>
        </MDBox>
        <MDTypography variant="h5" color="white" fontWeight="medium" sx={{ mt: 2 }}>
          {opportunity?.properties?.name}
        </MDTypography>
        <MDTypography variant="body2" color="white" fontWeight="medium" sx={{ mb: 4 }}>
          {opportunity?.properties?.company?.name}
        </MDTypography>
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox display="flex" alignItems="center">
            <MDBox lineHeight={1}>
              <MDTypography variant="button" color="white" fontWeight="regular" opacity={0.8}>
                Stage
              </MDTypography>
              <MDTypography variant="h6" color="white" fontWeight="medium">
                {opportunity?.properties?.stageDetails?.stage?.label} until {new Date(opportunity?.properties?.closeDate).toDateString()}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" justifyContent="flex-end" width="10%">
            <Pie
              data={{
                labels: [],
                datasets: [
                  {
                    label: [],
                    weight: 9,
                    cutout: 0,
                    tension: 0.9,
                    pointRadius: 2,
                    borderWidth: 2,
                    backgroundColor: ["dark", "white"],
                    fill: false,
                    data: [stage, 100 - stage]
                  }
                ]
              }}
            />
          </MDBox>
        </MDBox>
        <MDBox mt={2} />
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox
            variant="gradient"
            bgColor={"white"}
            color={"dark"}
            width="3rem"
            height="3rem"
            borderRadius="md"
            display="flex"
            justifyContent="center"
            alignItems="center"
            shadow="md"
            style={{ cursor: "pointer" }}
            onClick={handleOpenCallDrawer}
          >
            <AddIcCall />
          </MDBox>
          <MDBox
            variant="gradient"
            bgColor={"white"}
            color={"dark"}
            width="3rem"
            height="3rem"
            borderRadius="md"
            display="flex"
            justifyContent="center"
            alignItems="center"
            shadow="md"
            style={{ cursor: "pointer", position: "relative" }}
            onClick={handleOpenCommitmentDrawer}
          >
            <TaskAlt />
            <Add style={{ width: "0.5em", position: "absolute", top: "4px", right: "8px" }} />
          </MDBox>
          <MDBox
            variant="gradient"
            bgColor={"white"}
            color={"dark"}
            width="3rem"
            height="3rem"
            borderRadius="md"
            display="flex"
            justifyContent="center"
            alignItems="center"
            shadow="md"
            style={{ cursor: "pointer", position: "relative" }}
            onClick={handleOpenEventDrawer}
          >
            <CalendarMonthOutlined />
            <Add style={{ width: "0.5em", position: "absolute", top: "4px", right: "9px" }} />
          </MDBox>
          <MDBox
            variant="gradient"
            bgColor={"white"}
            color={"dark"}
            width="3rem"
            height="3rem"
            borderRadius="md"
            display="flex"
            justifyContent="center"
            alignItems="center"
            shadow="md"
            style={{ cursor: "pointer", position: "relative" }}
            onClick={() => {
              window.location.href = `mailto:`
            }}
          >
            <MailOutlineOutlined />
            <Add style={{ width: "0.5em", position: "absolute", top: "4px", right: "9px" }} />
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  )
}

// Setting default values for the props of DealHeaderCard
DealHeaderCard.defaultProps = {
  color: "dark"
}

// Typechecking props for the DealHeaderCard
DealHeaderCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  deal: PropTypes.any.isRequired
}

export default DealHeaderCard
