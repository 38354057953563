import { createContext, useContext, useEffect, useState } from "react"
import { ForgeOrganizationMember } from "../types/member"
import { ForgeOrganization } from "../types/organization"
import { AuthContext } from "context"
import OrganizationFirestore from "./firestore"
import OrganizationApi from "./api"
import SettingsApi from "forge/settings/services/api"

interface OrganizationContextType {
  member: ForgeOrganizationMember
  organization: ForgeOrganization
  parentOrganization: ForgeOrganization
  divisions: ForgeOrganization[]
  members: ForgeOrganizationMember[]
  queryDomain: (domain: string) => Promise<any>
  verifyDomainAvailability: (domains: string[]) => Promise<string[]>
  createOrganization: (organization: ForgeOrganization) => Promise<void>
  updateOrganization: (organization: ForgeOrganization) => Promise<void>
  deleteOrganization: () => Promise<void>
  cancelSubscription: (stripeSubscriptionId: string) => Promise<void>
}

export const OrganizationContext = createContext<OrganizationContextType>({
  member: undefined,
  organization: undefined,
  parentOrganization: undefined,
  divisions: [],
  members: [],
  queryDomain: async () => {},
  verifyDomainAvailability: async () => [],
  createOrganization: async () => {},
  updateOrganization: async () => {},
  deleteOrganization: async () => {},
  cancelSubscription: async () => {}
})

export const OrganizationContextProvider = ({ children }: { children: any }) => {
  // Context
  const { getCurrentUser, isEncryptionInitialized } = useContext(AuthContext)
  const { user, encryptionService, userProfileData } = getCurrentUser()

  // Services
  const organizationFirestore = new OrganizationFirestore(user, userProfileData, encryptionService)
  const organizationApi = new OrganizationApi(user, userProfileData, encryptionService)
  const settingsApi = new SettingsApi(user, userProfileData, encryptionService)

  // State
  const [member, setMember] = useState<ForgeOrganizationMember>()
  const [organization, setOrganization] = useState<ForgeOrganization>()
  const [parentOrganization, setParentOrganization] = useState<ForgeOrganization>()
  const [divisions, setDivisions] = useState<ForgeOrganization[]>()
  const [members, setMembers] = useState<ForgeOrganizationMember[]>([])

  // Organization
  useEffect(() => {
    if (isEncryptionInitialized && userProfileData?.organization?.id) {
      const unsubscribe = organizationFirestore.getOrganizationLive(organization => {
        setOrganization(organization)
      })

      return () => unsubscribe()
    }
  }, [isEncryptionInitialized, userProfileData?.organization?.id])

  // Member
  useEffect(() => {
    if (isEncryptionInitialized && userProfileData?.organization?.id) {
      const unsubscribe = organizationFirestore.getMembershipDetails(member => {
        setMember(member)
      })

      return () => unsubscribe()
    }
  }, [isEncryptionInitialized, userProfileData?.organization?.id])

  // Parent Organization
  useEffect(() => {
    if (organization?.parentOrganizationRef) {
      ;(async () => {
        const result = await organizationFirestore.getParentOrganization(organization?.parentOrganizationRef)

        setParentOrganization(result)
      })()
    }
  }, [organization?.parentOrganizationRef])

  useEffect(() => {
    const subOrganizations =
      organization?.subOrganizations && organization.subOrganizations.length > 0
        ? organization.subOrganizations
        : parentOrganization?.subOrganizations && parentOrganization.subOrganizations.length > 0
        ? parentOrganization.subOrganizations
        : []

    if (subOrganizations && subOrganizations.length > 0) {
      const unsubscribe = organizationFirestore.getOrganizationDivisionsLive(subOrganizations, organizations => {
        setDivisions(organizations)
      })

      return () => unsubscribe()
    }
  }, [organization?.subOrganizations, parentOrganization?.subOrganizations])

  const queryDomain = async (domain: string) => {
    let value = await organizationApi.getCompanyFromDomain(domain)
    return value?.company
  }

  const verifyDomainAvailability = async (domains: string[]) => {
    return await organizationFirestore.verifyDomainAvailability(domains)
  }

  const createOrganization = async (organization: ForgeOrganization): Promise<void> => {
    try {
      const result = await encryptionService.createOrganizationGroup(organization.name, userProfileData.encryption.sealdIdentity, organization.domain)
      organization.sealdGroupId = result.data.groupId
      return await organizationApi.createOrganization(organization)
    } catch (error) {
      console.warn(error)
    }
  }

  const updateOrganization = async (organization: ForgeOrganization): Promise<void> => {
    return await organizationApi.updateOrganization(organization)
  }

  const deleteOrganization = async (): Promise<void> => {
    const result = await organizationApi.deleteOrganization()

    if (result.deleteSealdGroup && result.sealdGroupId) {
      try {
        await encryptionService.deleteOrganizationGroup(result.sealdGroupId)
      } catch (error) {
        console.warn(error)
      }
    }
  }

  const cancelSubscription = async (stripeSubscriptionId: string): Promise<void> => {
    return await settingsApi.cancelSubscription(stripeSubscriptionId)
  }

  return (
    <OrganizationContext.Provider
      value={{
        member,
        organization,
        parentOrganization,
        divisions,
        members,
        queryDomain,
        verifyDomainAvailability,
        createOrganization,
        updateOrganization,
        deleteOrganization,
        cancelSubscription
      }}
    >
      {children}
    </OrganizationContext.Provider>
  )
}
