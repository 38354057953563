import { createContext, useContext, useEffect, useRef, useState } from "react"
import { ForgeOrganizationMember } from "../../types/member"
import { AuthContext } from "context"
import OrganizationMembersApi from "./api"
import OrganizationMembersFirestore from "./firestore"
import OrganizationTeamsFirestore from "forge/organization/teams/services/firestore"
import { ForgeOrganizationTeam } from "forge/organization/types/team"
import { OrganizationContext } from "forge/organization/services/OrganizationContext"

interface MembersContextType {
  members: ForgeOrganizationMember[]
  getMember: (contactId: string) => ForgeOrganizationMember
  getMembers: (contactIds: string[]) => ForgeOrganizationMember[]
  getMembersByRole: (role: string) => ForgeOrganizationMember[]
  inviteMember: (team: ForgeOrganizationMember) => Promise<void>
  updateMember: (memberId: string, member: ForgeOrganizationMember) => Promise<void>
  changeRole: (memberId: string, member: ForgeOrganizationMember) => Promise<void>
  acceptMember: (memberId: string, member: ForgeOrganizationMember) => Promise<void>
  rejectMember: (memberId: string) => Promise<void>
  removeMember: (memberId: string, member: ForgeOrganizationMember) => Promise<void>
}

export const MembersContext = createContext<MembersContextType>({
  members: [],
  getMember: _ => null,
  getMembers: _ => [],
  getMembersByRole: _ => [],
  inviteMember: async () => {},
  updateMember: async () => {},
  changeRole: async () => {},
  acceptMember: async () => {},
  rejectMember: async () => {},
  removeMember: async () => {}
})

export const MembersContextProvider = ({ children }: { children: any }) => {
  // Context
  const { getCurrentUser, isEncryptionInitialized } = useContext(AuthContext)
  const { organization } = useContext(OrganizationContext)
  const { user, encryptionService, userProfileData } = getCurrentUser()

  // Services
  const organizationMembersFirestore = new OrganizationMembersFirestore(user, userProfileData, encryptionService)
  const organizationMembersApi = new OrganizationMembersApi(user, userProfileData, encryptionService)
  const organizationTeamsFirestore = new OrganizationTeamsFirestore(user, userProfileData, encryptionService)

  // State
  const [members, setMembers] = useState<ForgeOrganizationMember[]>([])
  const [membersMap, setMembersMap] = useState<{ [key: string]: any }>({})
  const [teamsMap, setTeamsMap] = useState<{ [key: string]: any }>({})
  const teamRef = useRef<any>()

  useEffect(() => {
    if (isEncryptionInitialized) {
      if (userProfileData?.doesUserBelongsToAnOrganization) {
        const unsubscribe = organizationMembersFirestore.getMembersLive(members => {
          members = matchTeams(members)
          setMembers(members)

          const membersMapped = members.reduce<{ [key: string]: any }>((acc, currentValue, currentIndex, __) => {
            acc[currentValue.ref.id] = currentValue
            return acc
          }, {})
          setMembersMap(membersMapped)
          pendingRemovalMembers(members)
        })

        return () => unsubscribe()
      }
    } else {
      setMembersMap({})
    }
  }, [isEncryptionInitialized, userProfileData?.organization?.id, userProfileData?.organization?.pendingMigration])

  useEffect(() => {
    if (isEncryptionInitialized) {
      if (userProfileData?.doesUserBelongsToAnOrganization) {
        const unsubscribe = organizationTeamsFirestore.getTeamsLive((teams, _) => {
          const teamsMapped = teams.reduce<{ [key: string]: any }>((acc, currentValue, currentIndex, __) => {
            acc[currentValue.ref.id] = currentValue
            return acc
          }, {})

          teamRef.current = teamsMapped
          return setTeamsMap(teamsMapped)
        })

        return () => unsubscribe()
      }
    } else {
      setTeamsMap({})
    }
  }, [isEncryptionInitialized, userProfileData?.organization?.id, userProfileData?.organization?.pendingMigration])

  useEffect(() => {
    let updatedMembers = matchTeams(members)
    setMembers(updatedMembers)

    const membersMapped = updatedMembers.reduce<{ [key: string]: any }>((acc, currentValue, currentIndex, __) => {
      acc[currentValue.ref.id] = currentValue
      return acc
    }, {})
    setMembersMap(membersMapped)
  }, [teamRef.current])

  const getMember = (memberId: string): any => {
    return membersMap[memberId]
  }

  const getMembers = (membersIds: string[]): ForgeOrganizationMember[] => {
    let members = []

    for (const membersId of membersIds) {
      members.push(membersMap[membersId])
    }

    return members.filter(e => e)
  }

  const getMembersByRole = (role: string): ForgeOrganizationMember[] => {
    return members.filter(e => e.role === role)
  }

  const getTeams = (teamsIds: string[]): ForgeOrganizationTeam[] => {
    let teams = []

    for (const teamsId of teamsIds) {
      teams.push(teamRef.current[teamsId])
    }

    return teams.filter(e => e)
  }

  const matchTeams = (members: ForgeOrganizationMember[]): ForgeOrganizationMember[] => {
    if (teamRef.current && Object.values(teamRef.current).length > 0) {
      for (const member of members) {
        if (member.teamsRefs?.length > 0) {
          member.teams = getTeams(member.teamsRefs.map(ref => ref.id))
        }
      }
    }

    return members
  }

  const inviteMember = async (member: ForgeOrganizationMember): Promise<void> => {
    member.status = "invited"
    return await organizationMembersApi.inviteMember(member)
  }

  const updateMember = async (memberId: string, member: ForgeOrganizationMember): Promise<void> => {
    return await organizationMembersApi.updateMember(memberId, member)
  }

  const changeRole = async (memberId: string, member: ForgeOrganizationMember) => {
    try {
      await encryptionService.manageGroupAdmins(organization.sealdGroupId, member.sealdId, member.role !== "admin")
      await organizationMembersApi.updateMember(memberId, member)
    } catch (error) {
      console.warn(error)
    }
    return
  }

  const acceptMember = async (memberId: string, member: ForgeOrganizationMember): Promise<void> => {
    try {
      await encryptionService.addMemberToGroup(organization.sealdGroupId, member.sealdId, member.role === "admin")
      await organizationMembersApi.acceptMember(memberId)
    } catch (error) {
      console.warn(error)
    }
    return
  }

  const rejectMember = async (memberId: string): Promise<void> => {
    try {
      await organizationMembersApi.rejectMember(memberId)
    } catch (error) {
      console.warn(error)
    }
    return
  }

  const removeMember = async (memberId: string, member: ForgeOrganizationMember): Promise<void> => {
    try {
      await organizationMembersApi.removeMember(memberId)
    } catch (error) {
      console.warn(error)
    }
    return
  }

  const pendingRemovalMembers = async (members: ForgeOrganizationMember[]): Promise<void> => {
    const membersToRemove = members.filter(member => member.status === "removed")
    for (const member of membersToRemove) {
      try {
        await encryptionService.removeMemberFromGroup(organization.sealdGroupId, member.sealdId)
        organizationMembersApi.deleteMember(member.ref?.id)
      } catch (error) {
        console.warn(error)
      }
    }
  }

  return (
    <MembersContext.Provider
      value={{
        members,
        getMember,
        getMembers,
        getMembersByRole,
        inviteMember,
        updateMember,
        changeRole,
        acceptMember,
        rejectMember,
        removeMember
      }}
    >
      {children}
    </MembersContext.Provider>
  )
}
