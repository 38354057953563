import SealdSDKConstructor, { type SealdSDK } from "@seald-io/sdk"
import SealdSDKPluginSSKSPassword from "@seald-io/sdk-plugin-ssks-password"
import HttpService from "../http.service"
import { Auth, getAuth } from "firebase/auth"
import { EncryptionResult } from "."

let sealdSDKInstance: SealdSDK
export const getSealdSDKInstance = (): SealdSDK => sealdSDKInstance

const instantiateSealdSDK = async () => {
  if (sealdSDKInstance) return

  sealdSDKInstance = SealdSDKConstructor({
    appId: process.env.REACT_APP_SEALD_APP_ID,
    apiURL: process.env.REACT_APP_SEALD_API_URL,
    plugins: [SealdSDKPluginSSKSPassword(process.env.REACT_APP_SEALD_KEY_STORAGE_URL)]
  })
}

export class SealdService {
  private firebaseAuth: Auth

  constructor() {
    this.firebaseAuth = getAuth()
    instantiateSealdSDK()
  }

  getSealdJwt = async () => {
    let token = await this.firebaseAuth.currentUser.getIdToken()
    const tokenEndpoint = "user/seald/token"

    // Have to pass auth token because Axios is not initialized yet
    let tokenRequest = await HttpService.get(tokenEndpoint, undefined, { Authorization: token })

    return tokenRequest.data?.token
  }

  createIdentity = async ({ userId, password }: { userId: string; password: string }): Promise<string> => {
    try {
      const signupJWT = await this.getSealdJwt()

      // Always instantiate the SDK again before creating an identity
      await instantiateSealdSDK()

      // Identity
      const accountInfo = await sealdSDKInstance.initiateIdentity({ signupJWT, displayName: userId })
      await sealdSDKInstance.ssksPassword.saveIdentity({ userId, password })

      return accountInfo.sealdId
    } catch (error) {
      console.error(error)
      return
    }
  }

  cleanup = async () => {
    try {
      await sealdSDKInstance.close()
      sealdSDKInstance = null
    } catch (error) {
      console.error(error)
    }
  }

  retrieveIdentity = async ({ userId, password }: { userId: string; password: string }): Promise<string> => {
    const accountInfo = await sealdSDKInstance.ssksPassword.retrieveIdentity({ userId, password })
    return accountInfo.sealdId
  }

  // --------------------------------ENCRYPTION-------------------------------

  encrypt = async (data: string, organizationId?: string): Promise<EncryptionResult> => {
    await instantiateSealdSDK()

    if (organizationId) {
      return await this.encryptForOrganization(data, organizationId)
    }

    return await this.encryptForIndividual(data)
  }

  private encryptForIndividual = async (
    data: string
  ): Promise<{
    result: any
    encrypter: string
    encrypted: boolean
  }> => {
    try {
      const result = await sealdSDKInstance.encryptMessage(data, {}, { encryptForSelf: true })

      return {
        result,
        encrypter: "seald",
        encrypted: true
      }
    } catch (error) {
      console.warn(error)
      return {
        result: data,
        encrypter: "seald",
        encrypted: false
      }
    }
  }

  private encryptForOrganization = async (
    data: string,
    organizationId: string
  ): Promise<{
    result: any
    encrypter: string
    encrypted: boolean
  }> => {
    try {
      const result = await sealdSDKInstance.encryptMessage(data, { sealdIds: [organizationId] }, { encryptForSelf: false })

      return {
        result,
        encrypter: "seald",
        encrypted: true
      }
    } catch (error) {
      console.warn(error)
      return {
        result: data,
        encrypter: "seald",
        encrypted: false
      }
    }
  }

  // --------------------------------DECRYPTION-------------------------------

  decrypt = async (data: string): Promise<any> => {
    try {
      if (data) {
        let decodedData = JSON.parse(data)
        if ("sessionId" in decodedData) {
          let result = await sealdSDKInstance.decryptMessage(data)
          return result
        } else if ("id" in decodedData) {
          let result = await sealdSDKInstance.decryptFile(decodedData.encryptedFile)
          return result.data
        }
      }
    } catch (error) {
      console.warn(error)
    }
  }

  // ----------------------------------GROUPS---------------------------------

  createOrganizationGroup = async (name: string, sealdId: string, organizationId: string): Promise<any> => {
    try {
      if (!(organizationId && sealdId)) {
        throw {
          error: true,
          message: "Missing params."
        }
      }

      const group = await sealdSDKInstance.createGroup({
        groupName: name, // will be displayed in the administration dashboard. This is just to help you locate the group. You can for example put the ID that your back-end has assigned to this group.
        members: {
          sealdIds: [sealdId] // all members of the group: the users who will be able to read the messages intended for the group. Must contain the user who is creating the group.
        },
        admins: {
          sealdIds: [sealdId] // the group administrators: the users who will be able to add/remove members to the group. Must contain the user who is creating the group.
        }
      })
      console.log(group)

      return {
        success: true,
        message: "E3Kit Group created successfully.",
        data: {
          groupId: group.id
        }
      }
    } catch (error) {
      console.warn(error)
      throw {
        error: true,
        message: JSON.stringify(error)
      }
    }
  }

  deleteOrganizationGroup = async (organizationId: string) => {
    try {
      if (!organizationId) {
        throw {
          error: true,
          message: "Missing params."
        }
      }

      await sealdSDKInstance.deleteGroup(organizationId)

      return {
        success: true,
        message: "E3Kit Group deleted successfully.",
        data: {
          groupId: organizationId
        }
      }
    } catch (error) {
      console.warn(error)
      throw {
        error: true,
        message: JSON.stringify(error)
      }
    }
  }

  addMemberToGroup = async (groupSealdId: string, memberSealdId: string, isAdminRole: boolean) => {
    try {
      const newMember = { sealdIds: [memberSealdId] }
      await sealdSDKInstance.addGroupMembers(groupSealdId, newMember, isAdminRole ? newMember : undefined)

      return {
        success: true,
        message: "Member added successfully."
      }
    } catch (error: any) {
      console.warn(error)
      if (error.code && error.code === "ADD_MEMBERS_ALREADY_IN_GROUP") {
        // Do Nothing
      } else {
        throw {
          error: true,
          message: JSON.stringify(error)
        }
      }
    }
  }

  removeMemberFromGroup = async (groupSealdId: string, memberSealdId: string) => {
    try {
      const newMember = { sealdIds: [memberSealdId] }
      await sealdSDKInstance.removeGroupMembers(groupSealdId, { sealdIds: [memberSealdId] })

      return {
        success: true,
        message: "Member removed successfully."
      }
    } catch (error: any) {
      console.warn(error)
      if (error.code && (error.code === "REMOVE_MEMBERS_NOT_IN_GROUP" || error.code === "SEARCH_GROUP_UNKNOWN_GROUP")) {
        // Do Nothing
      } else {
        throw {
          error: true,
          message: JSON.stringify(error)
        }
      }
    }
  }

  manageGroupAdmins = async (groupSealdId: string, memberSealdId: string, remove: boolean) => {
    try {
      await sealdSDKInstance.setGroupAdmins(groupSealdId, {
        addToAdmins: remove ? undefined : { sealdIds: [memberSealdId] },
        removeFromAdmins: remove ? { sealdIds: [memberSealdId] } : undefined
      })

      return {
        success: true,
        message: `Admin ${remove ? "removed" : "added"} successfully.`
      }
    } catch (error) {
      console.warn(error)
      throw {
        error: true,
        message: JSON.stringify(error)
      }
    }
  }
}