import { Done, Draw, FilterListRounded } from "@mui/icons-material"
import { Box, Card, Checkbox, Divider, Drawer, FormControlLabel, Icon, Stack, useMediaQuery } from "@mui/material"
import theme from "assets/theme"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import MDTypography from "components/MDTypography"
import { useContext, useEffect, useRef, useState } from "react"
import { NewsSortBy, NewsSortByExtended } from "types/news/news-filter"
import { NewsContext } from "../../services/NewsContext"
import { capitalize } from "lodash"

function NewsFilterDrawer({ openDrawer, handleCloseDrawer }: { openDrawer: boolean; handleCloseDrawer: () => void }): JSX.Element {
  // UI
  const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"))
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  // Context
  const { newsFiltered, categories, sortBy, setSortBy, setSelectedCategories } = useContext(NewsContext)

  // State
  const drawerRef = useRef(null)
  const [selectedCheckbox, setSelectedCheckbox] = useState<boolean[]>([])

  useEffect(() => {
    setSelectedCategories(categories.filter((_, index) => selectedCheckbox[index]))
  }, [selectedCheckbox])

  const updateCheckboxValue = (index: number, newValue: boolean) => {
    setSelectedCheckbox(prevState => {
      // Create a copy of the state array
      const newArray = [...prevState]
      // Update the value at the specified index
      newArray[index] = newValue
      // Return the new array to update the state
      return newArray
    })
  }

  return (
    <Drawer
      anchor="right"
      ref={drawerRef}
      open={openDrawer}
      onClose={handleCloseDrawer}
      PaperProps={{
        sx: {
          height: "fit-content",
          maxHeight: "96%",
          width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%"
        }
      }}
    >
      <MDBox display="flex" alignItems="baseline" pt={4} pb={0.5} px={3}>
        <FilterListRounded
          sx={{
            transform: "translateY(4px)"
          }}
        />
        <MDBox ml={1}>
          <MDTypography variant="h5">Filters</MDTypography>
        </MDBox>
        <Box style={{ flex: 1 }} />
        <Icon
          sx={{
            cursor: "pointer",
            transform: "translateY(4px)"
          }}
          onClick={handleCloseDrawer}
        >
          close
        </Icon>
      </MDBox>
      <Divider style={{ opacity: 1, margin: "8px 0" }} />

      {/* Body */}
      <MDBox px={3} pt={1}>
        <MDTypography variant="h6" mb={1}>
          Order by
        </MDTypography>
        <Stack direction="row" spacing={1}>
          {[NewsSortBy.recency, NewsSortBy.contacts].map(filterType => {
            let isSelected = filterType === sortBy
            return (
              <Card
                style={{
                  flex: 1,
                  marginBottom: 8,
                  background: isSelected ? "#6495ed4f" : "white",
                  border: isSelected ? "2px solid cornflowerblue" : "2px solid lightgray",
                  cursor: "pointer"
                }}
                onClick={() => setSortBy(filterType)}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  style={{ marginTop: 12, marginBottom: 12, paddingLeft: 8, paddingRight: 12 }}
                >
                  {isSelected && <MDBox flex={2} />}
                  <MDTypography display="inline" variant="body2" fontWeight="bold" style={{ color: "cornflowerblue" }}>
                    {NewsSortByExtended.getName(filterType)}
                  </MDTypography>
                  {isSelected && <MDBox flex={1.75} />}
                  {isSelected && <Done fontSize="medium" style={{ color: "cornflowerblue", marginRight: 16 }} />}
                </Stack>
              </Card>
            )
          })}
        </Stack>
        <MDTypography variant="h6" mb={1}>
          Categories
        </MDTypography>
        {categories.map((category, index) => (
          <FormControlLabel
            control={<Checkbox checked={selectedCheckbox[index]} onChange={() => updateCheckboxValue(index, !selectedCheckbox[index])} />}
            label={capitalize(category)}
          />
        ))}
      </MDBox>

      {/* Footer */}
      <Box
        sx={{
          display: "flex",
          gap: 1,
          p: 1.5,
          pb: 2,
          borderTop: "1px solid",
          borderColor: "divider",
          justifyContent: "space-between"
        }}
      >
        <MDBox></MDBox>
        <MDButton variant="gradient" color="info" type="submit" onClick={handleCloseDrawer}>
          See {newsFiltered.length} news
        </MDButton>
      </Box>
    </Drawer>
  )
}

// Setting default values for the props of NewsFilterDrawer
NewsFilterDrawer.defaultProps = {
  handleCloseDrawer: null,
  contact: null
}

export default NewsFilterDrawer
