import { useDraggable, MouseSensor as LibMouseSensor, TouchSensor as LibTouchSensor } from "@dnd-kit/core"
import { CSS } from "@dnd-kit/utilities"
import { MouseEvent, TouchEvent } from "react"

// Block DnD event propagation if element have "data-no-dnd" attribute
const handler = ({ nativeEvent: event }: MouseEvent | TouchEvent) => {
  let cur = event.target as HTMLElement

  while (cur) {
    if (cur.dataset && cur.dataset.noDnd) {
      return false
    }
    cur = cur.parentElement as HTMLElement
  }

  return true
}

export class MouseSensor extends LibMouseSensor {
  static activators = [{ eventName: "onMouseDown", handler }] as (typeof LibMouseSensor)["activators"]
}

export class TouchSensor extends LibTouchSensor {
  static activators = [{ eventName: "onTouchStart", handler }] as (typeof LibTouchSensor)["activators"]
}

export function Draggable({ id, contact, children }: { id: string; contact: any; children: any }) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: id,
    data: contact
  })

  const style = {
    transform: CSS.Translate.toString(transform)
  }

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {children}
    </div>
  )
}
