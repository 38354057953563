import { SearchTerm } from "forge/knowledge/schemas/search-term"

export class News {
  originSearchTerm: string
  allTitleSearchTerms: SearchTerm[]
  allDescriptionSearchTerms: SearchTerm[]
  searchTermCategory?: string
  author?: string
  title: string
  description?: string
  url: string
  source?: string
  image?: string
  category?: string
  language: string
  country: string
  publishedAt: number
  sourceImage?: string
  affectedContacts?: string[]
  affectedContactsRecords: any[]
  query: string
  newsTrendingId: number
  searchTermScore: number
  affectedContactsCount: number
  urgentScore: number
  totalScore: number
  totalWeightedScore: number

  constructor({
    originSearchTerm,
    allTitleSearchTerms,
    allDescriptionSearchTerms,
    searchTermCategory,
    author,
    title,
    description,
    url,
    source,
    image,
    category,
    language,
    country,
    publishedAt,
    sourceImage,
    affectedContacts,
    affectedContactsRecords,
    query,
    newsTrendingId,
    searchTermScore,
    affectedContactsCount,
    urgentScore,
    totalScore,
    totalWeightedScore
  }: {
    originSearchTerm: string
    allTitleSearchTerms: SearchTerm[]
    allDescriptionSearchTerms: SearchTerm[]
    searchTermCategory?: string
    author?: string
    title: string
    description?: string
    url: string
    source?: string
    image?: string
    category?: string
    language: string
    country: string
    publishedAt: number
    sourceImage?: string
    affectedContacts?: string[]
    affectedContactsRecords: any[]
    query: string
    newsTrendingId: number
    searchTermScore: number
    affectedContactsCount: number
    urgentScore: number
    totalScore: number
    totalWeightedScore: number
  }) {
    this.originSearchTerm = originSearchTerm
    this.allTitleSearchTerms = allTitleSearchTerms
    this.allDescriptionSearchTerms = allDescriptionSearchTerms
    this.searchTermCategory = searchTermCategory
    this.author = author
    this.title = title
    this.description = description
    this.url = url
    this.source = source
    this.image = image
    this.category = category
    this.language = language
    this.country = country
    this.publishedAt = publishedAt
    this.sourceImage = sourceImage
    this.affectedContacts = affectedContacts
    this.affectedContactsRecords = affectedContactsRecords
    this.query = query
    this.newsTrendingId = newsTrendingId
    this.searchTermScore = searchTermScore
    this.affectedContactsCount = affectedContactsCount
    this.urgentScore = urgentScore
    this.totalScore = totalScore
    this.totalWeightedScore = totalWeightedScore
  }

  copyWith({
    originSearchTerm,
    allTitleSearchTerms,
    allDescriptionSearchTerms,
    searchTermCategory,
    author,
    title,
    description,
    url,
    source,
    image,
    category,
    language,
    country,
    publishedAt,
    sourceImage,
    affectedContacts,
    affectedContactsRecords,
    query,
    newsTrendingId,
    searchTermScore,
    affectedContactsCount,
    urgentScore,
    totalScore,
    totalWeightedScore
  }: Partial<News>): News {
    return new News({
      originSearchTerm: originSearchTerm ?? this.originSearchTerm,
      allTitleSearchTerms: allTitleSearchTerms ?? this.allTitleSearchTerms,
      allDescriptionSearchTerms: allDescriptionSearchTerms ?? this.allDescriptionSearchTerms,
      searchTermCategory: searchTermCategory ?? this.searchTermCategory,
      author: author ?? this.author,
      title: title ?? this.title,
      description: description ?? this.description,
      url: url ?? this.url,
      source: source ?? this.source,
      image: image ?? this.image,
      category: category ?? this.category,
      language: language ?? this.language,
      country: country ?? this.country,
      publishedAt: publishedAt ?? this.publishedAt,
      sourceImage: sourceImage ?? this.sourceImage,
      affectedContacts: affectedContacts ?? this.affectedContacts,
      affectedContactsRecords: affectedContactsRecords ?? this.affectedContactsRecords,
      query: query ?? this.query,
      newsTrendingId: newsTrendingId ?? this.newsTrendingId,
      searchTermScore: searchTermScore ?? this.searchTermScore,
      affectedContactsCount: affectedContactsCount ?? this.affectedContactsCount,
      urgentScore: urgentScore ?? this.urgentScore,
      totalScore: totalScore ?? this.totalScore,
      totalWeightedScore: totalWeightedScore ?? this.totalWeightedScore
    })
  }

  toMap(): Record<string, unknown> {
    return {
      searchTerm: this.originSearchTerm,
      allTitleSearchTerms: this.allTitleSearchTerms,
      allDescriptionSearchTerms: this.allDescriptionSearchTerms,
      searchTermCategory: this.searchTermCategory,
      author: this.author,
      title: this.title,
      description: this.description,
      url: this.url,
      source: this.source,
      image: this.image,
      category: this.category,
      language: this.language,
      country: this.country,
      publishedAt: this.publishedAt,
      sourceImage: this.sourceImage,
      affectedContacts: this.affectedContacts,
      affectedContactsRecords: this.affectedContactsRecords,
      query: this.query,
      newsTrendingId: this.newsTrendingId,
      searchTermScore: this.searchTermScore,
      affectedContactsCount: this.affectedContactsCount,
      urgentScore: this.urgentScore,
      totalScore: this.totalScore,
      totalWeightedScore: this.totalWeightedScore
    }
  }

  static fromMap(map: Record<string, any>): News {
    const seenSearchTerms: Set<string> = new Set()
    let allTitleSearchTerms: SearchTerm[] = []
    if (map.allTitleSearchTermsJson != null) {
      if (typeof map.allTitleSearchTermsJson === "string") {
        map.allTitleSearchTermsJson = JSON.parse(map.allTitleSearchTermsJson)
      }

      if (Array.isArray(map.allTitleSearchTermsJson)) {
        allTitleSearchTerms = (map.allTitleSearchTermsJson as any[])
          .map(e => SearchTerm.fromMap(e))
          // It can be null coming from the backend
          .filter(e => e.idbID != null && seenSearchTerms.add(e.searchTerm))
        allTitleSearchTerms.sort((a, b) => a.searchTerm.localeCompare(b.searchTerm))
      }
    }

    let allDescriptionSearchTerms: SearchTerm[] = []
    if (map.allDescriptionSearchTermsJson != null) {
      if (typeof map.allDescriptionSearchTermsJson === "string") {
        map.allDescriptionSearchTermsJson = JSON.parse(map.allDescriptionSearchTermsJson)
      }

      if (Array.isArray(map.allDescriptionSearchTermsJson)) {
        allDescriptionSearchTerms = (map.allDescriptionSearchTermsJson as any[])
          .map(e => SearchTerm.fromMap(e))
          // It can be null coming from the backend
          .filter(e => e.idbID != null && seenSearchTerms.add(e.searchTerm))
        allDescriptionSearchTerms.sort((a, b) => a.searchTerm.localeCompare(b.searchTerm))
      }
    }

    return new News({
      originSearchTerm: map.searchTerm ? String(map.searchTerm) : "",
      allTitleSearchTerms,
      allDescriptionSearchTerms,
      searchTermCategory: map.searchTermCategory ? String(map.searchTermCategory) : "",
      author: map.author ? String(map.author) : undefined,
      title: map.title ? String(map.title) : "",
      description: map.description ? String(map.description) : undefined,
      url: map.url ? String(map.url) : "",
      source: map.source ? String(map.source) : undefined,
      image: map.image ? String(map.image) : undefined,
      category: map.category ? String(map.category) : undefined,
      language: map.language ? String(map.language) : "",
      country: map.country ? String(map.country) : "",
      publishedAt: map.published_at ?? map.publishedAt ?? 0,
      sourceImage: map.sourceImage ? String(map.sourceImage) : undefined,
      affectedContacts: map.affectedContacts ? (Array.isArray(map.affectedContacts) ? (map.affectedContacts as string[]) : []) : [],
      affectedContactsRecords: [],
      query: map.query ? String(map.query) : map.originSearchTerm ? String(map.originSearchTerm) : "",
      newsTrendingId: map.newsTrendingID ?? map.newsTrendingId ?? 0,
      searchTermScore: map.searchTermScore ?? 0,
      affectedContactsCount: map.affectedContactsCount ?? 0,
      urgentScore: map.urgentScore ?? 0,
      totalScore: map.totalScore ?? 0,
      totalWeightedScore: map.totalWeightedScore ?? 0
    })
  }

  get allSearchTerms(): SearchTerm[] {
    return [...this.allTitleSearchTerms, ...this.allDescriptionSearchTerms]
  }
}
