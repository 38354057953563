import { Unsubscribe, onSnapshot, setDoc, updateDoc, doc } from "firebase/firestore"
import { User } from "firebase/auth"
import { UserProfileData } from "types/user/user-profile-data"
import { getLocalTimezone } from "types/timezones"
import dayjs from "dayjs"
import { UserSettings } from "types/user/user-settings"
import { firestoreDb } from "firebase.init"
import { ForgeEncryption } from "forge/core/services/encryption"

class AuthFirestoreService {
  private user: User
  private encryptionService: ForgeEncryption

  constructor(user: User, encryptionService: ForgeEncryption) {
    this.user = user
    this.encryptionService = encryptionService
  }

  createUserProfile = async (): Promise<boolean> => {
    const userRef = doc(firestoreDb, `users/${this.user.uid}`)

    // Get the current timezone offset in milliseconds
    const timeZoneOffsetInMilliseconds = dayjs().utcOffset() * 60 * 1000

    // Get the current timezone offset in hours
    const timeZoneOffsetInHours = dayjs().utcOffset() / 60

    await setDoc(userRef, {
      installer: "web",
      timezoneName: getLocalTimezone(),
      timezoneOffset: timeZoneOffsetInMilliseconds,
      timezoneOffsetInHours: timeZoneOffsetInHours,
      settings: {
        audience: "all",
        commitmentsPush: true,
        commitmentsReminderDays: [0],
        frequenceAfterEachEmail: true,
        frequenceBeforeEachEmail: true,
        frequenceDailyPostEmail: true,
        frequenceDailyPreEmail: true,
        frequenceAfterEachPush: true,
        frequenceBeforeEachPush: true,
        frequenceDailyPostPush: true,
        frequenceDailyPrePush: true,
        milestonesPush: true,
        milestonesReminderDays: [0],
        hasCalendarAccess: false,
        hasContactsAccess: false,
        ignoredAttendees: []
      },
      onboardingData: {
        currentStep: null,
        nextStep: null,
        finalized: false,
        setInitialData: true,
        settingInitialContacts: false,
        settingInitialEvents: false
      },
      pipelineUserConfig: {
        isEnabled: false,
        integration: null
      },
      encryptionPasswordSet: false
    })

    return true
  }

  getUserProfileData = (onEvent: (userProfileData: UserProfileData, userSettings: UserSettings) => void): Unsubscribe => {
    const userRef = doc(firestoreDb, `users/${this.user.uid}`)

    return onSnapshot(userRef, async snapshot => {
      let userProfileData = await UserProfileData.fromMap(snapshot.data(), this.user, this.encryptionService)

      let userSettings = UserSettings.fromMap(snapshot.data()?.settings)
      onEvent(userProfileData, userSettings)
    })
  }

  updateEncryptionPasswordSet = async (): Promise<void> => {
    const userRef = doc(firestoreDb, `users/${this.user.uid}`)

    await updateDoc(userRef, {
      encryptionPasswordSet: true,
      "onboardingData.setInitialData": true,
      "onboardingData.finalized": true
    })
  }
}

export default AuthFirestoreService
