import * as React from "react"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
// import parse from 'autosuggest-highlight/parse';
import { debounce } from "@mui/material/utils"
import { createUseStyles } from "react-jss"

const GOOGLE_MAPS_API_KEY = "AIzaSyCcfgP7lkagiUTfBPkKuWskBX1zG2I6bsQ"

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return
  }

  const script = document.createElement("script")
  script.setAttribute("async", "")
  script.setAttribute("id", id)
  script.src = src
  position.appendChild(script)
}

const autocompleteService: any = { current: null }

interface MainTextMatchedSubstrings {
  offset: number
  length: number
}
interface StructuredFormatting {
  main_text: string
  secondary_text: string
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[]
}
export interface PlaceType {
  description: string
  structured_formatting: StructuredFormatting
}

const styles = createUseStyles({
  root: {
    "& .MuiFormLabel-root": {
      paddingTop: "4px"
    }
  }
})

export default function PlacesAutocomplete({
  onSelection,
  initialValue,
  label = "Add a location",
  enableLabel = true
}: {
  onSelection: (place: PlaceType) => void
  initialValue?: PlaceType
  label?: string
  enableLabel?: boolean
}) {
  const [value, setValue] = React.useState<PlaceType | null>(initialValue)
  const [inputValue, setInputValue] = React.useState("")
  const [options, setOptions] = React.useState<readonly PlaceType[]>([])
  const loaded = React.useRef(false)
  const classes = styles()

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`, document.querySelector("head"), "google-maps")
    }

    loaded.current = true
  }

  const fetch = React.useMemo(
    () =>
      debounce((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        ;(autocompleteService.current as any).getPlacePredictions(request, callback)
      }, 400),
    []
  )

  React.useEffect(() => {
    let active = true

    if (!autocompleteService.current && (window as any).google?.maps) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === "") {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = []

        const inputOption = {
          description: inputValue,
          structured_formatting: {
            main_text: inputValue,
            secondary_text: ""
          }
        }

        if (value) {
          newOptions = [value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions([inputOption, ...newOptions])
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  return (
    <Autocomplete
      id="places-select"
      sx={{ width: 300 }}
      style={{ flex: 1 }}
      getOptionLabel={option => (typeof option === "string" ? option : option.description)}
      filterOptions={x => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={(event: any, newValue: PlaceType | null) => {
        if (newValue) {
          setOptions(newValue ? [newValue, ...options] : options)
          setValue(newValue)
          onSelection(newValue)
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={params => <TextField {...params} className={classes.root} label={enableLabel && label} placeholder={label} fullWidth />}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings || []

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid item sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}>
                <Typography variant="body2" color="text.primary">
                  {option.structured_formatting.main_text}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}
