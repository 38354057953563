// @mui material components

import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"
import { Avatar } from "@mui/material"
import { VolunteerActivismOutlined } from "@mui/icons-material"
import { getKnowledgeTypeIcon } from "forge/knowledge/schemas/knowledge-type"
import { parseKnowledge } from "forge/core/knowledgeParsing"
import { timeFrame } from "types/contact/linkedin-data"

function VolunteerWorkTile({ volunteerWork, knowledge, onClick }: { volunteerWork: any; knowledge: any; onClick?: () => void }): JSX.Element {
  return (
    <MDBox display="flex" alignItems="start" lineHeight={1} mb={1.5} style={{ cursor: "pointer" }} onClick={onClick}>
      <Avatar sx={{ bgcolor: "lightgray", width: 44, height: 44, marginTop: "4px" }} variant="rounded" src={volunteerWork?.logo_url}>
        <VolunteerActivismOutlined style={{ color: "white" }} />
      </Avatar>
      <MDBox ml={1}>
        {parseKnowledge(knowledge, volunteerWork.company, "bold")}
        <MDTypography variant="body2" color="text" sx={{ fontSize: "0.9rem", lineHeight: 1.4 }}>
          {volunteerWork.title}
        </MDTypography>
        <MDTypography variant="body2" sx={{ fontSize: "0.8rem", lineHeight: 1.4, color: "grey" }}>
          {timeFrame(volunteerWork)}
        </MDTypography>
      </MDBox>
      <MDBox flex={1} />
      {getKnowledgeTypeIcon(knowledge?.type)}
    </MDBox>
  )
}

// Setting default values for the props of NewsCard
VolunteerWorkTile.defaultProps = {
  volunteerWork: null
}

export default VolunteerWorkTile
