import { Card, Divider, IconButton, Stack } from "@mui/material"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import { Add, ArrowForwardIos, EmojiObjectsOutlined } from "@mui/icons-material"
import { useContext } from "react"
import { NewsContext } from "forge/outreach/news/services/NewsContext"
import NewsHorizontalCard from "forge/outreach/news/components/NewsHorizontalCard"
import { CARDS_TOOLTIPS } from "../../utils/contacts-constants"
import CardTitle from "../CardTitle"

function ContactNewsCard({ handleOpenDrawer }: { handleOpenDrawer: () => void }) {
  const { news } = useContext(NewsContext)

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox m={3}>
        <CardTitle title="Icebreakers" tooltipInfo={CARDS_TOOLTIPS.ICEBREAKERS} />
        <Divider style={{ opacity: 1 }} />
        {news.length > 0 ? (
          news.map((article, index) => (
            <MDBox mb={2}>
              <NewsHorizontalCard key={index} article={article} />
            </MDBox>
          ))
        ) : (
          <Stack mt={5} mb={3} direction="row" alignSelf={"center"} justifyContent={"center"} alignItems="center" spacing={0.5}>
            <MDTypography
              variant="body2"
              align="center"
              style={{ color: "darkgray" }}
            >{`No news to share right now, add a new topic to update results`}</MDTypography>
            <ArrowForwardIos style={{ color: "darkgray", height: "0.75em" }} />
            <MDBox
              width="3rem"
              height="3rem"
              borderRadius="md"
              display="flex"
              justifyContent="center"
              alignItems="center"
              shadow="md"
              style={{ cursor: "pointer", background: "cornflowerblue" }}
              onClick={handleOpenDrawer}
            >
              <IconButton aria-label="add-knowledge" style={{ padding: 0 }} onClick={handleOpenDrawer}>
                <EmojiObjectsOutlined style={{ color: "white" }} />
                <Add style={{ color: "white", width: "0.5em", position: "absolute", top: "-10px", right: "-4px" }} />
              </IconButton>
            </MDBox>
          </Stack>
        )}
      </MDBox>
    </Card>
  )
}

export default ContactNewsCard
