import { EmailOutlined, Help, LinkedIn, LocationOnOutlined, Notes, Person, PhoneIphone, SummarizeOutlined } from "@mui/icons-material"
import { Knowledge } from "./knowledge"

export enum KnowledgeType {
  user = "KnowledgeType.user",
  suggestedQuestions = "KnowledgeType.suggestedQuestions",
  notes = "KnowledgeType.notes",
  summary = "KnowledgeType.summary",
  beyondSummary = "KnowledgeType.beyondSummary",
  linkedinEducation = "KnowledgeType.linkedinEducation",
  linkedinExperience = "KnowledgeType.linkedinExperience",
  linkedinVolunteerWork = "KnowledgeType.linkedinVolunteerWork",
  linkedinLocation = "KnowledgeType.linkedinLocation",
  companyFromDomain = "KnowledgeType.companyFromDomain",
  locationFromPhone = "KnowledgeType.locationFromPhone",
  locationFromAddress = "KnowledgeType.locationFromAddress"
}

// Reverse mapping
type KnowledgeTypeStringMap = {
  [key in KnowledgeType]: string
}

export const knowledgeTypeStringMap: KnowledgeTypeStringMap = {
  [KnowledgeType.user]: "KnowledgeType.user",
  [KnowledgeType.suggestedQuestions]: "KnowledgeType.suggestedQuestions",
  [KnowledgeType.notes]: "KnowledgeType.notes",
  [KnowledgeType.summary]: "KnowledgeType.summary",
  [KnowledgeType.beyondSummary]: "KnowledgeType.beyondSummary",
  [KnowledgeType.linkedinEducation]: "KnowledgeType.linkedinEducation",
  [KnowledgeType.linkedinExperience]: "KnowledgeType.linkedinExperience",
  [KnowledgeType.linkedinVolunteerWork]: "KnowledgeType.linkedinVolunteerWork",
  [KnowledgeType.linkedinLocation]: "KnowledgeType.linkedinLocation",
  [KnowledgeType.companyFromDomain]: "KnowledgeType.companyFromDomain",
  [KnowledgeType.locationFromPhone]: "KnowledgeType.locationFromPhone",
  [KnowledgeType.locationFromAddress]: "KnowledgeType.locationFromAddress"
}

const myInsights: KnowledgeType[] = [KnowledgeType.user, KnowledgeType.suggestedQuestions, KnowledgeType.notes]

const forgeInsights: KnowledgeType[] = [
  KnowledgeType.summary,
  KnowledgeType.beyondSummary,
  KnowledgeType.linkedinEducation,
  KnowledgeType.linkedinExperience,
  KnowledgeType.linkedinVolunteerWork,
  KnowledgeType.linkedinLocation,
  KnowledgeType.companyFromDomain,
  KnowledgeType.locationFromPhone,
  KnowledgeType.locationFromAddress
]

const locationInsights: KnowledgeType[] = [KnowledgeType.locationFromAddress, KnowledgeType.locationFromPhone, KnowledgeType.linkedinLocation]

export function getUserKnowledge(knowledgeList: Knowledge[]): any[] {
  return knowledgeList.filter(knowledge => knowledge && myInsights.includes(knowledge.type))
}

// Define forgeKnowledge function
export function getForgeKnowledge(knowledgeList: Knowledge[]): any[] {
  let filteredKnowledgeList = knowledgeList.filter(knowledge => knowledge && forgeInsights.includes(knowledge.type))

  const mostRecentItemsMap: { [key in KnowledgeType]?: Knowledge } = {}

  for (const knowledge of filteredKnowledgeList) {
    const type: KnowledgeType = knowledge.type

    if (
      !mostRecentItemsMap[type] ||
      (knowledge.createdAt && (mostRecentItemsMap[type]!.createdAt ?? new Date(0)).getTime() < knowledge.createdAt.getTime())
    ) {
      mostRecentItemsMap[type] = knowledge
    }
  }

  const mostRecentItems = Object.values(mostRecentItemsMap)
  return mostRecentItems
}

export function getLocationKnowledge(knowledgeList: any[]): any[] {
  return knowledgeList.filter(knowledge => knowledge && locationInsights.includes(knowledge.type) && knowledge.visible)
}

export function getKnowledgeByType(knowledgeList: any[], type: KnowledgeType, single: boolean = false): any[] | any {
  if (single) {
    return knowledgeList.find(knowledge => knowledge && knowledge.type == type && knowledge.visible)
  }

  return removeDuplicates(knowledgeList.filter(knowledge => knowledge && knowledge.type == type && knowledge.visible))
}

function removeDuplicates(arr: any[]): any[] {
  if (!arr) return arr

  const uniqueObjects = new Set()
  return arr.filter(item => {
    const serialized = JSON.stringify(item)
    if (uniqueObjects.has(serialized)) {
      return false
    } else {
      uniqueObjects.add(serialized)
      return true
    }
  })
}

export function getKnowledgeTypeIcon(type: KnowledgeType): any[] | any {
  switch (type) {
    case KnowledgeType.user:
      return <Person color="disabled" />
    case KnowledgeType.suggestedQuestions:
      return <Help color="disabled" />
    case KnowledgeType.notes:
      return <Notes color="disabled" />
    case KnowledgeType.summary:
      return <SummarizeOutlined color="disabled" />
    case KnowledgeType.beyondSummary:
      return <SummarizeOutlined color="disabled" />
    case KnowledgeType.linkedinEducation:
      return <LinkedIn color="disabled" />
    case KnowledgeType.linkedinExperience:
      return <LinkedIn color="disabled" />
    case KnowledgeType.linkedinVolunteerWork:
      return <LinkedIn color="disabled" />
    case KnowledgeType.linkedinLocation:
      return <LinkedIn color="disabled" />
    case KnowledgeType.companyFromDomain:
      return <EmailOutlined color="disabled" />
    case KnowledgeType.locationFromPhone:
      return <PhoneIphone color="disabled" />
    case KnowledgeType.locationFromAddress:
      return <LocationOnOutlined color="disabled" />

    default:
      return <></>
  }
}
