export class SearchTerm {
  idbID: number
  entity: string
  searchTerm: string
  category: string
  word?: string
  priority: number

  constructor({
    idbID,
    entity,
    searchTerm,
    category,
    word,
    priority
  }: {
    idbID: number
    entity: string
    searchTerm: string
    category: string
    word?: string
    priority: number
  }) {
    this.idbID = idbID
    this.entity = entity
    this.searchTerm = searchTerm
    this.category = category
    this.word = word
    this.priority = priority
  }

  toMap(): { [key: string]: any } {
    return {
      idbID: this.idbID,
      entity: this.entity,
      searchTerm: this.searchTerm,
      category: this.category,
      word: this.word,
      priority: this.priority
    }
  }

  static fromMap(map: { [key: string]: any }): SearchTerm {
    return new SearchTerm({
      idbID: map.idbID ?? -1,
      entity: map.entity,
      searchTerm: map.searchTerm ?? map.entity ?? "",
      category: map.category ?? "",
      word: map.word,
      priority: map.priority ?? 1
    })
  }
}
