import { createContext } from "react"
import { Buffer } from "buffer"

interface Algorithm {
  name: string
  length: number
  iv: Uint8Array
}

interface SecureStorageContextType {
  containsKey: (key: string) => boolean
  deleteKey: (key: string) => void
  deleteAll: () => void
  read: (key: string) => Promise<string | undefined>
  write: (key: string, value: string) => Promise<void>
}

export const SecureStorageContext = createContext<SecureStorageContextType>({
  containsKey: () => false,
  deleteKey: () => {},
  deleteAll: () => {},
  read: async () => "",
  write: async () => {}
})

export const SecureStorageContextProvider = ({ contact, children }: { contact?: any; children: any }) => {
  let _publicKey = "ForgeSecureStorage"

  const containsKey = (key: string): boolean => {
    let result = localStorage.getItem(`${_publicKey}.${key}`)
    return result !== null
  }

  const deleteKey = (key: string): void => {
    return localStorage.removeItem(`${_publicKey}.${key}`)
  }

  const deleteAll = (): void => {
    return localStorage.clear()
  }

  const read = async (key: string): Promise<string | undefined> => {
    let result = localStorage.getItem(`${_publicKey}.${key}`)

    return await _decryptValue(result)
  }

  const write = async (key: string, value: string): Promise<void> => {
    const iv = crypto.getRandomValues(new Uint8Array(12))
    const algorithm = getAlgorithm(iv)
    const encryptionKey = await getEncryptionKey(algorithm)

    const encryptedContent = await crypto.subtle.encrypt(algorithm, encryptionKey, Buffer.from(value, "utf-8"))

    const encoded = base64Encode(iv) + "." + base64Encode(new Uint8Array(encryptedContent))

    localStorage.setItem(`${_publicKey}.${key}`, encoded)
    return
  }

  const getAlgorithm = (iv: Uint8Array): Algorithm => {
    return { name: "AES-GCM", length: 256, iv: iv }
  }

  const getEncryptionKey = async (algorithm: Algorithm): Promise<CryptoKey> => {
    let encryptionKey: CryptoKey
    const key = _publicKey

    if (localStorage.getItem(key)) {
      const jwk = Buffer.from(localStorage.getItem(key)!, "base64")

      encryptionKey = await crypto.subtle.importKey("raw", jwk, algorithm, false, ["encrypt", "decrypt"])
    } else {
      encryptionKey = await crypto.subtle.generateKey(algorithm, true, ["encrypt", "decrypt"])

      const exportedKey = await crypto.subtle.exportKey("raw", encryptionKey)
      localStorage.setItem(key, Buffer.from(exportedKey).toString("base64"))
    }

    return encryptionKey
  }

  const _decryptValue = async (cypherText?: string) => {
    if (!cypherText) {
      return null
    }

    const parts = cypherText.split(".")

    const iv = Buffer.from(parts[0], "base64")
    const algorithm = getAlgorithm(iv)

    const decryptionKey = await getEncryptionKey(algorithm)

    const value = Buffer.from(parts[1], "base64")

    const decryptedContent = await crypto.subtle.decrypt(algorithm, decryptionKey, value)

    const plainText = new TextDecoder().decode(decryptedContent)

    return plainText
  }

  const base64Encode = (buffer: Uint8Array): string => {
    // Convert the Uint8Array buffer to a Buffer (Node.js)
    const nodeBuffer = Buffer.from(buffer)

    // Encode the Buffer to base64
    const base64Encoded = nodeBuffer.toString("base64")

    return base64Encoded
  }

  return <SecureStorageContext.Provider value={{ containsKey, deleteKey, deleteAll, read, write }}>{children}</SecureStorageContext.Provider>
}
