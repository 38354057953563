// @material-ui core components
import { SyncAlt } from "@mui/icons-material"
import { Avatar, Box, LinearProgress, Stack } from "@mui/material"
import Card from "@mui/material/Card"

// Material Dashboard 2 PRO React TS components
import MDTypography from "components/MDTypography"

// Settings page components
import { useContext } from "react"
import { ContactsContext } from "context"
import { OrganizationContext } from "forge/organization/services/OrganizationContext"

function PendingMigrationCard(): JSX.Element {
  // Context
  const { organization } = useContext(OrganizationContext)
  const { migrationProgress } = useContext(ContactsContext)

  return (
    <Card
      sx={{ overflow: "visible" }}
      style={{
        alignItems: "center",
        justifyContent: "center",
        padding: "64px",
        minHeight: "425px",
        maxHeight: "425px",
        minWidth: "475px",
        maxWidth: "650px"
      }}
    >
      <Stack direction="column" spacing={4} alignItems="center" alignContent="center" justifyContent="center">
        <Avatar variant="rounded" style={{ background: "black", height: "80px", width: "80px" }}>
          <SyncAlt style={{ height: "48px", width: "48px" }} />
        </Avatar>
        <MDTypography variant="h5" textAlign="center">
          {organization?.name ?? organization?.legalName ?? organization?.domain}'s Organization Data Migration
        </MDTypography>
        <Box sx={{ width: "100%" }}>
          <LinearProgress
            variant="determinate"
            value={migrationProgress * 100}
            sx={{
              "& .MuiLinearProgress-bar": {
                width: `${migrationProgress * 100}% !important`
              }
            }}
          />
        </Box>
        <MDTypography variant="body2" textAlign="center">
          You've been removed or the organization no longer exists.
          <br />
          The data from the organization is being migrated to your account.
          <br />
          Keep this window open to finish the migration.
        </MDTypography>
      </Stack>
    </Card>
  )
}

export default PendingMigrationCard
