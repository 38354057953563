import { User } from "firebase/auth"
import { ForgeEncryption } from "forge/core/services/encryption"
import HttpService from "forge/core/services/http.service"
import { UserProfileData } from "types/user/user-profile-data"

class HomeApi {
  private user: User
  private userProfileData: UserProfileData
  private forgeEncryption: ForgeEncryption

  constructor(user: User, userProfileData: UserProfileData, forgeEncryption: ForgeEncryption) {
    this.user = user
    this.userProfileData = userProfileData
    this.forgeEncryption = forgeEncryption
  }

  createContactFromAlert = async (alert: any): Promise<any> => {
    const endpoint = `/alerts/${alert.ref?.id}/contact`
    let result = await HttpService.post(endpoint, {})

    return result ?? ""
  }
}

export default HomeApi
