import appleLogo from "assets/forge/svgs/apple.svg"
import googleCalendarLogo from "assets/forge/svgs/google-calendar.svg"
import { OutlookRecurrence } from "./outlook-recurrence"
import outlookLogo from "assets/forge/svgs/microsoft-outlook.svg"
import { VideoConferencingPlatform } from "./video-conferencing-platform"
import { blue, brown, cyan, green, orange, pink, purple, red, teal, yellow } from "@mui/material/colors"
import { rgb } from "chroma-js"
import { removeUndefinedValues } from "forge/core/utilities"

const calendarColors: { [key: string]: string } = {
  Red: red[500],
  Orange: orange[500],
  Yellow: yellow[500],
  "Light Green": green[300],
  Green: green[500],
  "Light Blue": blue[300],
  Blue: blue[500],
  Purple: purple[500],
  Brown: brown[500],
  Pink: pink[500],
  Teal: teal[500],
  Cyan: cyan[500]
}

export default calendarColors

export const intToColor = (value: number): string => {
  const alpha = (value >> 24) & 0xff
  const red = (value >> 16) & 0xff
  const green = (value >> 8) & 0xff
  const blue = value & 0xff

  return rgb(red, green, blue, alpha).hex()
}

export const hexToColorInt = (hex: string): number => {
  // Remove '#' if present and convert to lowercase
  hex = hex.replace(/^#/, "").toLowerCase()

  if (hex.length === 6) {
    hex = hex + "FF"
  }

  // Parse hexadecimal components to decimal
  let red = parseInt(hex.slice(0, 2), 16)
  let green = parseInt(hex.slice(2, 4), 16)
  let blue = parseInt(hex.slice(4, 6), 16)
  let alpha = parseInt(hex.slice(6, 8), 16)

  // Calculate alpha, red, green, and blue components
  alpha = alpha & 0xff // Ensure alpha is between 0 and 255
  red = red & 0xff // Ensure red is between 0 and 255
  green = green & 0xff // Ensure green is between 0 and 255
  blue = blue & 0xff // Ensure blue is between 0 and 255

  console.log(alpha)
  console.log(red)
  console.log(green)
  console.log(blue)

  // Combine components into a single integer color value
  const color = ((alpha << 24) | (red << 16) | (green << 8) | (blue << 0)) & 0xffffffff

  return color
}

export enum CalendarType {
  local = "CalendarType.local",
  google = "CalendarType.google",
  outlook = "CalendarType.outlook"
}

export const CalendarTypeExtended = {
  ...CalendarType,
  getName(type: CalendarType): any {
    switch (type) {
      case CalendarType.local:
        return "iOS Calendar"
      case CalendarType.google:
        return "Google Calendar"
      case CalendarType.outlook:
        return "Outlook"
      default:
        return ""
    }
  },
  getIcon(type: CalendarType): any {
    switch (type) {
      case CalendarType.local:
        return appleLogo
      case CalendarType.google:
        return googleCalendarLogo
      case CalendarType.outlook:
        return outlookLogo
      default:
        return ""
    }
  },
  getRecurrenceRule(
    type: CalendarType,
    startDate?: Date,
    endDate?: Date | null | undefined,
    recurrence?: string,
    onADate?: Date,
    xOccurrences?: number
  ): any {
    if (!recurrence) return null

    switch (type) {
      case CalendarType.google:
        let googleRule: string | null = null

        switch (recurrence) {
          case "daily":
            googleRule = "RRULE:FREQ=DAILY;"
            break
          case "weekly":
            googleRule = "RRULE:FREQ=WEEKLY;"
            break
          case "monthly":
            googleRule = "RRULE:FREQ=MONTHLY;"
            break
          case "yearly":
            googleRule = "RRULE:FREQ=YEARLY;"
            break
          default:
            return null
        }

        if (onADate) {
          googleRule += `UNTIL=${onADate.toISOString().slice(0, 10).replace(/-/g, "")};`
        } else if (xOccurrences) {
          googleRule += `COUNT=${xOccurrences};`
        }
        return [googleRule]
      case CalendarType.outlook:
        let outlookRule: OutlookRecurrence = new OutlookRecurrence({ pattern: {}, range: {} })

        switch (recurrence) {
          case "daily":
            outlookRule.pattern = {
              type: "daily",
              interval: 1
            }
            break
          case "weekly":
            outlookRule.pattern = {
              type: "weekly",
              interval: 1,
              daysOfWeek: [startDate.toLocaleDateString("en-US", { weekday: "long" })],
              firstDayOfWeek: "monday"
            }
            break
          case "monthly":
            outlookRule.pattern = {
              type: "absoluteMonthly",
              interval: 1,
              dayOfMonth: startDate.getDate()
            }
            break
          case "yearly":
            outlookRule.pattern = {
              type: "absoluteYearly",
              interval: 1,
              dayOfMonth: startDate.getDate(),
              month: startDate.getMonth() + 1
            }
            break
          default:
            return null
        }

        const formattedStartDate = startDate.toISOString().slice(0, 10)
        if (onADate !== null && onADate !== undefined) {
          const formattedEndDate = (endDate ?? startDate).toISOString().slice(0, 10)
          outlookRule.range = {
            type: "endDate",
            startDate: formattedStartDate,
            endDate: formattedEndDate
          }
        } else if (xOccurrences !== null && xOccurrences !== undefined) {
          outlookRule.range = {
            type: "numbered",
            startDate: formattedStartDate,
            numberOfOccurrences: xOccurrences
          }
        } else {
          outlookRule.range = {
            type: "noEnd",
            startDate: formattedStartDate
          }
        }

        return outlookRule
      default:
        return null
    }
  }
}

enum GoogleCalendarAccessRole {
  none = "GoogleCalendarAccessRole.none",
  freeBusyReader = "GoogleCalendarAccessRole.freeBusyReader",
  reader = "GoogleCalendarAccessRole.reader",
  writer = "GoogleCalendarAccessRole.writer",
  owner = "GoogleCalendarAccessRole.owner"
}

export class ForgeCalendar {
  id?: string
  version: number
  account?: string
  name?: string
  isReadOnly?: boolean
  isDefault?: boolean
  color?: number
  accountName?: string
  accountType?: string
  active: boolean
  type: CalendarType
  lastUsed: boolean
  googleAccessRole?: GoogleCalendarAccessRole

  constructor({
    id,
    version = 0,
    account = "",
    name,
    isReadOnly,
    isDefault,
    color,
    accountName,
    accountType,
    active,
    type,
    lastUsed = false,
    googleAccessRole
  }: {
    id?: string
    version?: number
    account?: string
    name?: string
    isReadOnly?: boolean
    isDefault?: boolean
    color?: number
    accountName?: string
    accountType?: string
    active: boolean
    type: CalendarType
    lastUsed?: boolean
    googleAccessRole?: GoogleCalendarAccessRole
  }) {
    this.id = id
    this.version = version
    this.account = account
    this.name = name
    this.isReadOnly = isReadOnly
    this.isDefault = isDefault
    this.color = color
    this.accountName = accountName
    this.accountType = accountType
    this.active = active
    this.type = type
    this.lastUsed = lastUsed
    this.googleAccessRole = googleAccessRole
  }

  copyWith({
    id,
    version,
    account,
    name,
    isReadOnly,
    isDefault,
    color,
    accountName,
    accountType,
    active,
    type,
    lastUsed,
    googleAccessRole
  }: Partial<ForgeCalendar> = {}): ForgeCalendar {
    return new ForgeCalendar({
      id: id ?? this.id,
      version: version ?? this.version,
      account: account ?? this.account,
      name: name ?? this.name,
      isReadOnly: isReadOnly ?? this.isReadOnly,
      isDefault: isDefault ?? this.isDefault,
      color: color ?? this.color,
      accountName: accountName ?? this.accountName,
      accountType: accountType ?? this.accountType,
      active: active ?? this.active,
      type: type ?? this.type,
      lastUsed: lastUsed ?? this.lastUsed,
      googleAccessRole: googleAccessRole ?? this.googleAccessRole
    })
  }

  toMap(): { [key: string]: any } {
    return removeUndefinedValues({
      id: this.id,
      version: this.version,
      account: this.account,
      name: this.name,
      isReadOnly: this.isReadOnly,
      isDefault: this.isDefault,
      color: this.color,
      accountName: this.accountName,
      accountType: this.accountType,
      active: this.active,
      type: this.type.toString(),
      lastUsed: this.lastUsed,
      googleAccessRole: this.googleAccessRole?.toString()
    })
  }

  static fromMap(map: { [key: string]: any }): ForgeCalendar {
    return new ForgeCalendar({
      id: map["id"],
      version: map["version"] ?? 0,
      account: map["account"],
      name: map["name"],
      isReadOnly: map["isReadOnly"],
      isDefault: map["isDefault"],
      color: map["color"],
      accountName: map["accountName"],
      accountType: map["accountType"],
      active: map["active"] ?? false,
      type:
        map["type"] === "CalendarType.local"
          ? CalendarType.local
          : map["type"] === "CalendarType.google"
          ? CalendarType.google
          : map["type"] === "CalendarType.outlook"
          ? CalendarType.outlook
          : CalendarType.local,
      lastUsed: map["lastUsed"] ?? false,
      googleAccessRole: map["googleAccessRole"]
    })
  }

  availablePlatforms(): VideoConferencingPlatform[] {
    switch (this.type) {
      case "CalendarType.local":
        return [VideoConferencingPlatform.zoom]
      case "CalendarType.outlook":
        return [VideoConferencingPlatform.teams, VideoConferencingPlatform.zoom]
      case "CalendarType.google":
        return [VideoConferencingPlatform.meets, VideoConferencingPlatform.zoom]

      default:
        return []
    }
  }

  get canWeWriteToIt(): boolean {
    console.log(this)
    return (
      (this.type == CalendarType.google &&
        this.googleAccessRole != null &&
        (this.googleAccessRole === GoogleCalendarAccessRole.writer || this.googleAccessRole === GoogleCalendarAccessRole.owner)) ||
      (this.type == CalendarType.local && !(this.isReadOnly ?? false)) ||
      (this.type == CalendarType.outlook && !(this.isReadOnly ?? false))
    )
  }
}
