// @mui material components
import Card from "@mui/material/Card"

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"

// Setting pages components
import SettingTile from "../SettingTile/SettingTile"
import { useContext } from "react"
import { AuthContext } from "context"
import { SettingsContext } from "forge/settings/services/SettingsContext"

function SettingsAI(): JSX.Element {
  // Context
  const { getCurrentUser } = useContext(AuthContext)
  const { userSettings } = getCurrentUser()
  const { updateUserSettings } = useContext(SettingsContext)

  return (
    <Card id="ai-settings">
      <MDBox p={3} lineHeight={1}>
        <MDBox mb={1}>
          <MDTypography variant="h5">AI Settings</MDTypography>
        </MDBox>
        <MDTypography variant="body2" color="text">
          {/* How frequently do you want reminders? */}
        </MDTypography>
      </MDBox>
      <MDBox pb={3} px={3}>
        <SettingTile
          text="Enable AI Career Summaries"
          isEnabled={userSettings?.enableAiCareerSummaries}
          onEnabled={enabled =>
            updateUserSettings(
              userSettings?.copyWith({
                enableAiCareerSummaries: enabled
              })
            )
          }
        />
      </MDBox>
    </Card>
  )
}

export default SettingsAI
