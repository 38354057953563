import * as contentful from "contentful"
import { get } from "lodash"

export const CONTENT_TYPE = "pageConfiguration"

const client = contentful.createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID ?? "",
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN ?? "",
  host: process.env.REACT_APP_CONTENTFUL_END_POINT ?? "preview.contentful.com",
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT ?? "master"
})

export const getEntries = async (options = {}) => {
  return await client.getEntries(options).then(entries => {
    if (entries.items[0]) return cleanContentfulFields(entries.items[0])
    return null
  })
}

const cleanContentfulFields = (contentfulObject: object): object => {
  const item: any = contentfulObject
  if (Array.isArray(item)) {
    return item
      .map(ele => {
        return cleanContentfulFields(ele)
      })
      .filter(ele => ele !== null)
  }

  const contentTypeId = get(item, ["sys", "contentType", "id"])
  const id = get(item, ["sys", "id"])

  if (item.sys) delete item.sys
  if (item.metadata) delete item.metadata

  if (item.fields) {
    const newItem = { contentTypeId, id }
    if (item.fields.objectReference) delete item.fields.objectReference
    Object.keys(item.fields).forEach(key => {
      const aux = cleanContentfulFields(item.fields[key])
      // @ts-expect-error No types.
      if (aux) newItem[key] = aux
    })
    return newItem
  }

  return item
}
