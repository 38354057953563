// @material-ui core components
import { Apartment } from "@mui/icons-material"
import { Avatar, Stack } from "@mui/material"
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import MDTypography from "components/MDTypography"

// Settings page components
import FormField from "layouts/pages/account/components/FormField"
import { useState } from "react"
import CreateOrganizationDialog from "../CreateOrganizationDialog"

function CreateOrganizationCard(): JSX.Element {
  // State
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <Card
      sx={{ overflow: "visible" }}
      style={{
        alignItems: "center",
        justifyContent: "center",
        margin: "0 auto",
        padding: "64px",
        minHeight: "425px",
        maxHeight: "425px",
        minWidth: "475px",
        maxWidth: "475px"
      }}
    >
      <CreateOrganizationDialog open={open} handleClose={handleClose} />
      <Stack direction="column" spacing={8} alignItems="center" alignContent="center" justifyContent="center">
        <Avatar variant="rounded" style={{ background: "black", height: "80px", width: "80px" }}>
          <Apartment style={{ height: "48px", width: "48px" }} />
        </Avatar>

        <MDTypography variant="h5">Add your organization</MDTypography>

        <MDButton variant="gradient" color="dark" size="small" type="submit" style={{ textTransform: "none" }} onClick={() => handleOpen()}>
          Add Organization
        </MDButton>
      </Stack>
    </Card>
  )
}

export default CreateOrganizationCard
