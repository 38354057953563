import { useContext, useEffect, useState } from "react"

// @mui material components
import { Tab, Tabs, useMediaQuery } from "@mui/material"
import { CalendarIcon } from "@mui/x-date-pickers"
import { Flag, Handshake, Notifications } from "@mui/icons-material"

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import Footer from "examples/Footer"

// Data
import CalendarView from "./components/CalendarView"
import CommitmentsView from "./components/CommitmentsView"
import MilestonesView from "./components/MilestonesView"

// Firebase
import { CustomTabPanel, a11yProps } from "forge/core/components/TabPanel"
import AlertsView from "./components/AlertsView"
import { AlertsContext } from "./services/AlertsContext"
import theme from "assets/theme"
import MDBadge from "components/MDBadge"
import { startOfDay } from "forge/core/utilities"

function Home() {
  const bigScreen = useMediaQuery(theme.breakpoints.up("sm"))
  const [alertBagde, setAlertBadge] = useState<number>(0)
  const { alerts, getAlertsForDay } = useContext(AlertsContext)
  const alertFlag = localStorage.getItem("alertFlag")

  useEffect(() => {
    let alertSeenFlag
    if (alertFlag) {
      const item = JSON.parse(alertFlag)
      const now = new Date()
      if (now.getTime() > item.expiry) {
        localStorage.removeItem("alertFlag")
        alertSeenFlag = null
      } else alertSeenFlag = item
    }

    if (!alertSeenFlag?.value) {
      const today = startOfDay(new Date())
      const todayAlerts = getAlertsForDay(today)
      setAlertBadge(todayAlerts.length)
    } else setAlertBadge(0)
  }, [alerts, getAlertsForDay, alertFlag])

  // Tabs
  const [tabValue, setTabValue] = useState(() => {
    let homeIndex = localStorage.getItem("homeIndex")
    if (homeIndex && !isNaN(parseInt(homeIndex))) {
      return parseInt(homeIndex)
    }

    return 0
  })

  const handleSetTabValue = (_event: any, newValue: number) => {
    localStorage.setItem("homeIndex", newValue.toString())
    return setTabValue(newValue)
  }

  return (
    <DashboardLayout>
      <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
        <Tab icon={bigScreen && <CalendarIcon />} iconPosition="start" label="Calendar" {...a11yProps(0)} />
        <Tab
          icon={bigScreen && <Notifications />}
          iconPosition="start"
          label={<>Alerts {alertBagde > 0 && <MDBadge badgeContent={alertBagde} color="error" size="xs" circular />}</>}
          {...a11yProps(1)}
        />
        <Tab icon={bigScreen && <Handshake />} iconPosition="start" label="Commitments" {...a11yProps(2)} />
        <Tab icon={bigScreen && <Flag />} iconPosition="start" label="Milestones" {...a11yProps(3)} />
      </Tabs>
      <CustomTabPanel value={tabValue} index={0}>
        <CalendarView />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <AlertsView />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        <CommitmentsView />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={3}>
        <MilestonesView />
      </CustomTabPanel>

      <Footer />
    </DashboardLayout>
  )
}

export default Home
