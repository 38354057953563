import { createUseStyles } from "react-jss"

// MUI
import { Box, Drawer, Tab, Tabs, TextField, useMediaQuery } from "@mui/material"

// Material Dashboard
import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"

import { useContext, useState } from "react"
import { AuthContext, ContactsContext } from "context"
import { CustomTabPanel, a11yProps } from "forge/core/components/TabPanel"
import theme from "assets/theme"
import MemberCard from "forge/organization/members/components/MemberCard"
import { Search } from "@mui/icons-material"
import PermissionGroupTile from "../PermissionGroupTile"

const styles = createUseStyles({
  formIcon: { alignSelf: "center", height: "1.5em", width: "1.5em", marginRight: "16px" },
  avatar: {
    backgroundColor: "white",
    color: "black",
    height: "fit-content",
    width: "fit-content"
  },
  chipIcon: { color: "black", width: "0.75em", height: "0.75em", margin: "4px" }
})

function RoleDrawer({ openDrawer, handleClose, role }: { openDrawer: boolean; handleClose: () => void; role?: any }) {
  // UI
  const classes = styles()
  const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"))
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  // Context
  const { getCurrentUser } = useContext(AuthContext)
  const { contacts } = useContext(ContactsContext)

  // State
  const [searchQuery, setSearchQuery] = useState<string>("")

  // Tabs
  const [tabValue, setTabValue] = useState(0)

  const internalHandleClose = () => {
    setSearchQuery(undefined)
    setTabValue(0)
    handleClose()
  }

  if (!role) {
    return null
  }

  const permissionGroups = [
    {
      id: "organization",
      label: "Organization",
      permissions: [
        {
          label: "Create Organization",
          description: "",
          allowed: true
        },
        {
          label: "Edit Organization",
          description: "",
          allowed: true
        },
        {
          label: "Delete Organization",
          description: "",
          allowed: true
        }
      ]
    },
    {
      id: "billing",
      label: "Billing",
      permissions: [
        {
          label: "Manage Billing",
          description: "",
          allowed: true
        }
      ]
    },
    {
      id: "teams",
      label: "Teams",
      permissions: [
        {
          label: "Create Team",
          description: "",
          allowed: true
        },
        {
          label: "Edit Team",
          description: "",
          allowed: true
        },
        {
          label: "Manage Team Members",
          description: "",
          allowed: true
        },
        {
          label: "Delete Team",
          description: "",
          allowed: true
        }
      ]
    },
    {
      id: "members",
      label: "Members",
      permissions: [
        {
          label: "Invite Members",
          description: "",
          allowed: true
        },
        {
          label: "Modify Member Role",
          description: "",
          allowed: true
        },
        {
          label: "Remove Members",
          description: "",
          allowed: true
        }
      ]
    }
  ]

  return (
    <Drawer
      anchor="right"
      open={openDrawer}
      onClose={internalHandleClose}
      PaperProps={{
        sx: {
          width: largeScreen ? "40%" : mediumScreen ? "70%" : "90%"
        }
      }}
    >
      {/* Header */}
      <div style={{ backgroundColor: "black", padding: 24 }}>
        <MDTypography variant="h5" style={{ color: "white" }}>
          {role?.name}
        </MDTypography>
        <Box height={6} />
        <MDTypography variant="body2" style={{ color: "white" }}>
          {role?.description}
        </MDTypography>
      </div>

      {/* Body */}
      <MDBox mx={3} mt={2}>
        <Tabs orientation="horizontal" value={tabValue} onChange={(_, newValue) => setTabValue(newValue)}>
          <Tab iconPosition="start" label="Permissions" {...a11yProps(0)} />
          <Tab iconPosition="start" label="Users" {...a11yProps(1)} />
        </Tabs>
        <CustomTabPanel value={tabValue} index={0}>
          {permissionGroups.map(e => (
            <PermissionGroupTile permissionGroup={e} />
          ))}
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <TextField
            id="title"
            label="Search contact"
            variant="outlined"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            style={{ flex: 1, width: "100%" }}
            InputLabelProps={{ style: { color: "gray" } }}
            InputProps={{
              endAdornment: <Search fontSize="small" />
            }}
          />
          <Box height={16} />
          {role.members?.slice(0, 100).map((member: any) => (
            <MemberCard member={member} />
          ))}
        </CustomTabPanel>
      </MDBox>
    </Drawer>
  )
}

// Setting default values for the props of RoleDrawer
RoleDrawer.defaultProps = {
  handleCloseDrawer: null
}

export default RoleDrawer
