/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "App"

// Material Dashboard 2 PRO React TS Context Provider
import { MaterialUIControllerProvider, AuthContextProvider, ContactsContextProvider, CalendarsContextProvider, EventsContextProvider } from "context"
import { SecureStorageContextProvider } from "forge/core/services/SecureStorageContext"
import { RemoteConfigContextProvider } from "forge/core/services/RemoteConfigContext"
import { OpportunitiesContextProvider } from "forge/pipeline/opportunities/services/OpportunitiesContext"
import { AppStateContextProvider } from "forge/core/services/AppStateContext"
import { initSentry } from "sentry.init"
import { HelmetProvider } from "react-helmet-async"
import { OrganizationContextProvider } from "forge/organization/services/OrganizationContext"
import { MembersContextProvider } from "forge/organization/members/services/MembersContext"
import { TeamsContextProvider } from "forge/organization/teams/services/TeamsContext"
import { setupAxiosInterceptors } from "forge/core/services/interceptor"
import { CrmContextProvider } from "forge/settings/services/CrmContext"

initSentry()
const root = createRoot(document.getElementById("root"))

setupAxiosInterceptors(() => {})

root.render(
  <BrowserRouter>
    <HelmetProvider>
      <AppStateContextProvider>
        <SecureStorageContextProvider>
          <RemoteConfigContextProvider>
            <AuthContextProvider>
              <OrganizationContextProvider>
                <ContactsContextProvider>
                  <CalendarsContextProvider>
                    <OpportunitiesContextProvider>
                      <EventsContextProvider>
                        <CrmContextProvider>
                          <MembersContextProvider>
                            <TeamsContextProvider>
                              <MaterialUIControllerProvider>
                                <App />
                              </MaterialUIControllerProvider>
                            </TeamsContextProvider>
                          </MembersContextProvider>
                        </CrmContextProvider>
                      </EventsContextProvider>
                    </OpportunitiesContextProvider>
                  </CalendarsContextProvider>
                </ContactsContextProvider>
              </OrganizationContextProvider>
            </AuthContextProvider>
          </RemoteConfigContextProvider>
        </SecureStorageContextProvider>
      </AppStateContextProvider>
    </HelmetProvider>
  </BrowserRouter>
)
