// MUI
import { Icon, Paper, Stack, Switch } from "@mui/material"

// Material Dashboard
import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import { useContext, useEffect, useState } from "react"
import CreateCommitmentDrawer from "forge/commitments/components/CreateCommitmentDrawer"
import { Commitment } from "types/commitment"
import { ForgeAvatarGroup } from "forge/core/components/ForgeAvatar"
import { AuthContext } from "context"
import CommitmentsApi from "forge/commitments/services/api"
import { MembersContext } from "forge/organization/members/services/MembersContext"
import { ForgeOrganizationMember } from "forge/organization/types/member"
import MemberTile from "forge/organization/members/components/MemberTile"
import { OrganizationContext } from "forge/organization/services/OrganizationContext"

function CommitmentCard({ commitment, showAvatar = true }: { commitment: Commitment; showAvatar?: boolean }) {
  const [openCreateCommitmentDrawer, setOpenCreateCommitmentDrawer] = useState(false)
  const handleOpenCommitmentDrawer = () => setOpenCreateCommitmentDrawer(true)
  const handleCloseCommitmentDrawer = () => setOpenCreateCommitmentDrawer(false)

  // Context
  const { getCurrentUser } = useContext(AuthContext)
  const { getMember } = useContext(MembersContext)
  const { organization } = useContext(OrganizationContext)

  // State
  const [completed, setCompleted] = useState<boolean>(false)
  const [creatorMember, setMemberCreator] = useState<ForgeOrganizationMember>()

  // API
  let { user, userProfileData, encryptionService } = getCurrentUser()
  const commitmentsApi = new CommitmentsApi(user, userProfileData, organization, encryptionService)

  // Define Urgency
  // Calculate difference between start date and current date
  const startDate = new Date(commitment.startDate.toDate())
  const differenceMilliseconds = startDate.getTime() - new Date().getTime()
  const differenceHours = Math.floor(differenceMilliseconds / (1000 * 60 * 60))

  const expiredCard = differenceHours < 0 && !commitment.completed
  const aboutToExpireCard = differenceHours >= 0 && differenceHours <= 24 && !commitment.completed

  const updateCompletion = async (checked: boolean) => {
    if (commitment.contacts?.length > 0) {
      setCompleted(checked)
      commitment.completed = checked
      await commitmentsApi.updateCommitment(commitment.contacts[0], commitment)
    }
  }

  useEffect(() => {
    if (commitment?.isOrganizationCommitment && commitment?.createdBy) {
      setMemberCreator(getMember(commitment.createdBy.id))
    }

    setCompleted(commitment.completed)
  }, [commitment])

  return (
    <MDBox mb={2}>
      <CreateCommitmentDrawer
        openDrawer={openCreateCommitmentDrawer}
        handleCloseDrawer={handleCloseCommitmentDrawer}
        commitment={commitment}
        keepSelectedContact={true}
      />
      <Paper
        elevation={2}
        style={{
          borderRadius: 12,
          border: `${expiredCard || aboutToExpireCard ? 3 : 0}px solid ${expiredCard ? "red" : "orange"}`,
          cursor: "pointer"
        }}
        onClick={handleOpenCommitmentDrawer}
      >
        <MDBox component="li" display="flex" justifyContent="space-between" alignItems="flex-start" bgColor={"grey-100"} borderRadius="lg" p={2}>
          <MDBox width="100%" display="flex" flexDirection="column">
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="column" maxWidth="88%">
                <MDTypography mb={1} variant="caption" color="text">
                  {commitment.startDate.toDate().toDateString()}
                </MDTypography>
                <MDBox lineHeight={0}>
                  <MDTypography variant="h6" fontWeight="medium">
                    {commitment.name}
                  </MDTypography>
                </MDBox>
                {/* {commitment.contacts?.length > 0 && showAvatar && (
                                    <MDBox lineHeight={0}>
                                        <MDTypography variant="caption">
                                            For {commitment.contacts[0]?.name}
                                        </MDTypography>
                                    </MDBox>
                                )} */}
              </Stack>
              <Switch
                checked={completed}
                onClick={e => {
                  // e.preventDefault();
                  e.stopPropagation()
                }}
                onChange={(e, checked) => updateCompletion(checked)}
              />
            </Stack>

            {commitment.contacts?.length > 0 && showAvatar && (
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
                mt={1}
              >
                <ForgeAvatarGroup contacts={commitment.contacts} />

                <MDButton
                  variant="text"
                  color={"info"}
                  size="small"
                  onClick={handleOpenCommitmentDrawer}
                  style={{ textTransform: "none", fontWeight: "400", padding: 0, fontSize: "0.875rem" }}
                >
                  Review&nbsp;<Icon>arrow_forward</Icon>
                </MDButton>
              </MDBox>
            )}

            {creatorMember && (
              <MDBox mt={1.5}>
                <MemberTile member={creatorMember} prefixText="Created by " size="small" color="gray" />
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      </Paper>
    </MDBox>
  )
}

// Setting default values for the props of CommitmentCard
CommitmentCard.defaultProps = {
  commitment: null
}

export default CommitmentCard
