import { firestoreDb } from "firebase.init"
import { DocumentReference, Timestamp, doc } from "firebase/firestore"

export const documentReferenceFromMap = (ref: any) => {
  return ref ? (ref instanceof DocumentReference ? ref : typeof ref === "string" ? doc(firestoreDb, ref) : null) : null
}

export const dateFromMap = (date: any) => {
  return date ? (date instanceof Timestamp ? date.toDate() : typeof date === "number" ? new Date(date) : null) : null
}

export const dateToMap = (date: any) => {
  return date ? (date instanceof Timestamp ? date.toMillis() : date instanceof Date ? date.getTime() : undefined) : undefined
}
