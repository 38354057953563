import { CalendarEvent } from "./calendar/calendar-event"
import { ForgeEvent } from "./forge-event"

export const isOrganizer = (event: ForgeEvent): boolean => {
  if (!event) return false

  return event instanceof CalendarEvent
    ? (event as CalendarEvent).properties.userAttendee
      ? (event as CalendarEvent).properties.userAttendee!.isOrganizer
      : !(event as CalendarEvent).attendees.some(e => e.isOrganizer)
    : false
}

export const hasPhysicalLocation = (event: ForgeEvent): boolean => {
  return event instanceof CalendarEvent && (event as CalendarEvent).properties.location && (event as CalendarEvent).properties.location !== ""
}

export const hasMeetingUrl = (event: ForgeEvent): boolean => {
  return event instanceof CalendarEvent && (event as CalendarEvent).properties.url && (event as CalendarEvent).properties.url !== ""
}
