export const NOTIFICATION_TYPES = {
  ERROR: "error",
  INFO: "info",
  QUESTION: "question",
  SUCCESS: "success",
  WARNING: "warning"
}

export const LOADING_MESSAGES = {
  CALENDAR: "Syncing with Calendar...",
  CRM: "Syncing with CRM..."
}
