// @material-ui core components
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"

// Settings page components
import FormField from "layouts/pages/account/components/FormField"
import { useContext, useEffect, useState } from "react"
import { Button, Stack } from "@mui/material"
import ConfirmationDialog from "forge/core/components/ConfirmationDialog"
import MDButton from "components/MDButton"
import { OrganizationContext } from "forge/organization/services/OrganizationContext"
import { debounce } from "lodash"
import { ForgeOrganization } from "forge/organization/types/organization"
import { Can } from "forge/organization/rbac/CanContext"
import { DisabledTooltip } from "forge/organization/rbac/DisabledTooltip"
import OrganizationCrmSyncCard from "./organization-crm-sync-card"

function OrganizationInfo(): JSX.Element {
  // Context
  const { organization, updateOrganization, deleteOrganization } = useContext(OrganizationContext)

  // State
  const [domain, setDomain] = useState<string>()
  const [name, setName] = useState<string>()
  const [legalName, setLegalName] = useState<string>()
  const [address, setAddress] = useState<string>()
  const [linkedInUrl, setLinkedInUrl] = useState<string>()
  const [updatedFromTextField, setUpdatedFromTextField] = useState<boolean>(false)

  useEffect(() => {
    if (organization) {
      setDomain(organization.domain)
      setName(organization.name)
      setLegalName(organization.legalName)
      setAddress(organization.address)
      setLinkedInUrl(organization.linkedInUrl)
    }
  }, [organization])

  useEffect(() => {
    if (updatedFromTextField) {
      // Debounce the updateBasicInfo function
      const debouncedFunction = debounce(async () => {
        setUpdatedFromTextField(false)
        await updateOrganization(
          new ForgeOrganization({
            domain,
            name,
            legalName,
            address,
            linkedInUrl
          })
        )
      }, 500)

      debouncedFunction()

      return () => {
        // Cleanup function
        debouncedFunction.cancel() // Cancel debounce on component unmount
      }
    }
  }, [name, legalName, address, linkedInUrl])

  const customErrorFeature = "Only the account administrator may use this feature."
  const customErrorEdit = "Please contact your administrator to change this information"

  return (
    <Card id="organization" sx={{ overflow: "visible", marginBottom: "24px" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Organization Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Can I="edit" an="organization" passThrough>
              {allowed => (
                <DisabledTooltip allowed={allowed} message={customErrorEdit}>
                  <FormField
                    label="Common Name"
                    placeholder="Organization Name"
                    disabled={!allowed}
                    value={name}
                    onChange={(e: any) => {
                      setUpdatedFromTextField(true)
                      setName(e.target.value)
                    }}
                  />
                </DisabledTooltip>
              )}
            </Can>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Can I="edit" an="organization" passThrough>
              {allowed => (
                <DisabledTooltip allowed={allowed} message={customErrorEdit}>
                  <FormField
                    label="Legal Name"
                    placeholder="Legal Name"
                    disabled={!allowed}
                    value={legalName}
                    onChange={(e: any) => {
                      setUpdatedFromTextField(true)
                      setLegalName(e.target.value)
                    }}
                  />
                </DisabledTooltip>
              )}
            </Can>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Can I="edit" an="organization" passThrough>
              {allowed => (
                <DisabledTooltip allowed={allowed} message={customErrorEdit}>
                  <FormField
                    label="Address"
                    placeholder="Address"
                    disabled={!allowed}
                    value={address}
                    onChange={(e: any) => {
                      setUpdatedFromTextField(true)
                      setAddress(e.target.value)
                    }}
                  />
                </DisabledTooltip>
              )}
            </Can>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Can I="edit" an="organization" passThrough>
              {allowed => (
                <DisabledTooltip allowed={allowed} message={customErrorEdit}>
                  <FormField
                    label="Company's LinkedIn Profile"
                    placeholder="https://www.linkedin.com/in/..."
                    disabled={!allowed}
                    value={linkedInUrl}
                    onChange={(e: any) => {
                      setUpdatedFromTextField(true)
                      setLinkedInUrl(e.target.value)
                    }}
                  />
                </DisabledTooltip>
              )}
            </Can>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  )
}

export default OrganizationInfo
