import { createContext, useState, useEffect, useContext } from "react"
import { useLocation, useNavigate } from "react-router-dom"

// Firebase
import { User, getAuth, signInWithCustomToken } from "firebase/auth"
import { firestoreDb, onMessageListener, setupNotifications } from "firebase.init"
import { DocumentReference, doc, getDoc } from "firebase/firestore"

import { UserProfileData } from "types/user/user-profile-data"
import { VirgilService } from "forge/core/services/encryption/virgil"
import CrossDeviceAccessKeyRequestDialog from "forge/auth/components/CrossDeviceAccessKeyRequestDialog"
import AuthFirestoreService from "./firestore"
import CrossDeviceAccessKeyDisplayDialog from "../components/CrossDeviceAccessKeyDisplayDialog"
import { UserSettings } from "types/user/user-settings"
import { SecureStorageContext } from "forge/core/services/SecureStorageContext"
import { AppStateContext } from "forge/core/services/AppStateContext"
import { SplashScreen } from "forge/core/components/SplashScreen"
import AuthApi from "./api"
import { RemoteConfigContext } from "forge/core/services/RemoteConfigContext"
import { ForgeEncryption } from "forge/core/services/encryption"
import { SealdService } from "forge/core/services/encryption/seald"
import { UserEmail } from "types/user/user-email"
import * as Sentry from "@sentry/react"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { register } from "../../../../public/serviceWorker"

interface AuthContextType {
  userId: string
  isAuthenticated: boolean
  userProfileData: UserProfileData | null
  loggingIn: boolean
  setIsAuthenticated: (state: boolean) => void
  isEncryptionInitialized: boolean
  initializeEThreeWithPassword: (key: string) => void
  login: () => void
  logout: () => void
  getCurrentUser: () => {
    user: User | null
    encryptionService: ForgeEncryption
    userProfileData: UserProfileData | null
    userSettings: UserSettings | null
    userRef: DocumentReference | undefined
    memberRef: DocumentReference | undefined
  }
  getRole: () => void
  microsoftSso: () => Promise<void>
  usersNonCommonDomainEmails: () => UserEmail[]
}

export const AuthContext = createContext<AuthContextType>({
  userId: undefined,
  isAuthenticated: false,
  userProfileData: null,
  loggingIn: false,
  setIsAuthenticated: (state: boolean) => {},
  isEncryptionInitialized: false,
  initializeEThreeWithPassword: (key: string) => {},
  login: () => {},
  logout: () => {},
  getCurrentUser: (): {
    user: User | null
    encryptionService: ForgeEncryption
    userProfileData: UserProfileData | null
    userSettings: UserSettings | null
    userRef: DocumentReference | undefined
    memberRef: DocumentReference | undefined
  } => ({
    user: null,
    encryptionService: undefined,
    userProfileData: null,
    userSettings: null,
    userRef: undefined,
    memberRef: undefined
  }),
  getRole: () => {},
  microsoftSso: async () => {},
  usersNonCommonDomainEmails: () => []
})

export const AuthContextProvider = ({ children }: { children: any }) => {
  // Context
  const { authLoading, setAuthLoading } = useContext(AppStateContext)
  const { read, write, containsKey, deleteAll } = useContext(SecureStorageContext)
  const { commonEmailDomains } = useContext(RemoteConfigContext)

  // Services
  const authApi = new AuthApi()
  const firebaseAuth = getAuth()

  // State
  const [userId, setUserId] = useState<string>()
  const [deviceToken, setDeviceToken] = useState<string>()
  const [loggingIn, setLoggingIn] = useState<boolean>(false)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [forgeEncryption, setForgeEncryption] = useState<ForgeEncryption>()
  const [isEncryptionInitialized, setIsEncryptionInitialized] = useState<boolean>(false)
  const [crossDeviceAccessKey, setCrossDeviceAccessKey] = useState<string>()
  const [openCrossDeviceKeyDisplayDialog, setOpenCrossDeviceKeyDisplayDialog] = useState<boolean>(false)
  // const [openCrossDeviceKeyRequestDialog, setOpenCrossDeviceKeyRequestDialog] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>()
  const [userProfileData, setUserProfileData] = useState<UserProfileData | null>(null)
  const [userSettings, setUserSettings] = useState<UserSettings | null>(null)

  const navigate = useNavigate()
  const location = useLocation()

  const startNotificationSetup = async () => {
    // Set notifications for user
    const deviceToken = await setupNotifications()
    if (deviceToken) {
      onMessageListener()
      setDeviceToken(deviceToken)
    }
  }

  useEffect(() => {
    ;(async () => {
      await startNotificationSetup()
      if (deviceToken && userId) await authApi.subscribeToTopic(deviceToken)
    })()
  }, [deviceToken, userId])

  useEffect(() => {
    setUserId(firebaseAuth?.currentUser?.uid)
  }, [firebaseAuth?.currentUser])

  useEffect(() => {
    ;(async () => {
      await firebaseAuth.authStateReady()

      // There's no authenticated user, stop loading state and return
      if (!firebaseAuth.currentUser) {
        setAuthLoading(false)
        return
      }

      // A user is authenticated, then initialize Encryption service
      try {
        const seald = new SealdService()
        const virgil = new VirgilService()
        setForgeEncryption(new ForgeEncryption(seald, virgil))
      } catch (error) {
        Sentry.captureMessage("AuthContext, useEffect: 125")
        Sentry.captureException(error)
        console.error(error)
      }

      // Set flags and navigate
      setIsAuthenticated(true)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (isAuthenticated) {
        let localForgeEncryption = forgeEncryption
        if (!forgeEncryption) {
          // A user is authenticated, then initialize Encryption service
          try {
            const seald = new SealdService()
            const virgil = new VirgilService()
            localForgeEncryption = new ForgeEncryption(seald, virgil)
            setForgeEncryption(localForgeEncryption)
          } catch (error) {
            Sentry.captureMessage("AuthContext, useEffect: 146")
            Sentry.captureException(error)
            console.error(error)
          }
        }

        if (localForgeEncryption) {
          setLoggingIn(true)
          const authFirestoreService = new AuthFirestoreService(firebaseAuth.currentUser, localForgeEncryption)

          const userRef = doc(firestoreDb, `users/${firebaseAuth.currentUser.uid}`)

          const userId = firebaseAuth.currentUser?.uid
          const userDoc = await getDoc(userRef)
          const userData = userDoc.data()

          if (!userDoc.exists()) {
            // User is Registering, create it's profile
            await authFirestoreService.createUserProfile()

            // Register user in Encryption Platforms
            const cdak = await localForgeEncryption.createIdentity({ userId })

            // Display CDAK
            setCrossDeviceAccessKey(cdak)
            setOpenCrossDeviceKeyDisplayDialog(true)
            await write(`${userId}_PrivateKey`, cdak)
            await authFirestoreService.updateEncryptionPasswordSet()
            setIsEncryptionInitialized(true)
            setAuthLoading(false)
            navigate("/home")
          } else {
            // User is Signing In
            let secureStoragePrivateKey = containsKey(`${userId}_PrivateKey`)

            if (secureStoragePrivateKey) {
              // We have the key, meaning the user is opening a new tab or window
              // Authenticate him to Virgil & Seald
              let secureStoragePrivateKey = await read(`${userId}_PrivateKey`)
              if (userData.encryption?.sealdIdentity) {
                await localForgeEncryption.retrieveIdentity({
                  userId,
                  password: secureStoragePrivateKey
                })
              } else {
                await localForgeEncryption.createIdentity({
                  userId,
                  existingPassword: secureStoragePrivateKey
                })
              }

              // Notify the system and let the use go through
              setIsEncryptionInitialized(true)
              setAuthLoading(false)
              navigate(location.pathname)
            } else {
              // We don't have the password, we need to get it from the user.
              if (userData.encryptionPasswordSet) {
                // User will be asked for CDAK
                setAuthLoading(false)
                navigate("/auth/cdak")
              } else {
                // Register user in Encryption Platforms
                const cdak = await localForgeEncryption.createIdentity({
                  userId: firebaseAuth.currentUser.uid
                })

                // Display CDAK
                setCrossDeviceAccessKey(cdak)
                setOpenCrossDeviceKeyDisplayDialog(true)
                await write(`${userId}_PrivateKey`, cdak)
                await authFirestoreService.updateEncryptionPasswordSet()
                setIsEncryptionInitialized(true)
                setAuthLoading(false)
                navigate("/home")
              }
            }
          }

          // This function verifies the membership to an organization.
          // Naming is generic in case there's a need to do anything else.
          authApi.onUserAuthenticated()

          const unsubscribe = authFirestoreService.getUserProfileData((userProfileData, userSettings) => {
            setUserProfileData(userProfileData)
            setUserSettings(userSettings)
          })

          setLoggingIn(false)
          await startNotificationSetup()
          return () => unsubscribe()
        }
      }
    })()
  }, [isAuthenticated])

  const initializeEThreeWithPassword = async (key: string) => {
    try {
      setErrorMessage(undefined)
      if (key && key.length > 0) {
        await forgeEncryption.retrieveIdentity({
          userId: firebaseAuth.currentUser?.uid,
          password: key
        })
        await write(`${firebaseAuth.currentUser?.uid}_PrivateKey`, key)
        setIsEncryptionInitialized(true)
        await startNotificationSetup()
        navigate("/home")
      }
    } catch (error) {
      console.error(error)
      setErrorMessage("Incorrect CDAK. Please try again.")
    }
  }

  const login = async () => {
    setIsAuthenticated(true)
  }

  const logout = async () => {
    // Unsubscribe notifications
    await authApi.unsubscribeFromTopic(deviceToken)
    // Firebase
    await firebaseAuth.signOut()

    await forgeEncryption.cleanup()
    setIsEncryptionInitialized(false)

    // Local Secure Storage
    deleteAll()

    setIsAuthenticated(false)
    navigate("/sign-in")
  }

  const onKeyAcknowledged = () => {
    setCrossDeviceAccessKey(undefined)
    setOpenCrossDeviceKeyDisplayDialog(false)
  }

  const getCurrentUser = (): {
    user: User | null
    encryptionService: ForgeEncryption
    userProfileData: UserProfileData | null
    userSettings: UserSettings | null
    userRef: DocumentReference | undefined
    memberRef: DocumentReference | undefined
  } => {
    try {
      return {
        user: firebaseAuth.currentUser,
        encryptionService: forgeEncryption,
        userProfileData,
        userSettings,
        userRef: firebaseAuth.currentUser ? doc(firestoreDb, `users/${firebaseAuth.currentUser.uid}`) : undefined,
        memberRef:
          userProfileData?.organization?.id && firebaseAuth.currentUser
            ? doc(firestoreDb, `organizations/${userProfileData.organization?.id}/members/${firebaseAuth.currentUser.uid}`)
            : undefined
      }
    } catch (err) {
      console.error(err)
      return {
        user: null,
        encryptionService: null,
        userProfileData: null,
        userSettings: null,
        userRef: undefined,
        memberRef: undefined
      }
    }
  }

  const getRole = async () => {
    // // first get the current user id
    // const id = await getCurrentUser();
    // try {
    //     // second I get the user with role
    //     const res = await CrudService.getUser(id);
    //     const roleId = res.data.relationships.roles.data[0].id;
    //     // third check the role id and return the role type
    //     if (roleId === "1") {
    //         return "admin";
    //     }
    //     if (roleId === "2") {
    //         return "creator";
    //     }
    //     if (roleId === 3) {
    //         return "member";
    //     }
    //     return res.included[0].attributes.name;
    // } catch (err) {
    //     console.error(err);
    //     return null;
    // }
  }

  const microsoftSso = async () => {
    const endpoint = `/user/auth/microsoft`
    const popup = window.open(
      `${process.env.REACT_APP_API_URL}${endpoint}`,
      "targetWindow",
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=500, height=550"
    )

    window.addEventListener("message", async event => {
      let apiUrl = process.env.REACT_APP_API_URL
      if (apiUrl.endsWith("/")) {
        apiUrl = apiUrl.slice(0, -1)
      }

      if (event.origin === apiUrl) {
        if (event.data) {
          let data = event.data

          if (data.code) {
            try {
              let result = await authApi.authOutlookCalendar(data.code)
              let firebaseToken = await authApi.getMicrosoftFirebaseUserToken(result)

              await signInWithCustomToken(firebaseAuth, firebaseToken)
              login()
            } catch (error) {
              console.warn(error)
            }
          }
          popup.close()
        }
      }
    })
  }

  const usersNonCommonDomainEmails = (): UserEmail[] => {
    return userProfileData?.emails?.filter(email => !commonEmailDomains.some(commonEmail => email.email.includes(commonEmail)))
  }

  if (authLoading) {
    return <SplashScreen />
  }

  return (
    <AuthContext.Provider
      value={{
        userId,
        isAuthenticated,
        userProfileData,
        loggingIn,
        setIsAuthenticated,
        isEncryptionInitialized,
        initializeEThreeWithPassword,
        login,
        logout,
        getCurrentUser,
        getRole,
        microsoftSso,
        usersNonCommonDomainEmails
      }}
    >
      <CrossDeviceAccessKeyDisplayDialog open={openCrossDeviceKeyDisplayDialog} accessKey={crossDeviceAccessKey} onContinue={onKeyAcknowledged} />
      {children}
    </AuthContext.Provider>
  )
}
