import React from "react"
import { Chip, Stack, useMediaQuery } from "@mui/material"
import theme from "assets/theme"
import MDTypography from "components/MDTypography"
import Markdown from "forge/shared/Markdown/Markdown"

interface Props {
  page: any
}

const Showcase: React.FC<Props> = ({ page }) => {
  const xlargeScreen = useMediaQuery(theme.breakpoints.up("xl"))
  const { title, subtitle, content, image } = page

  return (
    <Stack alignItems="center" justifyContent="center" spacing={3} style={{ width: "100%", position: "absolute", top: xlargeScreen ? "30%" : "20%" }}>
      <MDTypography variant="h4" textAlign="center" style={{ color: "white" }}>
        <span style={{ textDecoration: "underline cornflowerblue 5px" }}>THE LASTEST:</span> {title}
      </MDTypography>
      {subtitle && <Chip label={subtitle} color="primary" />}
      <Markdown source={content} isRichText isBlackBackground />
      <img src={image?.file?.url} alt="" />
    </Stack>
  )
}

export default Showcase
