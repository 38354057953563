import { AuthContext } from "context"
import { useContext } from "react"
import { Navigate } from "react-router-dom"

const ProtectedRoute = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { isAuthenticated, isEncryptionInitialized } = useContext(AuthContext)

  if (!isAuthenticated) {
    return <Navigate to={"/sign-in"} replace />
  } else if (!isEncryptionInitialized) {
    return <Navigate to={"/cdak"} replace />
  }

  return children
}

export default ProtectedRoute
