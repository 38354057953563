function SignInButton({ onClick, ssoOption, cta }: { onClick: () => void; ssoOption: "google" | "microsoft"; cta: string }): JSX.Element {
  const getLongText = () => {
    switch (ssoOption) {
      case "google":
        return `${cta} with Google`
      case "microsoft":
        return `${cta} with Microsoft`
    }
  }

  const getShortText = () => {
    switch (ssoOption) {
      case "google":
        return "Google"
      case "microsoft":
        return "Microsoft"
    }
  }

  const getLogoUrl = () => {
    switch (ssoOption) {
      case "google":
        return "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
      case "microsoft":
        return "https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/microsoft.svg"
    }
  }

  return (
    <button
      className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-generic firebaseui-id-idp-button"
      data-provider-id="microsoft.com"
      data-upgraded=",MaterialButton"
      style={{
        background: "white",
        display: "block",
        margin: "0 auto",
        borderRadius: "8px"
      }}
      onClick={onClick}
    >
      <span className="firebaseui-idp-icon-wrapper">
        <img className="firebaseui-idp-icon" style={{ filter: "brightness(0)" }} alt="" src={getLogoUrl()} />
      </span>
      <span className="firebaseui-idp-text firebaseui-idp-text-long" style={{ color: "#757575" }}>
        {getLongText()}
      </span>
      <span className="firebaseui-idp-text firebaseui-idp-text-short" style={{ color: "#757575" }}>
        {getShortText()}
      </span>
    </button>
  )
}

export default SignInButton
