import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import EventOverview from "forge/calendar/event-overview"
import ContactOverview from "forge/people/contacts/contact-overview"
import DealOverview from "forge/pipeline/opportunities/deal-overview"

const crudRoutes = [
  {
    key: "contact-overview",
    route: "/people/relationships/:id",
    component: (
      <DashboardLayout>
        <ContactOverview />
      </DashboardLayout>
    ),
    type: "contact"
  },
  {
    key: "deal-overview",
    route: "/pipeline/opportunities/:id",
    component: <DealOverview />,
    type: "deal"
  },
  {
    key: "event-overview",
    route: "/calendar/events/:id",
    component: <EventOverview />,
    type: "event"
  }
]

export default crudRoutes
