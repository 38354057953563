/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { ReactNode } from "react"

// @mui material components
import { Theme } from "@mui/material/styles"

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox"

// Declaring prop types for DataTableBodyCell
interface Props {
  children: ReactNode
  noBorder?: boolean
  padding?: any
  widthSetting?: string
  align?: "left" | "right" | "center"
}

function DataTableBodyCell({ noBorder, padding, widthSetting = "max-content", align, children }: Props): JSX.Element {
  return (
    <MDBox
      component="td"
      textAlign={align}
      pl={padding?.left ?? 3}
      pr={padding?.right ?? 3}
      pt={padding?.top ?? 1.5}
      pb={padding?.bottom ?? 1.5}
      sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }: Theme) => ({
        fontSize: size.sm,
        borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`
      })}
    >
      <MDBox display="inline-block" width={widthSetting} color="text" sx={{ verticalAlign: "middle" }}>
        {children}
      </MDBox>
    </MDBox>
  )
}

// Declaring default props for DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: "left"
}

export default DataTableBodyCell
