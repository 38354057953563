import { News } from "types/news/news"
import HttpService from "../../../core/services/http.service"

class NewsApi {
  getNews = async (searchTerms: string[] = []): Promise<News[]> => {
    const usersEndpoint = "news"
    let result = await HttpService.post(usersEndpoint, { searchTerms })

    result = result.map((e: any) => {
      try {
        return News.fromMap(e)
      } catch (error) {
        console.warn(error)
        return null
      }
    })

    return result.filter((e: News | null) => e)
  }

  getTrendingNews = async (filterByUser: boolean = true): Promise<News[]> => {
    const usersEndpoint = "news/trending"
    let result = await HttpService.get(usersEndpoint, { filterByUser })
    if (!result.success) return []

    let news = result.data?.news ?? []
    result = news.map((e: any) => {
      try {
        return News.fromMap(e)
      } catch (error) {
        console.warn(error)
        return null
      }
    })

    return result.filter((e: News | null) => e)
  }
}

export default new NewsApi()
