// Images
import forgeLogo from "assets/forge/images/forge.png"
import ForgeLoading from "forge/shared/ForgeLoading/ForgeLoading"

export const SplashScreen = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <img src={forgeLogo} alt="Forge Logo" style={{ margin: "10px", width: "25%" }} />
        <ForgeLoading loading loadingType="splash" />
      </div>
    </div>
  )
}
