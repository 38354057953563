import { createContext, useContext, useEffect, useState } from "react"
import { AuthContext } from "context"
import OrganizationFirestore from "../../organization/services/firestore"
import SettingsFirestore from "./firestore"
import { Unsubscribe } from "firebase/firestore"

interface InvoiceContextType {
  invoices: any[]
}

export const InvoiceContext = createContext<InvoiceContextType>({
  invoices: []
})

export const InvoiceContextProvider = ({ children }: { children: any }) => {
  // Context
  const { getCurrentUser, isEncryptionInitialized } = useContext(AuthContext)
  const { user, encryptionService, userProfileData } = getCurrentUser()

  // Services
  const settingsFirestore = new SettingsFirestore(user, userProfileData, encryptionService)

  // State
  const [invoices, setInvoices] = useState<any[]>([])

  // Organization
  useEffect(() => {
    if (isEncryptionInitialized) {
      let unsubscribe: Unsubscribe
      if (userProfileData?.organization?.id) {
        if (!(userProfileData?.organization?.pendingActivation ?? false) && !(userProfileData?.organization?.pendingMigration ?? false)) {
          unsubscribe = settingsFirestore.getOrganizationInvoicesLive(invoices => {
            setInvoices(invoices)
          })
        } else {
          setInvoices([])
        }
      } else {
        unsubscribe = settingsFirestore.getPersonalInvoicesLive(invoices => {
          setInvoices(invoices)
        })
      }

      return () => unsubscribe()
    }
  }, [isEncryptionInitialized, userProfileData?.organization?.id, userProfileData?.organization?.pendingMigration])

  return <InvoiceContext.Provider value={{ invoices }}>{children}</InvoiceContext.Provider>
}
