import { DocumentReference, DocumentSnapshot, Timestamp } from "firebase/firestore"
import { Crm } from "types/pipeline/crm"

export class ForgeOrganization {
  ref?: DocumentReference
  parentOrganizationRef?: DocumentReference
  subOrganizations?: DocumentReference[]
  sealdGroupId: string
  domain: string
  name: string
  legalName?: string
  url?: string
  address?: string
  linkedInUrl?: string
  createdAt?: Date
  updatedAt?: Date
  pipelineConfig?: {
    crmId: string
    integration: Crm
    isEnabled: boolean
    enableDate: Date
    lastSync: Date
    pipelineLoading: boolean
    loadingProcess?: number | null
  }

  // Stripe
  stripeCustomerId?: string
  stripeSubscriptionId?: string
  stripeData?: {
    customer?: any
    subscription?: any
  }

  // Deletion
  preDeleted?: boolean

  // eslint-disable-next-line
  constructor({
    ref,
    parentOrganizationRef,
    subOrganizations,
    sealdGroupId,
    domain,
    name,
    legalName,
    url,
    address,
    linkedInUrl,
    createdAt,
    updatedAt,
    pipelineConfig,
    stripeCustomerId,
    stripeSubscriptionId,
    stripeData,
    preDeleted
  }: {
    ref?: DocumentReference
    parentOrganizationRef?: DocumentReference
    subOrganizations?: DocumentReference[]
    sealdGroupId?: string
    domain: string
    name: string
    legalName?: string
    url?: string
    address?: string
    linkedInUrl?: string
    createdAt?: Date
    updatedAt?: Date
    pipelineConfig?: {
      crmId: string
      integration: Crm
      isEnabled: boolean
      enableDate: Date
      lastSync: Date
      pipelineLoading: boolean
      loadingProcess?: number | null
    }
    stripeCustomerId?: string
    stripeSubscriptionId?: string
    stripeData?: {
      customer?: any
      subscription?: any
    }
    preDeleted?: boolean
  }) {
    this.ref = ref
    this.parentOrganizationRef = parentOrganizationRef
    this.subOrganizations = subOrganizations
    this.sealdGroupId = sealdGroupId
    this.domain = domain
    this.name = name
    this.legalName = legalName
    this.url = url
    this.address = address
    this.linkedInUrl = linkedInUrl
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.pipelineConfig = pipelineConfig
    this.stripeCustomerId = stripeCustomerId
    this.stripeSubscriptionId = stripeSubscriptionId
    this.stripeData = stripeData
    this.preDeleted = preDeleted ?? false
  }

  toMap(toFirestore = true): any {
    const map = {
      ref: toFirestore ? this.ref : this.ref?.path,
      parentOrganizationRef: toFirestore ? this.parentOrganizationRef : this.parentOrganizationRef?.path,
      subOrganizations: this.subOrganizations?.map(e => (toFirestore ? e : e?.path)),
      sealdGroupId: this.sealdGroupId,
      domain: this.domain,
      name: this.name,
      legalName: this.legalName,
      url: this.url,
      address: this.address,
      linkedInUrl: this.linkedInUrl,
      createdAt: toFirestore ? this.createdAt ?? Timestamp.now() : this.createdAt?.valueOf(),
      updatedAt: toFirestore ? this.updatedAt ?? Timestamp.now() : this.updatedAt?.valueOf(),
      pipelineConfig: this.pipelineConfig,
      stripeCustomerId: this.stripeCustomerId,
      stripeSubscriptionId: this.stripeSubscriptionId,
      stripeData: this.stripeData,
      preDeleted: this.preDeleted
    }

    return map
  }

  static fromFirestore(doc: DocumentSnapshot): ForgeOrganization {
    const data = doc.data()
    return new ForgeOrganization({
      ref: doc.ref,
      parentOrganizationRef: data.parentOrganizationRef,
      subOrganizations: data.subOrganizations,
      sealdGroupId: data.sealdGroupId,
      domain: data.domain,
      name: data.name,
      legalName: data.legalName,
      url: data.url,
      address: data.address,
      linkedInUrl: data.linkedInUrl,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      pipelineConfig: data.pipelineConfig,
      stripeCustomerId: data.stripeCustomerId,
      stripeSubscriptionId: data.stripeSubscriptionId,
      stripeData: data.stripeData,
      preDeleted: data.preDeleted
    })
  }
}
