import { Timestamp } from "firebase/firestore"
import { ForgeEvent } from "types/forge-event"
import { Call } from "types/pipeline/call"

export function startOfDay(date: Date): Date {
  const start = new Date(date)
  start.setHours(0, 0, 0, 0)
  return start
}

export const endOfDay = (date: Date): Date => {
  const end = new Date(date)
  end.setDate(end.getDate() + 1)
  end.setHours(0, 0, 0, 0)
  return end
}

export function subtractDays(date: Date, days: number): Date {
  const result = new Date(date)
  result.setDate(date.getDate() - days)
  return result
}

export function addDays(date: Date, days: number): Date {
  const result = new Date(date)
  result.setDate(date.getDate() + days)
  return result
}

export function isToday(date: Date): boolean {
  const today = new Date()
  return date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate()
}

export function areSameDay(date1: Date, date2: Date): boolean {
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate()
}

export function smartTime(date: Date | null | undefined, enableMinutes: boolean = false): string {
  let time: string = ""

  if (date !== null && date !== undefined) {
    if (!enableMinutes) {
      date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
    }

    let today: Date = new Date()
    if (date instanceof Timestamp) date = date.toDate()
    if (!enableMinutes) {
      today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0)
    }

    const difference: number = date.getTime() - today.getTime()

    if (difference > 0) {
      time = new Intl.DateTimeFormat("en-US", { month: "short", day: "2-digit", year: "numeric" }).format(date)
    } else if (Math.abs(difference) < 24 * 60 * 60 * 1000) {
      if (enableMinutes) {
        if (Math.abs(difference) < 60 * 1000) {
          time = "a few seconds ago"
        } else if (Math.abs(difference) < 60 * 60 * 1000) {
          time = `${Math.ceil(Math.abs(difference) / (60 * 1000))} minutes ago`
        } else {
          time = `${Math.ceil(Math.abs(difference) / (60 * 60 * 1000))} hours ago`
        }
      } else {
        time = "Today"
      }
    } else if (Math.abs(difference) < 2 * 24 * 60 * 60 * 1000) {
      time = "Yesterday"
    } else if (Math.abs(difference) < 7 * 24 * 60 * 60 * 1000) {
      time = `${Math.floor(Math.abs(difference) / (24 * 60 * 60 * 1000))} days ago`
    } else if (Math.abs(difference) < 28 * 24 * 60 * 60 * 1000) {
      const calculated: number = Math.floor(Math.abs(difference) / (7 * 24 * 60 * 60 * 1000))
      time = `${calculated} week${calculated > 1 ? "s" : ""} ago`
    } else if (Math.abs(difference) < 365 * 24 * 60 * 60 * 1000) {
      const calculated: number = Math.floor(Math.abs(difference) / (28 * 24 * 60 * 60 * 1000))
      time = `${calculated} month${calculated > 1 ? "s" : ""} ago`
    } else {
      const calculated: number = Math.floor(Math.abs(difference) / (365 * 24 * 60 * 60 * 1000))
      time = `${calculated} year${calculated > 1 ? "s" : ""} ago`
    }
  }

  return time
}

export const replaceDiacritics = (text: string): string => {
  const diacriticsMap: { [key: string]: string } = {
    á: "a",
    à: "a",
    â: "a",
    ä: "a",
    ă: "a",
    ā: "a",
    é: "e",
    è: "e",
    ê: "e",
    ë: "e",
    ē: "e",
    ė: "e",
    ę: "e",
    í: "i",
    ì: "i",
    î: "i",
    ï: "i",
    ī: "i",
    ó: "o",
    ò: "o",
    ô: "o",
    ö: "o",
    õ: "o",
    ő: "o",
    ø: "o",
    ō: "o",
    ú: "u",
    ù: "u",
    û: "u",
    ü: "u",
    ū: "u",
    ç: "c",
    ć: "c",
    č: "c",
    ż: "z",
    ź: "z",
    ş: "s",
    ș: "s",
    š: "s",
    ñ: "n",
    ń: "n",
    ý: "y",
    ğ: "g",
    ř: "r",
    ł: "l",
    đ: "d",
    ť: "t",
    ď: "d",
    ů: "u",
    æ: "ae",
    œ: "oe",
    ß: "ss",
    þ: "th",
    ð: "dh",
    ģ: "g",
    ķ: "k",
    ļ: "l",
    ŧ: "t",
    ž: "z",
    ħ: "h",
    ı: "i",
    ї: "yi",
    ё: "ye",
    є: "ye",
    ы: "yi",
    э: "e",
    ў: "u",
    ą: "a",
    į: "i",
    ų: "u",
    ẞ: "ss"
  }

  // Replace diacritics using regular expressions
  return text.replace(/[^\u0000-\u007E]/g, char => diacriticsMap[char] || char)
}

export const capitalizeAllWords = (str: string): string => {
  return str.replace(/\b\w/g, char => char.toUpperCase())
}

export const removeUndefinedValues = (obj: any) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== undefined))
}

export const getDomainFromEmail = (email: string): string | null => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  if (!emailRegex.test(email)) {
    // Return null if the email does not match the pattern
    return null
  }

  const domainMatch = email.match(/@([^@]+)$/)
  return domainMatch ? domainMatch[1] : null
}

export const replaceUndefinedWithNull = (obj: any): any => {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, value === undefined ? null : value]))
}

export const orderCalls = (calls: ForgeEvent[], sortOption: string = "asc"): any[] => {
  const groupedCalls: { [key: string]: Call[] } = {}
  let currentDay: string | undefined

  for (const currentCall of calls) {
    const currentDayTmp: Date = startOfDay(currentCall instanceof Call ? currentCall.date : currentCall.startDate?.toDate() ?? new Date())
    if (currentDay !== currentDayTmp.toISOString()) {
      currentDay = currentDayTmp.toISOString()
    }

    const callGroup: any[] = groupedCalls[currentDay] ?? []
    callGroup.push(currentCall)
    groupedCalls[currentDay] = callGroup
  }

  // Used for Ascending Chronological Order for same day Calls
  for (const key of Object.keys(groupedCalls)) {
    groupedCalls[key] = groupedCalls[key].reverse()
  }

  let sections = []
  for (const [key, value] of Object.entries(groupedCalls)) {
    let keyDate = new Date(key)
    sections.push({
      keyDate,
      items: value,
      header:
        Math.abs(keyDate.getTime() - Date.now()) / (1000 * 60 * 60 * 24) < 29
          ? smartTime(keyDate)
          : new Intl.DateTimeFormat("en-US", { month: "short", day: "2-digit", year: "numeric" }).format(keyDate)
    })
  }

  return sections.sort((a, b) => (sortOption === "asc" ? a.keyDate.getTime() - b.keyDate.getTime() : b.keyDate.getTime() - a.keyDate.getTime()))
}
