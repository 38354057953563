import React, { ReactElement } from "react"
import { Tooltip } from "@mui/material"
import { InfoRounded, SwapVert } from "@mui/icons-material"
import MDTypography from "components/MDTypography"
import "./index.css"
import MDBox from "components/MDBox"

interface TooltipInfo {
  description: string
  benefits?: string
}

interface Props {
  title: string
  tooltipInfo: TooltipInfo
  reactButton?: ReactElement<any, any>
  hasSortAbility?: boolean
  handleSort?: () => void
}

const CardTitle: React.FC<Props> = ({ title, tooltipInfo, reactButton, hasSortAbility, handleSort }) => {
  return (
    <MDBox display="flex" sx={{ justifyContent: "space-between" }}>
      <MDBox display="flex">
        <MDTypography variant="h5">{title}</MDTypography>
        {hasSortAbility && <SwapVert style={{ marginLeft: "5px", cursor: "pointer" }} onClick={handleSort} />}
      </MDBox>
      <MDBox display="flex">
        <Tooltip
          style={{ marginRight: "1rem", color: "gray" }}
          title={
            <div className="tooltipContainer">
              {tooltipInfo.description}
              {tooltipInfo?.benefits && (
                <>
                  <br />
                  <br />
                  Key benefits: {tooltipInfo?.benefits}
                </>
              )}
            </div>
          }
          placement="bottom-start"
          componentsProps={{ tooltip: { sx: { width: "100rem !important" } } }}
        >
          <InfoRounded fontSize="small" />
        </Tooltip>
        {reactButton}
      </MDBox>
    </MDBox>
  )
}

export default CardTitle
