import { createContext, useContext, useState, useEffect } from "react"
import { AuthContext } from "context"
import { KnowledgeType, getForgeKnowledge, getKnowledgeByType, getLocationKnowledge, getUserKnowledge } from "../schemas/knowledge-type"
import KnowledgeApi from "./api"
import { SearchTerm } from "forge/knowledge/schemas/search-term"
import KnowledgeFirestore from "./firestore"
import { Knowledge } from "../schemas/knowledge"
import { OrganizationContext } from "forge/organization/services/OrganizationContext"

interface KnowledgeContextType {
  knowledge: any[]
  knowledgeSummary: string
  forgeKnowledge: any[]
  companyKnowledge: any[]
  locationKnowledge: any[]
  summaryKnowledge: any[]
  educationKnowledge: any[]
  experienceKnowledge: any[]
  volunteerWorkKnowledge: any[]
  analyzeKnowledge: (knowledge: any, saveKnowledge?: boolean) => Promise<any>
  createKnowledge: (text: string, selectedOptions: SearchTerm[]) => Promise<boolean>
  updateKnowledge: (knowledge: any, text: string, selectedOptions: SearchTerm[]) => Promise<boolean>
  deleteKnowledge: (knowledge: any) => Promise<boolean>
  markKnowledgeAsPrivate: (knowledge: any) => Promise<boolean>
  markKnowledgeAsPublic: (knowledge: any) => Promise<boolean>
}

export const KnowledgeContext = createContext<KnowledgeContextType>({
  knowledge: [],
  knowledgeSummary: undefined,
  forgeKnowledge: [],
  companyKnowledge: [],
  locationKnowledge: [],
  summaryKnowledge: [],
  educationKnowledge: [],
  experienceKnowledge: [],
  volunteerWorkKnowledge: [],
  analyzeKnowledge: async () => undefined,
  createKnowledge: async () => false,
  updateKnowledge: async () => false,
  deleteKnowledge: async () => false,
  markKnowledgeAsPrivate: async () => false,
  markKnowledgeAsPublic: async () => false
})

export const KnowledgeContextProvider = ({ contact, loadKnowledge = true, children }: { contact: any; loadKnowledge?: boolean; children: any }) => {
  // Context
  const { getCurrentUser, isEncryptionInitialized } = useContext(AuthContext)
  const { organization } = useContext(OrganizationContext)

  // Services
  const { user, encryptionService, userProfileData, userRef, memberRef } = getCurrentUser()
  const knowledgeFirestore = new KnowledgeFirestore(user, userProfileData, encryptionService)
  const knowledgeApi = new KnowledgeApi(user, userProfileData, organization, encryptionService)

  // State
  const [knowledge, setKnowledge] = useState<any[]>([])
  const [personalKnowledge, setPersonalKnowledge] = useState<any[]>([])
  const [organizationKnowledge, setOrganizationKnowledge] = useState<any[]>([])
  const [knowledgeSummary, setKnowledgeSummary] = useState<string>()
  const [forgeKnowledge, setForgeKnowledge] = useState<Knowledge[]>([])
  const [orgForgeKnowledge, setOrgForgeKnowledge] = useState<Knowledge[]>([])
  const [companyKnowledge, setCompanyKnowledge] = useState<Knowledge[]>([])
  const [orgCompanyKnowledge, setOrgCompanyKnowledge] = useState<Knowledge[]>([])
  const [locationKnowledge, setLocationKnowledge] = useState<Knowledge[]>([])
  const [orgLocationKnowledge, setOrgLocationKnowledge] = useState<Knowledge[]>([])
  const [summaryKnowledge, setSummaryKnowledge] = useState<Knowledge[]>([])
  const [orgSummaryKnowledge, setOrgSummaryKnowledge] = useState<Knowledge[]>([])
  const [educationKnowledge, setEducationKnowledge] = useState<Knowledge[]>([])
  const [orgEducationKnowledge, setOrgEducationKnowledge] = useState<Knowledge[]>([])
  const [experienceKnowledge, setExperienceKnowledge] = useState<Knowledge[]>([])
  const [orgExperienceKnowledge, setOrgExperienceKnowledge] = useState<Knowledge[]>([])
  const [volunteerWorkKnowledge, setVolunteerWorkKnowledge] = useState<Knowledge[]>([])
  const [orgVolunteerWorkKnowledge, setOrgVolunteerWorkKnowledge] = useState<Knowledge[]>([])

  useEffect(() => {
    setKnowledge([...personalKnowledge, ...organizationKnowledge])
  }, [personalKnowledge, organizationKnowledge])

  useEffect(() => {
    if (contact?.ref?.id && user && loadKnowledge) {
      setPersonalKnowledge([])
      clearForgeKnowledge()
      return knowledgeFirestore.getPersonalKnowledgeLive(contact?.ref?.id, async knowledge => {
        // Filter User Knowledge
        setPersonalKnowledge(getUserKnowledge(knowledge))

        if (!contact?.ref?.path?.includes("organizations")) {
          // Filter Forge Knowledge
          let filteredForgeKnowledge = getForgeKnowledge(knowledge)
          setForgeKnowledge(filteredForgeKnowledge)

          // Separate Knowledge
          setCompanyKnowledge(getKnowledgeByType(filteredForgeKnowledge, KnowledgeType.companyFromDomain))
          setLocationKnowledge(getLocationKnowledge(filteredForgeKnowledge))
          setSummaryKnowledge(getKnowledgeByType(filteredForgeKnowledge, KnowledgeType.summary, true))
          setEducationKnowledge(getKnowledgeByType(filteredForgeKnowledge, KnowledgeType.linkedinEducation, true))
          setExperienceKnowledge(getKnowledgeByType(filteredForgeKnowledge, KnowledgeType.linkedinExperience, true))
          setVolunteerWorkKnowledge(getKnowledgeByType(filteredForgeKnowledge, KnowledgeType.linkedinVolunteerWork, true))
        }
      })
    }
  }, [isEncryptionInitialized, contact?.ref])

  useEffect(() => {
    if (contact?.ref?.id && user && loadKnowledge && userProfileData?.doesUserBelongsToAnOrganization) {
      setOrganizationKnowledge([])
      clearForgeKnowledge()
      return knowledgeFirestore.getOrganizationKnowledgeLive(contact?.ref?.id, async knowledge => {
        // Filter User Knowledge
        setOrganizationKnowledge(getUserKnowledge(knowledge))

        // Filter Forge Knowledge
        let filteredForgeKnowledge = getForgeKnowledge(knowledge)
        setForgeKnowledge(filteredForgeKnowledge)

        // Separate Knowledge
        setOrgCompanyKnowledge(getKnowledgeByType(filteredForgeKnowledge, KnowledgeType.companyFromDomain))
        setOrgLocationKnowledge(getLocationKnowledge(filteredForgeKnowledge))
        setOrgSummaryKnowledge(getKnowledgeByType(filteredForgeKnowledge, KnowledgeType.summary, true))
        setOrgEducationKnowledge(getKnowledgeByType(filteredForgeKnowledge, KnowledgeType.linkedinEducation, true))
        setOrgExperienceKnowledge(getKnowledgeByType(filteredForgeKnowledge, KnowledgeType.linkedinExperience, true))
        setOrgVolunteerWorkKnowledge(getKnowledgeByType(filteredForgeKnowledge, KnowledgeType.linkedinVolunteerWork, true))
      })
    } else {
      setOrganizationKnowledge([])
      setOrgCompanyKnowledge([])
      setOrgLocationKnowledge([])
      setOrgSummaryKnowledge([])
      setOrgEducationKnowledge([])
      setOrgExperienceKnowledge([])
      setOrgVolunteerWorkKnowledge([])
    }
  }, [isEncryptionInitialized, contact?.ref, userProfileData?.organization?.id, userProfileData?.organization?.pendingMigration])

  useEffect(() => {
    if (contact) {
      ;(async () => {
        try {
          setKnowledgeSummary(undefined)
          const result = await knowledgeApi.getKnowledgeSummary(contact)
          if (result.success) {
            setKnowledgeSummary(result.data?.summary)
          }
        } catch (error) {
          console.warn(error)
        }
      })()
    }
  }, [isEncryptionInitialized, contact?.ref, contact?.questionVars])

  const clearForgeKnowledge = () => {
    setForgeKnowledge([])
    setOrgForgeKnowledge([])
    setCompanyKnowledge([])
    setOrgCompanyKnowledge([])
    setLocationKnowledge([])
    setOrgLocationKnowledge([])
    setSummaryKnowledge(undefined)
    setOrgSummaryKnowledge(undefined)
    setEducationKnowledge(undefined)
    setOrgEducationKnowledge(undefined)
    setExperienceKnowledge(undefined)
    setOrgExperienceKnowledge(undefined)
    setVolunteerWorkKnowledge(undefined)
    setOrgVolunteerWorkKnowledge(undefined)
  }

  const analyzeKnowledge = async (knowledge: Knowledge, saveKnowledge: boolean = false): Promise<any> => {
    let analyzedKnowledge = await knowledgeApi.analyzeKnowledge(contact, knowledge)

    knowledge.analyzing = analyzedKnowledge.analyzing
    knowledge.category = analyzedKnowledge.category
    knowledge.entity = analyzedKnowledge.entity
    knowledge.label = analyzedKnowledge.label
    knowledge.options = analyzedKnowledge.options
    knowledge.optionsSelected = analyzedKnowledge.optionsSelected
    knowledge.searchTerm = analyzedKnowledge.searchTerm
    knowledge.subjects = analyzedKnowledge.subjects
    knowledge.milestone = analyzedKnowledge.milestone
    if (knowledge.milestone) {
      knowledge.milestone.contacts = [contact]
      knowledge.milestone.contactRefs = [contact.ref]
    }

    if (saveKnowledge) {
      let savedKnowledge = await knowledgeApi.createKnowledge(contact, analyzedKnowledge, !contact?.isOrganizationContact)
      knowledge.ref = savedKnowledge.ref
    }

    return knowledge
  }

  const createKnowledge = async (text: string, searchTerms: SearchTerm[]) => {
    let newKnowledge = new Knowledge({
      contactRef: contact.ref,
      answer: text,
      options: searchTerms,
      optionsSelected: searchTerms,
      category: searchTerms.map(e => e.category),
      searchTerm: searchTerms.map(e => e.searchTerm),
      entity: searchTerms.map(e => e.entity),
      label: text,
      subjects: [],
      milestone: null,
      type: KnowledgeType.user,
      visible: true,
      createdBy: memberRef ?? userRef,
      encryptedBy: memberRef ?? userRef
    })

    await knowledgeApi.createKnowledge(contact, newKnowledge, !contact?.isOrganizationContact)
    return true
  }

  const updateKnowledge = async (knowledge: Knowledge, text: string, selectedOptions: SearchTerm[]) => {
    knowledge.answer = text
    knowledge.label = generateKnowledgeLabel(text, selectedOptions)
    knowledge.optionsSelected = selectedOptions
    knowledge.searchTerm = selectedOptions.map((e: any) => e.searchTerm)
    knowledge.entity = selectedOptions.map((e: any) => e.entity)
    knowledge.category = selectedOptions.map((e: any) => e.category)

    await knowledgeApi.updateKnowledge(contact, knowledge, knowledge.isOrganizationKnowledge)
    return true
  }

  const markKnowledgeAsPublic = async (knowledge: Knowledge) => {
    knowledge.isPrivate = false
    await knowledgeApi.updateKnowledgePrivacy(contact, knowledge)
    return true
  }

  const markKnowledgeAsPrivate = async (knowledge: Knowledge) => {
    knowledge.isPrivate = true
    await knowledgeApi.updateKnowledgePrivacy(contact, knowledge)
    return true
  }

  const generateKnowledgeLabel = (label: string, searchTerms: SearchTerm[]) => {
    for (let searchTerm of searchTerms) {
      // Used for matching special chars inside entity and avoid mismatch
      // FIB-306
      // St Simon's Island == St Simons Island
      let entity = searchTerm.entity.split("").join("[\\W_]?").replaceAll(/\s+/g, "")

      // Entity - Singular
      label = label.replaceAll(new RegExp("\\b" + entity + "\\b", "gi"), "{" + searchTerm.entity + "}")

      // Entity - Plurals
      label = label.replaceAll(new RegExp("\\b" + entity + "s\\b", "gi"), "{" + searchTerm.entity + "s}")
    }

    return label
  }

  const deleteKnowledge = async (knowledge: Knowledge) => {
    await knowledgeApi.deleteKnowledge(contact, knowledge)
    return true
  }

  return (
    <KnowledgeContext.Provider
      value={{
        knowledge,
        knowledgeSummary,
        forgeKnowledge: orgForgeKnowledge ?? forgeKnowledge,
        companyKnowledge: orgCompanyKnowledge ?? companyKnowledge,
        locationKnowledge: orgLocationKnowledge ?? locationKnowledge,
        summaryKnowledge: orgSummaryKnowledge ?? summaryKnowledge,
        educationKnowledge: orgEducationKnowledge ?? educationKnowledge,
        experienceKnowledge: orgExperienceKnowledge ?? experienceKnowledge,
        volunteerWorkKnowledge: orgVolunteerWorkKnowledge ?? volunteerWorkKnowledge,
        analyzeKnowledge,
        createKnowledge,
        updateKnowledge,
        deleteKnowledge,
        markKnowledgeAsPublic,
        markKnowledgeAsPrivate
      }}
    >
      {children}
    </KnowledgeContext.Provider>
  )
}
