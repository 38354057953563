import { ExpandMore } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Stack } from "@mui/material"
import MDTypography from "components/MDTypography"
import PermissionTile from "../PermissionTile"

function PermissionGroupTile({ permissionGroup }: { permissionGroup: any }): JSX.Element {
  return (
    <Accordion defaultExpanded style={{ boxShadow: "none" }}>
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
        <MDTypography variant="caption" fontWeight="bold" fontSize="medium" textTransform="capitalize" style={{ color: "#0000008f" }}>
          {permissionGroup.label}
        </MDTypography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="column" spacing={1}>
          {permissionGroup.permissions.map((permission: any) => (
            <PermissionTile permission={permission} />
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default PermissionGroupTile
