import { createContext, Dispatch, SetStateAction, useContext, useRef, useState } from "react"
import { AuthContext } from "context"
import SettingsApi from "./api"
import SettingsFirestore from "./firestore"
import { UserSettings } from "types/user/user-settings"
import { UserEmail } from "types/user/user-email"
import { useGoogleLogin } from "@react-oauth/google"
import { getAuth } from "firebase/auth"

interface SettingsContextType {
  updateBasicInfo: (
    firstName?: string,
    lastName?: string,
    preferredName?: string,
    company?: string,
    linkedInUrl?: string,
    email?: UserEmail[]
  ) => Promise<void>
  updateUserSettings: (userSettings: UserSettings) => Promise<void>
  setEmailToVerify: Dispatch<SetStateAction<UserEmail>>
  verifyGoogleEmail: () => void
  verifyMicrosoftEmail: (onSuccess: () => void) => void
  emailToVerify: UserEmail
  verifyingEmails: boolean
  setVerifyingEmails: Dispatch<SetStateAction<boolean>>
  errorVerifyingEmails: string
  setErrorVerifyingEmails: Dispatch<SetStateAction<string>>
}

export const SettingsContext = createContext<SettingsContextType>({
  updateBasicInfo: async () => {},
  updateUserSettings: async () => {},
  setEmailToVerify: () => {},
  verifyGoogleEmail: () => {},
  verifyMicrosoftEmail: () => {},
  emailToVerify: undefined,
  verifyingEmails: false,
  setVerifyingEmails: () => {},
  errorVerifyingEmails: undefined,
  setErrorVerifyingEmails: () => {}
})

export const SettingsContextProvider = ({ contact, children }: { contact?: any; children: any }) => {
  // Context
  const { getCurrentUser } = useContext(AuthContext)

  // State
  const callbackRef = useRef(false)
  const [emailToVerify, setEmailToVerify] = useState<UserEmail>()
  const [verifyingEmails, setVerifyingEmails] = useState<boolean>(false)
  const [errorVerifyingEmails, setErrorVerifyingEmails] = useState<any>()

  // Services
  const { user, encryptionService, userProfileData } = getCurrentUser()
  const settingsFirestoreService = new SettingsFirestore(user, userProfileData, encryptionService)
  const settingsApi = new SettingsApi(user, userProfileData, encryptionService)

  const updateBasicInfo = async (
    firstName?: string,
    lastName?: string,
    preferredName?: string,
    company?: string,
    linkedInUrl?: string,
    emails?: UserEmail[]
  ): Promise<void> => {
    const data = {
      firstName: firstName ?? userProfileData.firstName,
      lastName: lastName ?? userProfileData.lastName,
      preferredName: preferredName ?? userProfileData.preferredName,
      company: company ?? userProfileData.company,
      linkedInUrl: linkedInUrl ?? userProfileData.linkedInUrl,
      emails: emails ?? userProfileData.emails
    }

    await settingsApi.updateBasicInfo(data)
  }

  const updateUserSettings = async (userSettings: UserSettings): Promise<void> => {
    await settingsFirestoreService.updateUserSettings(userSettings)
  }

  // Google
  const verifyGoogleEmail = useGoogleLogin({
    flow: "auth-code",
    scope: ["openid", "email", "profile"].join(" "),
    ux_mode: "popup",
    redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URL,
    onSuccess: async tokenResponse => {
      try {
        await settingsApi.verifyGoogleEmail(tokenResponse.code, emailToVerify.email)
        emailToVerify.verified = true
        setEmailToVerify(emailToVerify)
      } catch (error: any) {
        console.warn(error)
        if (error?.message) {
          setErrorVerifyingEmails(error.message)
        }
      }

      setVerifyingEmails(false)
    }
  })

  const verifyMicrosoftEmail = async (onSuccess: () => void) => {
    const firebaseAuth = getAuth()
    const token = await firebaseAuth.currentUser.getIdToken()
    if (!token) {
      return
    }

    setVerifyingEmails(true)
    const endpoint = `/user/auth/email/microsoft?token=${token}`
    const popup = window.open(
      `${process.env.REACT_APP_API_URL}${endpoint}`,
      "targetWindow",
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=500, height=550"
    )

    window.addEventListener("message", async event => {
      let apiUrl = process.env.REACT_APP_API_URL
      if (apiUrl.endsWith("/")) {
        apiUrl = apiUrl.slice(0, -1)
      }

      if (event.origin === apiUrl) {
        if (event.data && !callbackRef.current) {
          callbackRef.current = true
          let data = event.data

          if (data.code) {
            popup.close()
            try {
              await settingsApi.verifyMicrosoftEmail(data.code, emailToVerify.email)
              emailToVerify.verified = true
              setEmailToVerify(emailToVerify)
              onSuccess()
            } catch (error: any) {
              console.warn(error)
              if (error?.message) {
                setErrorVerifyingEmails(error.message)
              }
            }

            callbackRef.current = false
          }
        }
      }

      setVerifyingEmails(false)
    })
  }

  return (
    <SettingsContext.Provider
      value={{
        updateBasicInfo,
        updateUserSettings,
        setEmailToVerify,
        verifyGoogleEmail,
        verifyMicrosoftEmail,
        emailToVerify,
        verifyingEmails,
        setVerifyingEmails,
        errorVerifyingEmails,
        setErrorVerifyingEmails
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}
