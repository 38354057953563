export const getLinkedInUrlProperties = (url: string) => {
  if (url) {
    const linkedInUri = new URL(url)

    if (
      linkedInUri &&
      (linkedInUri.host.includes("linkedin.com") || linkedInUri.pathname.includes("linkedin.com")) &&
      linkedInUri.pathname.split("/").filter(Boolean).length &&
      linkedInUri.pathname.split("/").includes("in")
    ) {
      // Clean Empty Segments
      const segments = linkedInUri.pathname.split("/").filter(e => e.trim() !== "")

      // Get index of 'in', next path segment should be LinkedIn Id
      const inIndex = segments.indexOf("in")
      let linkedInId
      if (inIndex !== -1 && segments.length > inIndex + 1) {
        linkedInId = segments[inIndex + 1]
      }

      return {
        linkedInUri,
        segments,
        linkedInId
      }
    }
  }

  return {
    linkedInUri: undefined,
    segments: undefined,
    linkedInId: undefined
  }
}

export const getEmailDomain = (email: string) => email.substring(email.lastIndexOf("@") + 1, email.length)

export const isUrlValid = (url: string): boolean => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i"
  )

  return pattern.test(url)
}

export const validateIfNotCommonDomain = (email: string, commonEmailDomains: string[]): boolean => {
  const domain: string = getEmailDomain(email)
  const secondLevelDomain: string = domain.substring(0, domain.indexOf("."))
  return !commonEmailDomains.some(item => item.includes(secondLevelDomain))
}
