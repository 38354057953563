import { useContext, useEffect, useState } from "react"
import MDBox from "components/MDBox"
import Invoice from "layouts/pages/account/billing/components/Invoice"
import { OrganizationContext } from "forge/organization/services/OrganizationContext"
import { Grid, Typography } from "@mui/material"
import { capitalize } from "lodash"
import { InvoiceContext } from "forge/settings/services/InvoiceContext"
import { AuthContext } from "context"

function SubscriptionDetails(): JSX.Element {
  // State
  let subscription: any
  const [individualPlan, setIndividualPlan] = useState<boolean>(true)
  const [status, setStatus] = useState<string>()
  const [startDate, setStartDate] = useState<number>()
  const [currentPeriodStart, setCurrentPeriodStart] = useState<number>()
  const [currentPeriodEnd, setCurrentPeriodEnd] = useState<number>()
  const [amountOfUsers, setAmountOfUsers] = useState<string>()
  const [planName, setPlanName] = useState<string>()
  const [amount, setAmount] = useState<number>()
  const [currency, setCurrency] = useState<string>()

  // Context
  const { userProfileData } = useContext(AuthContext)
  const { organization } = useContext(OrganizationContext)
  const { invoices } = useContext(InvoiceContext)

  useEffect(() => {
    if (organization?.stripeData?.subscription) {
      subscription = organization?.stripeData.subscription
      setIndividualPlan(false)
    } else if (userProfileData?.stripeData?.subscription) {
      subscription = userProfileData?.stripeData.subscription
      setIndividualPlan(true)
    } else {
      subscription = undefined
    }

    if (subscription) {
      setStatus(subscription.status)
      setStartDate(subscription.start_date * 1000)
      setCurrentPeriodStart(subscription.current_period_start * 1000)
      setCurrentPeriodEnd(subscription.current_period_end * 1000)
      setAmountOfUsers("")
      setPlanName(subscription.plan.nickname)
      setAmount(subscription.plan.amount)
      setCurrency(subscription.plan.currency)
    }
  }, [userProfileData, organization])

  // Helper function to format currency
  const formatCurrency = (amount: number, currency: string) => {
    if (amount && currency) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency
      }).format(amount / 100) // Stripe gives amounts in cents
    }

    return amount?.toString() ?? ""
  }

  return (
    <MDBox pl={3} pr={3} pb={3}>
      <Typography variant="h6" gutterBottom>
        Subscription Details
      </Typography>
      <Grid container spacing={1}>
        {/* Plan Name */}
        <Grid item xs={6}>
          <Typography variant="body2" color="textSecondary">
            Plan Name:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" textAlign="end">
            {planName}
          </Typography>
        </Grid>

        {/* Status */}
        <Grid item xs={6}>
          <Typography variant="body2" color="textSecondary">
            Status:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" color={status === "active" ? "green" : "red"} textAlign="end">
            {capitalize(status)}
          </Typography>
        </Grid>

        {/* Start Date */}
        <Grid item xs={6}>
          <Typography variant="body2" color="textSecondary">
            Start Date:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" textAlign="end">
            {new Date(startDate).toLocaleDateString()}
          </Typography>
        </Grid>

        {/* Expiration Date */}
        <Grid item xs={6}>
          <Typography variant="body2" color="textSecondary">
            Renewal Date:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" textAlign="end">
            {new Date(currentPeriodEnd).toLocaleDateString()}
          </Typography>
        </Grid>

        {/* Amount of Users */}
        {!individualPlan && (
          <Grid item xs={6}>
            <Typography variant="body2" color="textSecondary">
              Users Amount:
            </Typography>
          </Grid>
        )}
        {!individualPlan && (
          <Grid item xs={6}>
            <Typography variant="body2" textAlign="end">
              {amountOfUsers}
            </Typography>
          </Grid>
        )}

        {/* Amount */}
        <Grid item xs={6}>
          <Typography variant="body2" color="textSecondary">
            Amount:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" textAlign="end">
            {formatCurrency(amount, currency)}
          </Typography>
        </Grid>
      </Grid>
      <MDBox height={16} />
      {invoices.length > 0 && (
        <Typography variant="h6" gutterBottom>
          Invoices
        </Typography>
      )}
      <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
        {invoices.map(invoice => (
          <Invoice
            date={new Date(invoice.created * 1000).toLocaleDateString("en-US", {
              month: "long", // Full month name (e.g., "March")
              day: "2-digit", // Day with leading zero (e.g., "01")
              year: "numeric" // Full year (e.g., "2020")
            })}
            id={`#${invoice.number}`}
            price={formatCurrency(invoice.total, invoice.currency)}
            onClick={() => {
              window.open(invoice.invoice_pdf, "_blank")
            }}
          />
        ))}
      </MDBox>
    </MDBox>
  )
}

export default SubscriptionDetails
