import { Stack, Switch } from "@mui/material"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import { useEffect, useState } from "react"
import { ForgeCalendar, intToColor } from "types/calendar/calendar"

function CalendarTile({ calendar, onEnabled }: { calendar: ForgeCalendar; onEnabled?: (enabled: boolean) => void }): JSX.Element {
  // State
  const [enabled, setEnabled] = useState<boolean>(false)

  useEffect(() => {
    setEnabled(calendar.active)
  }, [calendar])

  return (
    <MDBox ml={2} pl={{ xs: 0, sm: 6 }} lineHeight={1}>
      <MDBox
        bgColor={"grey-100"}
        borderRadius="lg"
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "row", sm: "row" }}
        my={2}
        py={1}
        pl={2}
        pr={1}
      >
        <MDBox>
          <MDTypography variant="body2" fontWeight="medium" color="text">
            {calendar.name ?? ""}
          </MDTypography>
          <Stack direction="row" spacing={1} alignItems={"center"}>
            {enabled && <MDBox style={{ width: 16, height: 16, background: intToColor(calendar.color), borderRadius: 4 }} />}
            <MDTypography variant="body2" fontSize="small" color="text">
              {calendar.account ?? calendar.accountName}
            </MDTypography>
            <MDTypography variant="body2" fontSize="small" fontWeight="bold" color="text">
              {!calendar.canWeWriteToIt && "- Read Only"}
            </MDTypography>
          </Stack>
        </MDBox>
        <MDBox mt={{ xs: 1, sm: 0 }}>
          <Switch checked={enabled} onChange={(_, checked) => onEnabled(checked)} />
        </MDBox>
      </MDBox>
    </MDBox>
  )
}

export default CalendarTile
