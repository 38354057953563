import PropTypes from "prop-types"
import { useState, useEffect, useContext } from "react"
import Board from "@asseinfo/react-kanban"

// MUI
import { Card, Stack } from "@mui/material"

// Material Dashboard
import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"

// Components
import CommitmentCard from "../../../commitments/components/CommitmentCard"
import { ForgeEvent } from "types/forge-event"
import { AlertsContext } from "forge/home/services/AlertsContext"
import { Commitment } from "types/commitment"
import { CalendarEvent } from "types/calendar/calendar-event"
import EventCard from "forge/core/components/EventCard"
import MilestoneCard from "forge/milestones/components/MilestoneCard"
import { areSameDay, endOfDay, startOfDay } from "forge/core/utilities"
import { InfoOutlined } from "@mui/icons-material"
import { Milestone } from "types/milestone"
import AlertCard from "../AlertCard"
import dayjs from "dayjs"

function AlertsView() {
  const { alerts, getGroupedAlerts } = useContext(AlertsContext)
  const [data, setData] = useState<{ [key: string]: ForgeEvent[] }>({})

  const dayAfterTomorrow = new Date()
  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2)

  useEffect(() => {
    const alertFlag = localStorage.getItem("alertFlag")
    if (!alertFlag) {
      const now = new Date()
      const endDay = endOfDay(now)
      localStorage.setItem("alertFlag", JSON.stringify({ value: true, expiry: now.getTime() + (endDay.getTime() - now.getTime()) }))
    }
  }, [])

  useEffect(() => {
    setData(getGroupedAlerts())
  }, [alerts])

  useEffect(() => {
    setBoard(getBoards())
  }, [data])

  const getBoards = (): any => {
    const today = startOfDay(new Date())
    let columns = []

    for (let i = 0; i < 7; i++) {
      let id = dayjs(today).format("YYYY-MM-DD")
      today.setDate(today.getDate() + 1)
      let doesAnyDayHaveAlerts = Object.values(data).some(columnData => columnData.length > 0)
      if (doesAnyDayHaveAlerts && data[id] && data[id].length == 0) {
        continue
      }

      columns.push({
        id: id,
        cards: [
          {
            id: id
          }
        ]
      })
    }

    return { columns }
  }
  const [board, setBoard] = useState(getBoards())

  const renderColumn = (id: string) => {
    const idDate = new Date(id)
    const today = startOfDay(new Date())
    const tomorrow = startOfDay(new Date())
    tomorrow.setDate(today.getDate() + 1)

    let title = ""
    if (areSameDay(today, idDate)) {
      title = "Today"
    } else if (areSameDay(tomorrow, idDate)) {
      title = "Tomorrow"
    } else {
      title = idDate.toLocaleDateString("en-US", {
        month: "long",
        weekday: "long",
        day: "numeric"
      })
    }

    const columnData = data[id.split("T")[0]] ?? []

    return (
      <MDBox ml={3}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize" mb={2}>
          {title}
        </MDTypography>

        {columnData.length > 0 ? (
          columnData.map((alert: any) => (
            <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0} sx={{ listStyle: "none" }}>
              {getCard(alert)}
            </MDBox>
          ))
        ) : (
          <Stack m={"auto"} mt={"16vh"} mb={"16vh"} direction="column" alignItems="center" spacing={0.5}>
            <InfoOutlined style={{ color: "darkgray" }} />
            <MDTypography variant="h5" sx={{ fontWeight: "normal" }} style={{ color: "darkgray" }}>
              No alerts found for this day
            </MDTypography>
          </Stack>
        )}
      </MDBox>
    )
  }

  const getCard = (alert: ForgeEvent) => {
    if (alert instanceof CalendarEvent) {
      return <EventCard event={alert} />
    } else if (alert instanceof Commitment) {
      return <CommitmentCard commitment={alert} />
    } else if (alert instanceof Milestone) {
      return <MilestoneCard milestone={alert} />
    } else {
      return <AlertCard alert={alert} />
    }
  }

  return (
    <Card>
      <MDBox p={3} lineHeight={1} display="flex" justifyContent="space-between">
        <MDTypography variant="h5" fontWeight="medium">
          Alerts
        </MDTypography>
      </MDBox>
      <MDBox py={1}>
        <Board
          disableCardDrag={true}
          renderColumnHeader={({ id, title }: any) => <></>}
          renderCard={({ id, template }: any, { dragging }: any) => (
            <MDBox
              key={id}
              dragging={dragging.toString() || undefined}
              display="block"
              width="calc(510px - 40px)"
              color="text"
              borderRadius="xl"
              mt={2.55}
              mr={3}
              pb={1}
              sx={{ height: "100%" }}
            >
              {renderColumn(id)}
            </MDBox>
          )}
        >
          {board}
        </Board>
      </MDBox>
    </Card>
  )
}

// Setting default values for the props of CommitmentCard
AlertsView.defaultProps = {
  commitments: []
}

// Typechecking props for the CommitmentCard
AlertsView.propTypes = {
  commitments: PropTypes.array.isRequired
}

export default AlertsView
