import { useContext, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import theme from "assets/theme"

// Context
import { AuthContext, ContactsContext } from "context"

// @mui material components
import { Tooltip, Avatar, Box, Drawer, useMediaQuery, Card, Tabs, Tab } from "@mui/material"

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import MDButton from "components/MDButton"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "examples/Navbars/DashboardNavbar"
import Footer from "examples/Footer"
import DataTable from "examples/Tables/DataTable"

// Components
import CreateContactDrawer from "./components/CreateContactDrawer"
import { ApartmentRounded } from "@mui/icons-material"
import { ForgeContactAvatar } from "forge/core/components/ForgeAvatar"
import { CustomTabPanel, a11yProps } from "forge/core/components/TabPanel"
import { Timestamp } from "firebase/firestore"
import { RemoteConfigContext } from "forge/core/services/RemoteConfigContext"
import { getWarmthScore } from "types/contact/contact"

function ContactsManagement() {
  // Context
  const { warmthAlgorithm } = useContext(RemoteConfigContext)
  const { contacts } = useContext(ContactsContext)
  const { getCurrentUser } = useContext(AuthContext)
  const { user } = getCurrentUser()

  // Tabs
  const [tabValue, setTabValue] = useState(1)
  const handleSetTabValue = (event: any, newValue: number) => setTabValue(newValue)

  // State
  const [baseContacts, setBaseContacts] = useState([])
  const [tableData, setTableData] = useState([])
  const [openCreateContactDrawer, setOpenCreateContactDrawer] = useState(false)

  const handleOpenDrawer = () => setOpenCreateContactDrawer(true)
  const handleCloseDrawer = () => setOpenCreateContactDrawer(false)

  const navigate = useNavigate()

  const mediumScreen = useMediaQuery(theme.breakpoints.up("sm"))
  const largeScreen = useMediaQuery(theme.breakpoints.up("lg"))
  const xLargeScreen = useMediaQuery(theme.breakpoints.up("xl"))

  useEffect(() => {
    switch (tabValue) {
      case 1:
        // Recent Contacts
        let recent: any[] = [...contacts]
        recent.sort((a, b) => {
          if (a?.lastInteraction && b?.lastInteraction && a?.lastInteraction instanceof Timestamp && b?.lastInteraction instanceof Timestamp) {
            return b.lastInteraction.toMillis() - a.lastInteraction.toMillis()
          }
          return 0
        })
        setBaseContacts(recent)
        break
      case 2:
        // Priority Contacts
        let priority = contacts.filter(e => e?.favorite || e?.favoriteByGoal)
        setBaseContacts(priority)
        break
      default:
        // All Contacts
        setBaseContacts(contacts)
        break
    }
  }, [contacts, tabValue])

  useEffect(() => {
    setTableData(getRows(baseContacts))
  }, [tabValue, baseContacts])

  const clickOverviewHandler = (id: string) => {
    navigate(`/people/relationships/${id}`)
  }

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "2-digit"
  }

  const getRows = (info: any[]) => {
    let updatedInfo = info.map(row => {
      try {
        let score = getWarmthScore(row, warmthAlgorithm, user.uid).get("finalScore")

        return {
          type: "contact",
          id: row.id,
          name: row.name,
          firstName: row.firstName,
          lastName: row.lastName,
          profilePicture: row.linkedInProfileData?.profile_pic_url,
          modified: row.updatedAt?.toMillis(),
          added: row.createdAt?.toMillis(),
          company: row.company,
          companyLogo: "",
          role: row.jobTitle,
          contact: row,
          searchTermsInUse: row.searchTermsInUse,
          warmth: score
        }
      } catch (error) {
        console.warn(error)
        return {}
      }
    })

    return updatedInfo
  }

  const dataTableData = {
    columns: [
      {
        Header: "warmth",
        accessor: "warmth",
        width: "5%",
        // widthSetting: "100%",
        sorted: true,
        // padding: { left: 0, right: 0 },
        // paddingHeader: { left: 0 },
        sortDescFirst: true,
        Cell: (info: any) => {
          return (
            <Tooltip title={info.cell.row.original.name}>
              <ForgeContactAvatar
                contact={info.cell.row.original.contact}
                enableScoreBadge={false}
                warmthRingThickness={4}
                color="white"
                background="black"
              />
            </Tooltip>
          )
        }
      },
      {
        Header: "name",
        accessor: "name",
        width: "20%",
        sorted: true,
        Cell: (info: any) => {
          return <MDBox>{info.cell.row.original.name}</MDBox>
        }
      },
      {
        Header: "organization",
        accessor: "company",
        width: "20%",
        widthSetting: "100%",
        sorted: true,
        Cell: (info: any) => {
          return (
            <MDBox display="flex" alignItems="center">
              <Tooltip title={info.cell.row.original.company}>
                <Avatar
                  variant="rounded"
                  alt={info.cell.row.original.company}
                  src={info.cell.row.original.companyLogo}
                  style={{ background: info.cell.row.original.company ? "black" : "lightgray" }}
                >
                  <ApartmentRounded style={{ color: "white" }} />
                </Avatar>
              </Tooltip>
              {info.cell.row.original.company ? (
                <Box sx={{ ml: 2 }}>{info.cell.row.original.company}</Box>
              ) : (
                <MDTypography variant="p2" color="text" sx={{ ml: 2, color: "lightgray" }}>
                  No Organization
                </MDTypography>
              )}
            </MDBox>
          )
        }
      },
      {
        Header: "role",
        accessor: "role",
        width: "20%",
        widthSetting: "100%",
        sorted: true,
        disableGlobalFilter: true,
        Cell: (info: any) => {
          return info.cell.row.original.role ? (
            <Box>{info.cell.row.original.role}</Box>
          ) : (
            <MDTypography variant="p2" color="text" sx={{ color: "lightgray" }}>
              No Role
            </MDTypography>
          )
        }
      },
      {
        Header: "modified",
        accessor: "modified",
        width: "15%",
        sorted: true,
        disableGlobalFilter: true,
        Cell: (info: any) => {
          return info.cell.row.original.modified ? (
            <Box>{new Date(info.cell.row.original.modified).toLocaleDateString("en-US", options)}</Box>
          ) : (
            <MDTypography variant="p2" color="text" sx={{ color: "lightgray" }}>
              No Modified Date
            </MDTypography>
          )
        }
      },
      {
        Header: "added",
        accessor: "added",
        width: "15%",
        sorted: true,
        disableGlobalFilter: true,
        Cell: (info: any) => {
          return info.cell.row.original.added ? (
            <Box>{new Date(info.cell.row.original.added).toLocaleDateString("en-US", options)}</Box>
          ) : (
            <MDTypography variant="p2" color="text" sx={{ color: "lightgray" }}>
              No Added Date
            </MDTypography>
          )
        }
      },
      {
        Header: "active search terms",
        accessor: "searchTermsInUse",
        width: "0%",
        sorted: false,
        hide: true,
        Cell: (info: any) => {
          return <></>
        }
      }
    ],

    rows: tableData
  }

  return (
    <DashboardLayout>
      <CreateContactDrawer openDrawer={openCreateContactDrawer} handleCloseDrawer={handleCloseDrawer} />
      <MDBox pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox p={3} lineHeight={1} display="flex" justifyContent="space-between">
              <MDTypography variant="h5" fontWeight="medium">
                People
              </MDTypography>
              {/* {ability.can("create", "categories") && ( */}
              <MDButton variant="gradient" color="dark" size="small" type="submit" style={{ textTransform: "none" }} onClick={handleOpenDrawer}>
                + Add Person
              </MDButton>
              {/* )} */}
            </MDBox>
            <Tabs orientation="horizontal" value={tabValue} style={{ marginRight: 24, marginLeft: 24 }} onChange={handleSetTabValue}>
              <Tab label="A-Z" {...a11yProps(0)} />
              <Tab label="Recent" {...a11yProps(1)} />
              <Tab label="Priority" {...a11yProps(2)} />
            </Tabs>
            {/* Kept like this to reset search bar, modify table logic to reset */}
            {tabValue === 0 && (
              <DataTable
                tableId={`contacts${tabValue}`}
                table={dataTableData}
                canSearch={true}
                onRowClick={(row, index) => clickOverviewHandler(row.original.id)}
              />
            )}
            {tabValue === 1 && (
              <DataTable
                tableId={`contacts${tabValue}`}
                table={dataTableData}
                canSearch={true}
                onRowClick={(row, index) => clickOverviewHandler(row.original.id)}
              />
            )}
            {tabValue === 2 && (
              <DataTable
                tableId={`contacts${tabValue}`}
                table={dataTableData}
                canSearch={true}
                onRowClick={(row, index) => clickOverviewHandler(row.original.id)}
              />
            )}
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  )
}

export default ContactsManagement
