import { User } from "firebase/auth"
import { UserProfileData } from "types/user/user-profile-data"
import HttpService from "forge/core/services/http.service"
import { ForgeOrganizationTeam } from "forge/organization/types/team"
import { ForgeEncryption } from "forge/core/services/encryption"

class OrganizationTeamsApi {
  private user: User
  private userProfileData: UserProfileData
  private encryptionService: ForgeEncryption

  constructor(user: User, userProfileData: UserProfileData, encryptionService: ForgeEncryption) {
    this.user = user
    this.userProfileData = userProfileData
    this.encryptionService = encryptionService
  }

  createTeam = async (team: ForgeOrganizationTeam): Promise<any> => {
    if (this.userProfileData.organization?.id) {
      const endpoint = `organizations/${this.userProfileData.organization?.id}/teams`
      return await HttpService.post(endpoint, { team })
    }
  }

  addTeamMember = async (teamId: string, memberId: string): Promise<any> => {
    if (this.userProfileData.organization?.id) {
      const endpoint = `organizations/${this.userProfileData.organization?.id}/teams/${teamId}/members/${memberId}`
      return await HttpService.post(endpoint, {})
    }
  }

  updateTeam = async (teamId: string, team: ForgeOrganizationTeam): Promise<any> => {
    if (this.userProfileData.organization?.id) {
      const endpoint = `organizations/${this.userProfileData.organization?.id}/teams/${teamId}`
      return await HttpService.patch(endpoint, { team: team.toMap(false) })
    }
  }

  deleteTeam = async (teamId: string): Promise<any> => {
    if (this.userProfileData.organization?.id) {
      const endpoint = `organizations/${this.userProfileData.organization?.id}/teams/${teamId}`
      return await HttpService.delete(endpoint)
    }
  }
}

export default OrganizationTeamsApi
