import { createContext, useContext, useEffect, useRef, useState } from "react"
import { AuthContext } from "context"
import { ForgeOrganizationTeam } from "../../types/team"
import OrganizationTeamsFirestore from "./firestore"
import OrganizationTeamsApi from "./api"
import { MembersContext } from "forge/organization/members/services/MembersContext"

interface TeamsContextType {
  teams: ForgeOrganizationTeam[]
  createTeam: (team: ForgeOrganizationTeam) => Promise<void>
  addTeamMember: (teamId: string, memberId: string) => Promise<void>
  updateTeam: (teamId: string, team: ForgeOrganizationTeam) => Promise<void>
  deleteTeam: (teamId: string) => Promise<void>
}

export const TeamsContext = createContext<TeamsContextType>({
  teams: [],
  createTeam: async () => {},
  addTeamMember: async () => {},
  updateTeam: async () => {},
  deleteTeam: async () => {}
})

export const TeamsContextProvider = ({ children }: { children: any }) => {
  // Context
  const { getCurrentUser, isEncryptionInitialized } = useContext(AuthContext)
  const membersContext = useContext(MembersContext)
  const membersContextRef = useRef(membersContext)
  const { user, encryptionService, userProfileData } = getCurrentUser()

  // Services
  const organizationTeamsFirestore = new OrganizationTeamsFirestore(user, userProfileData, encryptionService)
  const organizationTeamsApi = new OrganizationTeamsApi(user, userProfileData, encryptionService)

  // State
  const [teams, setTeams] = useState<ForgeOrganizationTeam[]>([])

  useEffect(() => {
    if (isEncryptionInitialized) {
      if (userProfileData?.doesUserBelongsToAnOrganization) {
        const unsubscribe = organizationTeamsFirestore.getTeamsLive((teams, _) => setTeams(matchMembers(teams)))

        return () => unsubscribe()
      }
    } else {
      setTeams([])
    }
  }, [isEncryptionInitialized, userProfileData?.organization?.id, userProfileData?.organization?.pendingMigration])

  useEffect(() => {
    membersContextRef.current = membersContext // Update the ref whenever the context value changes
    setTeams(matchMembers(teams))
  }, [membersContext.members])

  const matchMembers = (teams: ForgeOrganizationTeam[]): ForgeOrganizationTeam[] => {
    if (membersContextRef.current.members.length > 0) {
      for (const team of teams) {
        if (team.membersRefs?.length > 0) {
          team.members = membersContextRef.current.getMembers(team.membersRefs.map(ref => ref.id))
        }

        team.createdBy = membersContextRef.current.getMember(team.createdByRef?.id)
      }
    }

    return teams
  }

  const createTeam = async (team: ForgeOrganizationTeam): Promise<void> => {
    return await organizationTeamsApi.createTeam(team)
  }

  const addTeamMember = async (teamId: string, memberId: string): Promise<void> => {
    return await organizationTeamsApi.addTeamMember(teamId, memberId)
  }

  const updateTeam = async (teamId: string, team: ForgeOrganizationTeam): Promise<void> => {
    return await organizationTeamsApi.updateTeam(teamId, team)
  }

  const deleteTeam = async (teamId: string): Promise<void> => {
    return await organizationTeamsApi.deleteTeam(teamId)
  }

  return (
    <TeamsContext.Provider
      value={{
        teams,
        createTeam,
        addTeamMember,
        updateTeam,
        deleteTeam
      }}
    >
      {children}
    </TeamsContext.Provider>
  )
}
