import { Card, Divider, FormControl, TextField, Tooltip } from "@mui/material"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import ExperienceTile from "../ExperienceTile"
import EducationTile from "../EducationTile"
import {
  BusinessCenterOutlined,
  FlagCircle,
  InfoRounded,
  LinkedIn,
  LocationOn,
  SchoolOutlined,
  SummarizeOutlined,
  VolunteerActivismOutlined
} from "@mui/icons-material"
import { createUseStyles } from "react-jss"
import VolunteerWorkTile from "../VolunteerWorkTile"
import LocationTile from "../LocationTile"
import { useContext, useEffect, useState } from "react"
import { AuthContext } from "context"
import { KnowledgeContext } from "forge/knowledge/services/KnowledgeContext"
import { parseKnowledge } from "forge/core/knowledgeParsing"
import { LinkedInProfileContext } from "../../services/LinkedInProfileContext"
import LinkedInOptionCard from "../LinkedInOptionCard"
import { LinkedInProfileOption } from "types/contact/linkedin-profile-option"
import ForgeLoading from "forge/shared/ForgeLoading/ForgeLoading"
import ForgeCta from "forge/core/components/ForgeCta"
import { CARDS_TOOLTIPS } from "../../utils/contacts-constants"
import CardTitle from "../CardTitle"
import ConfirmationDialog from "forge/core/components/ConfirmationDialog"
import ContactsApi from "../../services/api"
import { getLinkedInUrlProperties } from "../../utils"
import { triggerToastNotification } from "forge/shared/utils"

const styles = createUseStyles({
  lightGray: { color: "darkgray" },
  tooltipSx: { fontSize: "20px !important" }
})

function ContactResearchCard({
  contact,
  handleOpenDetailDrawer
}: {
  contact: any
  handleOpenDetailDrawer: (knowledge: any, linkedInData?: any) => void
}) {
  const classes = styles()

  // Context
  const { companyKnowledge, locationKnowledge, summaryKnowledge, educationKnowledge, experienceKnowledge, volunteerWorkKnowledge } =
    useContext(KnowledgeContext)
  const { getCurrentUser } = useContext(AuthContext)
  const { profileOptions, selectProfile, setLinkedInUrl } = useContext(LinkedInProfileContext)

  // Services
  let { user, userSettings, encryptionService, userProfileData } = getCurrentUser()
  const contactsApi = new ContactsApi(user, userProfileData, encryptionService)

  // State
  const [loading, setLoading] = useState<boolean>(false)
  const [url, setUrl] = useState<string>()
  const [reportReason, setReportReason] = useState<string>()
  const [openDialogReport, setOpenDialogReport] = useState(false)
  const handleOpenDialogReport = () => setOpenDialogReport(true)

  useEffect(() => {
    setUrl("")
  }, [contact?.ref])

  function lacksData() {
    return (
      !(contact?.linkedInProfileData?.openai_summary?.text && contact?.linkedInProfileData?.openai_summary?.text.length > 0) &&
      !(companyKnowledge && companyKnowledge.length > 0) &&
      !(locationKnowledge && locationKnowledge.length > 0) &&
      !(summaryKnowledge && summaryKnowledge.length > 0) &&
      !(educationKnowledge && educationKnowledge.length > 0) &&
      !(experienceKnowledge && experienceKnowledge.length > 0) &&
      !(volunteerWorkKnowledge && volunteerWorkKnowledge.length > 0)
    )
  }

  function headerQuestion() {
    if (contact?.forgeInsightsState?.findingLinkedInProfiles) {
      return `We're looking for ${contact.firstName}'s LinkedIn Profile,\nplease wait a moment...`
    }

    if (contact?.linkedInUrl) {
      if (lacksData()) {
        if (contact?.forgeInsightsState?.settingLinkedInProfile) {
          return `Researching ${contact.firstName}'s LinkedIn Profile,\nplease wait a moment...`
        } else if (contact?.forgeInsightsState?.findingLinkedInProfiles) {
          return `We're looking for ${contact.firstName}'s LinkedIn Profile,\nplease wait a moment...`
        } else {
          return null
        }
      } else {
        return `What do you have in common with ${contact.firstName}?`
      }
    }
    if (profileOptions.length === 0) return `We couldn't find ${contact.firstName}'s profile. Paste the URL here.`
    return `Which LinkedIn Profile is ${contact.firstName}'s, if any?`
  }

  function renderBody() {
    if (!contact || contact?.forgeInsightsState?.findingLinkedInProfiles) {
      return <ForgeLoading loading loadingType="inline" style={{ width: "40%", marginLeft: "auto", marginRight: "auto" }} />
    }

    if (contact?.linkedInUrl) {
      if (lacksData()) {
        if (contact?.forgeInsightsState?.settingLinkedInProfile || contact?.forgeInsightsState?.findingLinkedInProfiles) {
          return <ForgeLoading loading loadingType="inline" style={{ width: "40%", marginLeft: "auto", marginRight: "auto" }} />
        } else {
          // Visit Profile
          return <ForgeCta cta="Visit LinkedIn Profile" icon={<LinkedIn />} onClick={() => window.open(contact?.linkedInUrl, "_blank")} />
        }
      } else {
        return <></>
      }
    }

    return (
      <>
        {profileOptions.length > 0 && (
          <>
            {profileOptions?.map(option => (
              <LinkedInOptionCard key={option?.formattedUrl} linkedInOption={option} onClick={() => onLinkedInOptionSelected(option)} />
            ))}
          </>
        )}

        <FormControl style={{ width: "100%" }} component="form" onSubmit={handleSubmit}>
          <TextField
            id="linkedIn"
            label="LinkedIn URL"
            variant="outlined"
            InputLabelProps={{ style: { color: "gray" } }}
            InputProps={{
              endAdornment: (
                <Tooltip
                  title={`Navigate to the LinkedIn profile you want to copy. Once on the profile page, look at the browser's address bar at the top, where you'll see the URL of the profile. Click on the address bar to highlight the entire URL, then right-click and select "Copy," or simply press Ctrl+C on your keyboard. You can now paste the copied URL.`}
                  placement="bottom"
                >
                  <InfoRounded fontSize="small" />
                </Tooltip>
              )
            }}
            value={url}
            onChange={e => setUrl(e.target.value)}
          />
        </FormControl>
      </>
    )
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    setLoading(true)

    if (url) {
      await setLinkedInUrl(url)
    }

    setLoading(false)
  }

  const onLinkedInOptionSelected = (linkedInOption: LinkedInProfileOption) => {
    selectProfile(linkedInOption)
  }

  const handleCloseDialogReport = async (result: boolean) => {
    setOpenDialogReport(false)
    if (result) {
      const { linkedInId } = getLinkedInUrlProperties(contact.linkedInUrl)
      const requestResult = await contactsApi.reportLinkedInSummary(linkedInId, reportReason)

      if (requestResult) {
        triggerToastNotification({
          notificationMessage: "Thanks for the report, we're let the admins know to start a review",
          notificationType: "success",
          customTimer: 3500
        })
        setReportReason(undefined)
      } else
        triggerToastNotification({
          notificationMessage: "There was an error submitting your report, please try again.",
          notificationType: "error",
          customTimer: 3500
        })
    }
  }

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox m={3}>
        <CardTitle title="Research" tooltipInfo={CARDS_TOOLTIPS.RESEARCH} />
        <ConfirmationDialog
          openDialog={openDialogReport}
          handleCloseDialog={handleCloseDialogReport}
          title="Are you sure this is something that you want to report?"
          description="If that's the case include a reason behind reporting"
          confirmText="Report"
          denyVariant="outlined"
          extraComponent={
            <TextField
              style={{ width: "100%", marginTop: "15px" }}
              id="outlined-multiline-static"
              label="Report reason"
              multiline
              rows={4}
              value={reportReason}
              onChange={e => setReportReason(e.target.value)}
            />
          }
        />
        <Divider style={{ opacity: 1 }} />

        {contact?.firstName && (
          <MDTypography variant="h6" textAlign="center" mb={1.5}>
            {headerQuestion()}
          </MDTypography>
        )}
        {!contact?.linkedInProfileData?.openai_summary?.moderationFlagged &&
          userSettings?.enableAiCareerSummaries &&
          contact?.linkedInProfileData?.openai_summary?.text &&
          contact?.linkedInProfileData?.openai_summary?.text.length > 0 && (
            <MDBox>
              <MDBox display="flex" alignItems="center" pr={0} lineHeight={1}>
                <SummarizeOutlined className={classes.lightGray} />
                <MDBox width={"385px"}>
                  <MDTypography variant="h6" ml={1} className={classes.lightGray}>
                    {`${contact.firstName}'s Career Summary`}
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <Tooltip
                    title={`In case you see something that might be kind of offensive, you can report to Forge team by clicking this button.`}
                    placement="bottom"
                    onClick={handleOpenDialogReport}
                  >
                    <FlagCircle className={classes.tooltipSx} />
                  </Tooltip>
                </MDBox>
              </MDBox>
              <MDTypography
                variant="body2"
                color="text"
                textAlign="justify"
                style={{ cursor: "pointer" }}
                onClick={() => handleOpenDetailDrawer(summaryKnowledge)}
              >
                {parseKnowledge(summaryKnowledge, contact?.linkedInProfileData?.openai_summary?.text)}
              </MDTypography>
              <Divider variant="middle" />
            </MDBox>
          )}

        {locationKnowledge && locationKnowledge.length > 0 && (
          <MDBox>
            <MDBox display="flex" alignItems="center" pr={3} lineHeight={1} mb={1}>
              <LocationOn className={classes.lightGray} />
              <MDTypography variant="h6" ml={1} className={classes.lightGray}>
                Location
              </MDTypography>
            </MDBox>

            {locationKnowledge.map((e: any) => (
              <LocationTile key={e.id} location={e} onClick={() => handleOpenDetailDrawer(e)} />
            ))}

            <Divider variant="middle" />
          </MDBox>
        )}

        {/* Education */}
        {contact?.linkedInProfileData?.education && contact?.linkedInProfileData?.education.length > 0 && (
          <MDBox>
            <MDBox display="flex" alignItems="center" pr={3} lineHeight={1} mb={1}>
              <SchoolOutlined className={classes.lightGray} />
              <MDTypography variant="h6" ml={1} className={classes.lightGray}>
                Education
              </MDTypography>
            </MDBox>

            {contact?.linkedInProfileData?.education?.map((e: any) => (
              <EducationTile key={e.id} education={e} knowledge={educationKnowledge} onClick={() => handleOpenDetailDrawer(educationKnowledge)} />
            ))}

            <Divider variant="middle" />
          </MDBox>
        )}

        {/* Experience  */}
        {contact?.linkedInProfileData?.experiences && contact?.linkedInProfileData?.experiences.length > 0 && (
          <MDBox>
            <MDBox display="flex" alignItems="center" pr={3} lineHeight={1} mb={1}>
              <BusinessCenterOutlined className={classes.lightGray} />
              <MDTypography variant="h6" ml={1} className={classes.lightGray}>
                Experience
              </MDTypography>
            </MDBox>

            {companyKnowledge.map((e: any) => (
              <ExperienceTile key={e.id} knowledge={e} onClick={() => handleOpenDetailDrawer(e)} />
            ))}

            {contact?.linkedInProfileData?.experiences?.map((e: any) => (
              <ExperienceTile key={e.id} experience={e} knowledge={experienceKnowledge} onClick={() => handleOpenDetailDrawer(experienceKnowledge)} />
            ))}

            <Divider variant="middle" />
          </MDBox>
        )}

        {/* Volunteer Work   */}
        {contact?.linkedInProfileData?.volunteer_work && contact?.linkedInProfileData?.volunteer_work.length > 0 && (
          <MDBox>
            <MDBox display="flex" alignItems="center" pr={3} lineHeight={1} mb={1}>
              <VolunteerActivismOutlined className={classes.lightGray} />
              <MDTypography variant="h6" ml={1} className={classes.lightGray}>
                Volunteer Work
              </MDTypography>
            </MDBox>

            {contact?.linkedInProfileData?.volunteer_work?.map((e: any) => (
              <VolunteerWorkTile
                key={e.id}
                volunteerWork={e}
                knowledge={volunteerWorkKnowledge}
                onClick={() => handleOpenDetailDrawer(volunteerWorkKnowledge)}
              />
            ))}
          </MDBox>
        )}

        {renderBody()}
      </MDBox>
    </Card>
  )
}

export default ContactResearchCard
