import React, { ReactNode } from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS, INLINES, type Document } from "@contentful/rich-text-types"
import { Link } from "@mui/material"
import MDTypography from "components/MDTypography"
interface MarkDownProps {
  source: Document
  isRichText?: boolean
  isBlackBackground?: boolean
}
const Markdown = ({ source, isRichText, isBlackBackground = false }: MarkDownProps) => {
  if (!isRichText) return null
  if (typeof source === "string") return source

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_: unknown, children: React.ReactNode) => (
        <MDTypography style={{ color: isBlackBackground ? "white" : "black" }}>{children}</MDTypography>
      ),
      [BLOCKS.HEADING_1]: (_: unknown, children: React.ReactNode) => (
        <MDTypography variant="h1" style={{ color: isBlackBackground ? "white" : "black" }}>
          {children}
        </MDTypography>
      ),
      [BLOCKS.HEADING_3]: (_: unknown, children: React.ReactNode) => (
        <MDTypography variant="h3" style={{ color: isBlackBackground ? "white" : "black" }}>
          {children}
        </MDTypography>
      ),
      [INLINES.HYPERLINK]: (node: any, children: any) => (
        <Link href={node.data.uri} color="primary" underline="always">
          {children}
        </Link>
      )
    },
    renderMark: {
      [MARKS.BOLD]: (text: ReactNode) => (
        <MDTypography style={{ color: isBlackBackground ? "white" : "black" }} fontWeight="bold">
          {text}
        </MDTypography>
      )
    }
  }
  return <div>{documentToReactComponents(source, options)}</div>
}
export default Markdown
