import { Accordion, AccordionDetails, AccordionSummary, Card, Divider, IconButton, List, Stack } from "@mui/material"
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import { AddIcCall, ArrowForwardIos, Event, ExpandMore } from "@mui/icons-material"
import { useContext, useEffect, useState } from "react"
import { CallsContext } from "forge/pipeline/calls/services/CallsContext"
import { Call } from "types/pipeline/call"
import CallCard from "forge/pipeline/opportunities/components/CallCard"
import { orderCalls, smartTime, startOfDay } from "forge/core/utilities"
import { EventsContext } from "context"
import { ForgeEvent } from "types/forge-event"
import { CalendarEvent } from "types/calendar/calendar-event"
import EventCard from "forge/core/components/EventCard"
import { CARDS_TOOLTIPS } from "../../utils/contacts-constants"
import CardTitle from "../CardTitle"
import { createUseStyles } from "react-jss"
import useSortDirection from "forge/shared/hooks/useSortDirection"

const styles = createUseStyles({
  root: {
    "&:before": {
      display: "none"
    }
  },
  accordionDetailsRoot: {
    "& .MuiAccordionDetails-root": {
      padding: 0
    }
  }
})

function ContactCallsCard({
  contact,
  handleOpenDrawer,
  handleOpenEventDrawer
}: {
  contact: any
  handleOpenDrawer: () => void
  handleOpenEventDrawer: () => void
}) {
  const classes = styles()
  // Context
  const { calls } = useContext(CallsContext)
  const { events, getFutureEventsForContact, getCurrentAndPreviousEvents } = useContext(EventsContext)

  // State
  const [expanded, setExpanded] = useState<boolean>(false)
  const [areCallsToDisplay, setAreCallsToDisplay] = useState<boolean>(false)
  const [contactCalls, setContactCalls] = useState([])
  const [contactEvents, setContactEvents] = useState([])
  const [contactPreviousEvents, setContactPreviousEvents] = useState([])
  const [orderPreviousEvents, setOrderPreviousEvents] = useState([])
  const [sections, setSections] = useState([])
  const { sortOption, handleSortChange } = useSortDirection()

  useEffect(() => {
    setContactCalls(calls)
  }, [calls])

  useEffect(() => {
    if (contact?.ref?.id) {
      setContactEvents(getFutureEventsForContact([contact.ref.id]))
      setContactPreviousEvents(getCurrentAndPreviousEvents([contact.ref.id]))
    }
  }, [events, contact?.ref?.id])

  useEffect(() => {
    setSections(orderCalls([...contactCalls, ...contactEvents], sortOption))
    setOrderPreviousEvents(orderCalls([...contactPreviousEvents], sortOption))
    setAreCallsToDisplay(sections.length > 0 || contactPreviousEvents.length > 0)
  }, [contactCalls, contactEvents, contactPreviousEvents, sortOption])

  const NewEventButton = (
    <MDBox
      width="3rem"
      height="3rem"
      borderRadius="md"
      display="flex"
      justifyContent="center"
      alignItems="center"
      shadow="md"
      style={{ cursor: "pointer", background: "cornflowerblue", marginLeft: areCallsToDisplay ? "5px" : "0" }}
      onClick={handleOpenEventDrawer}
    >
      <IconButton aria-label="add-knowledge" style={{ padding: 0 }} onClick={handleOpenEventDrawer}>
        <Event style={{ color: "white" }} />
      </IconButton>
    </MDBox>
  )

  const NewLogCallButton = (
    <MDBox
      width="3rem"
      height="3rem"
      borderRadius="md"
      display="flex"
      justifyContent="center"
      alignItems="center"
      shadow="md"
      style={{ cursor: "pointer", background: "cornflowerblue" }}
      onClick={handleOpenDrawer}
    >
      <IconButton aria-label="add-knowledge" style={{ padding: 0 }} onClick={handleOpenDrawer}>
        <AddIcCall style={{ color: "white" }} />
      </IconButton>
    </MDBox>
  )

  const renderEventsList = (events: any[]) => {
    return (
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper"
        }}
      >
        {events?.map((e, index) => (
          <MDBox key={index}>
            <MDTypography fontSize="small" align="center" style={{ color: "gray", marginTop: 1.5, marginBottom: 8 }}>
              {e.header}
            </MDTypography>
            {e?.items?.map((event: any) => {
              if (event instanceof Call) {
                return <CallCard call={event} />
              } else if (event instanceof CalendarEvent) {
                return <EventCard event={event} />
              } else return <></>
            })}
          </MDBox>
        ))}
      </List>
    )
  }

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox m={3}>
        <CardTitle
          title="Conversations"
          tooltipInfo={CARDS_TOOLTIPS.CONVERSATIONS}
          reactButton={
            areCallsToDisplay && (
              <>
                {NewLogCallButton}
                {NewEventButton}
              </>
            )
          }
          hasSortAbility
          handleSort={() => handleSortChange(sortOption)}
        />
        <Divider style={{ opacity: 1 }} />
        {areCallsToDisplay ? (
          <>
            {orderPreviousEvents.length > 0 && (
              <Accordion
                TransitionProps={{ unmountOnExit: true }}
                className={classes.root}
                style={{ marginBottom: "8px", boxShadow: "none" }}
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}
              >
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header">
                  <MDTypography variant="body2">{`Previous meetings`}</MDTypography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetailsRoot}>{renderEventsList(orderPreviousEvents)}</AccordionDetails>
              </Accordion>
            )}
            {sections && sections.length > 0 && <Divider style={{ opacity: 1 }} />}
            {renderEventsList(sections)}
          </>
        ) : (
          <>
            <Stack mt={3} mb={3} direction="row" alignSelf={"center"} justifyContent={"center"} alignItems="center" spacing={0.5}>
              <MDTypography
                variant="body2"
                align="center"
                style={{ color: "darkgray" }}
              >{`Add any conversation\nyou've had with ${contact?.firstName}?`}</MDTypography>
              <ArrowForwardIos style={{ color: "darkgray", height: "0.75em" }} />
              {NewLogCallButton}
            </Stack>
            <Stack mt={3} mb={3} direction="row" alignSelf={"center"} justifyContent={"center"} alignItems="center" spacing={0.5}>
              <MDTypography
                variant="body2"
                align="center"
                style={{ color: "darkgray" }}
              >{`Schedule a meeting with ${contact?.firstName}`}</MDTypography>
              <ArrowForwardIos style={{ color: "darkgray", height: "0.75em" }} />
              {NewEventButton}
            </Stack>
          </>
        )}
      </MDBox>
    </Card>
  )
}

export default ContactCallsCard
