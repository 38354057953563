import { DocumentReference, DocumentSnapshot, Timestamp } from "firebase/firestore"
import { ForgeOrganizationTeam } from "./team"

const options: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "2-digit"
}
export class ForgeOrganizationMember {
  ref: DocumentReference
  email: string
  firstName: string
  lastName?: string
  sealdId?: string
  profilePictureUrl?: string
  role: string
  status: string
  teams: ForgeOrganizationTeam[]
  teamsRefs: DocumentReference[]
  joinedAt: Date
  updatedAt: Date

  // eslint-disable-next-line
  constructor({
    ref,
    email,
    firstName,
    lastName,
    sealdId,
    profilePictureUrl,
    role,
    status,
    teams,
    teamsRefs,
    joinedAt,
    updatedAt
  }: {
    ref?: DocumentReference
    email: string
    firstName: string
    lastName?: string
    sealdId?: string
    profilePictureUrl?: string
    role: string
    status?: string
    teams?: ForgeOrganizationTeam[]
    teamsRefs?: DocumentReference[]
    joinedAt?: Date
    updatedAt?: Date
  }) {
    this.ref = ref
    this.email = email
    this.firstName = firstName
    this.lastName = lastName
    this.sealdId = sealdId
    this.profilePictureUrl = profilePictureUrl
    this.role = role
    this.status = status
    this.teams = teams ?? []
    this.teamsRefs = teamsRefs ?? []
    this.joinedAt = joinedAt
    this.updatedAt = updatedAt
  }

  get name(): string {
    return (this.firstName + " " + this.lastName).trim()
  }

  get initials(): string {
    let result = ""
    if (this.firstName) {
      result += this.firstName[0]
    }
    if (this.lastName) {
      result += this.lastName[0]
    }
    return result.toUpperCase()
  }

  get joinedAtLocalized(): string {
    return this.joinedAt.toLocaleDateString("en-US", options)
  }

  static fromFirestore(doc: DocumentSnapshot): ForgeOrganizationMember {
    const data = doc.data()
    return new ForgeOrganizationMember({
      ref: doc.ref,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      sealdId: data.sealdId,
      profilePictureUrl: data.profilePictureUrl,
      role: data.role,
      status: data.status,
      teamsRefs: data.teamsRefs ?? [],
      joinedAt: data.joinedAt != null ? (data.joinedAt instanceof Timestamp ? data.joinedAt.toDate() : new Date(data.joinedAt)) : undefined,
      updatedAt: data.updatedAt != null ? (data.updatedAt instanceof Timestamp ? data.updatedAt.toDate() : new Date(data.updatedAt)) : undefined
    })
  }
}
