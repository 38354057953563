// TableContext.tsx
import { createContext, useState, useEffect, ReactNode } from "react"

interface TableState {
  data: any[]
  currentPage: number
  filters: Record<string, any>
}

interface TablesState {
  [key: string]: TableState
}

interface TableContextProps {
  tablesState: TablesState
  updateTableState: (tableId: string, newState: Partial<TableState>) => void
}

const TableContext = createContext<TableContextProps | undefined>(undefined)

const TableProvider = ({ children }: { children: any }) => {
  const [tablesState, setTablesState] = useState<TablesState>(() => {
    const savedState = localStorage.getItem("tablesState")
    return savedState ? JSON.parse(savedState) : {}
  })

  useEffect(() => {
    localStorage.setItem("tablesState", JSON.stringify(tablesState))
  }, [tablesState])

  const updateTableState = (tableId: string, newState: Partial<TableState>) => {
    setTablesState(prevState => ({
      ...prevState,
      [tableId]: {
        ...prevState[tableId],
        ...newState
      }
    }))
  }

  return <TableContext.Provider value={{ tablesState, updateTableState }}>{children}</TableContext.Provider>
}

export { TableContext, TableProvider }
