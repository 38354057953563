import { User } from "firebase/auth"
import { UserProfileData } from "types/user/user-profile-data"
import { ForgeOrganization } from "../types/organization"
import HttpService from "forge/core/services/http.service"
import { ForgeEncryption } from "forge/core/services/encryption"

class OrganizationApi {
  private user: User
  private userProfileData: UserProfileData
  private encryptionService: ForgeEncryption

  constructor(user: User, userProfileData: UserProfileData, encryptionService: ForgeEncryption) {
    this.user = user
    this.userProfileData = userProfileData
    this.encryptionService = encryptionService
  }

  getCompanyFromDomain = async (domain: string): Promise<any> => {
    const endpoint = `organizations/company-from-domain`
    return await HttpService.get(endpoint, { domain: domain })
  }

  createOrganization = async (organization: ForgeOrganization): Promise<any> => {
    const endpoint = `organizations`
    return await HttpService.post(endpoint, { organization: organization.toMap(false) })
  }

  updateOrganization = async (organization: ForgeOrganization): Promise<any> => {
    if (this.userProfileData.organization?.id) {
      const endpoint = `organizations/${this.userProfileData.organization?.id}`
      return await HttpService.patch(endpoint, { organization: organization.toMap(false) })
    }
  }

  deleteOrganization = async (): Promise<any> => {
    if (this.userProfileData.organization?.id) {
      const endpoint = `organizations/${this.userProfileData.organization?.id}`
      return await HttpService.delete(endpoint)
    }
  }
}

export default OrganizationApi
