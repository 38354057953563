import { Avatar, Chip, Menu, MenuItem, Stack } from "@mui/material"
import { EdgeLabelRenderer, Handle, Position } from "@reactflow/core"
import MDTypography from "components/MDTypography"
import ContactCard from "forge/core/components/ContactCard"
import { useContext, useEffect, useState } from "react"
import { BaseEdge, getStraightPath } from "reactflow"
import { getColorWithGradient, getWarmthScore } from "types/contact/contact"
import { ForgeOpportunity, opportunityClientRoles, opportunityTeamRoles } from "types/pipeline/opportunity"
import { OpportunityContext } from "../../services/OpportunityContext"

// Images
import ForgeLoading from "forge/shared/ForgeLoading/ForgeLoading"
import { RemoteConfigContext } from "forge/core/services/RemoteConfigContext"
import { AuthContext } from "context"
import ContactWarmthDialog from "forge/people/contacts/components/ContactWarmthDialog"

export function OpportunityContactCard({ data }: { data: any }) {
  // Context
  const opportunityContext = useContext(OpportunityContext)

  // State
  const [contact, setContact] = useState<any>()
  const [opportunity, setOpportunity] = useState<ForgeOpportunity>()
  const [role, setRole] = useState<string>()
  const [type, setType] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = async (newRole: string) => {
    setAnchorEl(null)

    if (newRole) {
      setLoading(true)

      setRole(newRole)
      await opportunityContext.setContactRole(contact, newRole, role)

      setLoading(false)
    }
  }

  useEffect(() => {
    if (data) {
      setContact(data.contact)
      setOpportunity(data.opportunity)
      setRole(data.opportunity?.contactsRoles[data.contact?.id])
      setType(data.type)
    }
  }, [])

  return (
    <div style={{ width: "100%" }}>
      {type === "client" ? <Handle type="target" position={Position.Right} /> : <Handle type="source" position={Position.Left} />}
      <div>
        {contact && (
          <ContactCard
            contact={contact}
            isFavoriteEnabled={false}
            isWarmthRingEnabled={false}
            enableScoreBadge={false}
            maxOneLine={true}
            width="220px"
            subtitle={
              <Stack direction="row" alignItems="center">
                <Chip
                  label={role ?? "[  Select role  ]"}
                  size="small"
                  style={{ overflow: "visible" }}
                  sx={{
                    width: loading ? "75%" : "100%",
                    "& .MuiChip-label": {
                      overflow: loading ? "hidden" : "visible",
                      textOverflow: "ellipsis"
                    }
                  }}
                  onClick={e => handleClick(e)}
                />
                {loading ? <ForgeLoading loading loadingType="small" style={{ margin: "0px", width: "25%" }} /> : <></>}
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => handleClose(undefined)}
                  MenuListProps={{
                    "aria-labelledby": "basic-button"
                  }}
                >
                  {type == "client"
                    ? opportunityClientRoles.map(role => <MenuItem onClick={() => handleClose(role)}>{role}</MenuItem>)
                    : opportunityTeamRoles.map(role => <MenuItem onClick={() => handleClose(role)}>{role}</MenuItem>)}
                </Menu>
              </Stack>
            }
          />
        )}
      </div>
    </div>
  )
}

export function Title({ data }: { data: any }) {
  return (
    <MDTypography variant="h6" textTransform="capitalize" textAlign={"center"} mb={2}>
      {data}
    </MDTypography>
  )
}

export function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  data
}: {
  id: string
  sourceX: number
  sourceY: number
  targetX: number
  targetY: number
  data?: any
}) {
  const [relationshipColor, setRelationshipColor] = useState("#000")

  // Context
  const { warmthAlgorithm } = useContext(RemoteConfigContext)
  const { getCurrentUser } = useContext(AuthContext)
  const { user } = getCurrentUser()

  // State
  const [score, setScore] = useState<number>(0)
  const [openWarmthDialog, setOpenWarmthDialog] = useState<boolean>(false)
  const handleClose = () => setOpenWarmthDialog(false)

  useEffect(() => {
    if (data?.enableColor) {
      const warmthScore = getWarmthScore(data?.contact, warmthAlgorithm, user.uid).get("finalScore")
      setScore(warmthScore)
      setRelationshipColor(getColorWithGradient(warmthScore))
    }
  }, [])

  const [edgePath, labelX, labelY] = getStraightPath({
    sourceX,
    sourceY,
    targetX,
    targetY
  })

  const onEdgeClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    setOpenWarmthDialog(true)
  }

  return (
    <>
      <ContactWarmthDialog
        open={openWarmthDialog}
        onClose={handleClose}
        userContact={data?.owner}
        contact={data?.contact}
        isOwnUser={true}
        relationshipScore={score}
        relationshipColor={relationshipColor}
      />

      <BaseEdge id={`${id}-border`} path={edgePath} style={{ strokeWidth: 4, stroke: "black" }} />
      {data?.enableColor && <BaseEdge id={id} path={edgePath} style={{ strokeWidth: 9, stroke: relationshipColor }} />}
      <EdgeLabelRenderer>
        <Avatar
          style={{
            width: "20px",
            height: "20px",
            position: "absolute",
            zIndex: "99",
            background: data?.enableColor ? relationshipColor : "black",
            color: score >= 20 && score < 80 ? "black" : "white",
            fontSize: "9px",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            pointerEvents: "all"
          }}
          onClick={e => onEdgeClick(e)}
          className="button-edge__label nodrag nopan"
        >
          {score}
        </Avatar>
      </EdgeLabelRenderer>
    </>
  )
}
