import { Avatar, Box, Card, Tooltip } from "@mui/material"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import MDTypography from "components/MDTypography"
import DataTable from "examples/Tables/DataTable"
import { useContext, useEffect, useState } from "react"
import RoleDrawer from "../RoleDrawer"
import { MembersContext } from "forge/organization/members/services/MembersContext"
import { ForgeOrganizationMember } from "forge/organization/types/member"

function OrganizationRoles() {
  // Context
  const { members, getMembersByRole } = useContext(MembersContext)

  // State
  const [tableData, setTableData] = useState([])
  const [selectedRole, setSelectedRole] = useState()
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const handleOpenDrawer = () => setOpenDrawer(true)
  const handleCloseDrawer = () => setOpenDrawer(false)

  useEffect(() => {
    setTableData(
      getRows([
        {
          type: "role",
          id: "admin",
          name: "Admin",
          description: "Admin description",
          members: getMembersByRole("admin")
        },
        {
          type: "role",
          id: "manager",
          name: "Manager",
          description: "Manager description",
          members: getMembersByRole("manager")
        },
        {
          type: "role",
          id: "representative",
          name: "Representative",
          description: "Representative description",
          members: getMembersByRole("representative")
        }
      ])
    )
  }, [members])

  const getRows = (info: any[]) => {
    let updatedInfo = info.map(row => {
      return {
        type: "role",
        id: row.id,
        name: row.name,
        description: row.description,
        members: row.members,
        membersAmount: row.members.length
      }
    })
    return updatedInfo
  }

  const dataTableData = {
    columns: [
      { Header: "name", accessor: "name", width: "20%" },
      { Header: "description", accessor: "description", width: "60%" },
      {
        Header: "members",
        accessor: "membersAmount",
        width: "20%",
        paddingHeader: {
          right: 3
        },
        Cell: (info: any) => {
          const [active, nonActive] = info.cell.row.original.members?.reduce(
            (acc: [ForgeOrganizationMember[], ForgeOrganizationMember[]], item: ForgeOrganizationMember) => {
              if (item.status === "active") {
                acc[0].push(item) // Matches the condition
              } else {
                acc[1].push(item) // Doesn't match the condition
              }
              return acc
            },
            [[], []]
          )

          return (
            <MDBox>
              {active?.length ?? 0} {nonActive.length > 0 && `(${nonActive.length} Non-active members)`}
            </MDBox>
          )
        }
      }
    ],

    rows: tableData
  }

  return (
    <MDBox mb={3}>
      <RoleDrawer openDrawer={openDrawer} handleClose={handleCloseDrawer} role={selectedRole} />
      <Card id="roles">
        <MDBox p={3} lineHeight={1} display="flex" justifyContent="space-between">
          <MDTypography variant="h5" fontWeight="medium">
            Roles
          </MDTypography>
        </MDBox>
        <DataTable
          tableId="teams"
          table={dataTableData}
          enablePagination={false}
          canSearch={true}
          onRowClick={(row, index) => {
            setSelectedRole(row.original)
            handleOpenDrawer()
          }}
        />
      </Card>
    </MDBox>
  )
}

export default OrganizationRoles
