import React, { useEffect, useState } from "react"

const useSortDirection = () => {
  const [sortOption, setSortOption] = useState(window.localStorage.getItem("sortCalls") ?? "asc")

  const handleSortChange = (currentOption: string = "desc") => {
    const newSort = currentOption === "desc" ? "asc" : "desc"
    setSortOption(newSort)
    window.localStorage.setItem("sortCalls", newSort)
  }

  useEffect(() => {
    const sortLocalStorage = window.localStorage.getItem("sortCalls")
    if (sortLocalStorage) setSortOption(sortLocalStorage)
    else handleSortChange()
  }, [])

  return { sortOption, handleSortChange }
}

export default useSortDirection
