import { useContext } from "react"

// @mui material components
import Card from "@mui/material/Card"
import Grid from "@mui/material/Grid"

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox"
import MDTypography from "components/MDTypography"
import { AuthContext } from "context"
import { Avatar } from "@mui/material"
import { isEmpty } from "lodash"

function SettingsHeader(): JSX.Element {
  // Context
  const { getCurrentUser } = useContext(AuthContext)
  const { userProfileData } = getCurrentUser()

  return (
    <Card id="profile">
      <MDBox p={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Avatar
              src={userProfileData?.profilePicture}
              style={{
                color: "white",
                backgroundColor: "black",
                fontSize: "1rem"
              }}
            >
              {userProfileData?.firstName && userProfileData?.firstName[0]}
              {userProfileData?.lastName && userProfileData?.lastName[0]}
            </Avatar>
          </Grid>
          <Grid item>
            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {!isEmpty(userProfileData?.preferredName) ? userProfileData?.preferredName : userProfileData?.firstName} {userProfileData?.lastName}
              </MDTypography>
              <MDTypography variant="button" color="text" fontWeight="medium">
                {userProfileData?.company}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  )
}

export default SettingsHeader
