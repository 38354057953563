import * as React from "react"
import Dialog from "@mui/material/Dialog"
import Slide from "@mui/material/Slide"
import { TransitionProps } from "@mui/material/transitions"
import { Can } from "forge/organization/rbac/CanContext"
import SubscriptionFlow from "./SubscriptionFlow"
import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"
import { Close } from "@mui/icons-material"

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function BillingDialog({ openDialog, handleCloseDialog }: { openDialog: boolean; handleCloseDialog: () => void }) {
  const billingLackOfPermissions = (
    <MDBox style={{ padding: "0px 24px 24px 24px", alignSelf: "center" }}>
      <MDTypography variant="button" color="text">
        Contact one of your organization's admins to manage billing.
      </MDTypography>
    </MDBox>
  )

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="xl" // Options: 'xs', 'sm', 'md', 'lg', 'xl'
      fullWidth={true}
    >
      <Close style={{ marginTop: "24px", marginRight: "20px", alignSelf: "end", cursor: "pointer" }} onClick={() => handleCloseDialog()} />
      <Can I="view" this={"billing"} passThrough>
        {allowed => (allowed ? <SubscriptionFlow /> : billingLackOfPermissions)}
      </Can>
    </Dialog>
  )
}
