export class UserStats {
  contactsAmount?: number
  insightsAmount?: number
  calendarsAmount?: number
  accessToCalendar?: number
  eventsInCalendar?: number
  timeBlocksInCalendar?: number
  meetingsInCalendar?: number
  goalsAmount?: number
  privacyPolicyFirstAcceptance?: Date
  privacyPolicyLatestAcceptance?: Date
  termsAndConditionsFirstAcceptance?: Date
  termsAndConditionsLatestAcceptance?: Date

  constructor({
    contactsAmount,
    insightsAmount,
    calendarsAmount,
    accessToCalendar,
    eventsInCalendar,
    timeBlocksInCalendar,
    meetingsInCalendar,
    goalsAmount,
    privacyPolicyFirstAcceptance,
    privacyPolicyLatestAcceptance,
    termsAndConditionsFirstAcceptance,
    termsAndConditionsLatestAcceptance
  }: {
    contactsAmount?: number
    insightsAmount?: number
    calendarsAmount?: number
    accessToCalendar?: number
    eventsInCalendar?: number
    timeBlocksInCalendar?: number
    meetingsInCalendar?: number
    goalsAmount?: number
    privacyPolicyFirstAcceptance?: Date
    privacyPolicyLatestAcceptance?: Date
    termsAndConditionsFirstAcceptance?: Date
    termsAndConditionsLatestAcceptance?: Date
  } = {}) {
    this.contactsAmount = contactsAmount
    this.insightsAmount = insightsAmount
    this.calendarsAmount = calendarsAmount
    this.accessToCalendar = accessToCalendar
    this.eventsInCalendar = eventsInCalendar
    this.timeBlocksInCalendar = timeBlocksInCalendar
    this.meetingsInCalendar = meetingsInCalendar
    this.goalsAmount = goalsAmount
    this.privacyPolicyFirstAcceptance = privacyPolicyFirstAcceptance
    this.privacyPolicyLatestAcceptance = privacyPolicyLatestAcceptance
    this.termsAndConditionsFirstAcceptance = termsAndConditionsFirstAcceptance
    this.termsAndConditionsLatestAcceptance = termsAndConditionsLatestAcceptance
  }

  copyWith({
    contactsAmount,
    insightsAmount,
    calendarsAmount,
    accessToCalendar,
    eventsInCalendar,
    timeBlocksInCalendar,
    meetingsInCalendar,
    goalsAmount,
    privacyPolicyFirstAcceptance,
    privacyPolicyLatestAcceptance,
    termsAndConditionsFirstAcceptance,
    termsAndConditionsLatestAcceptance
  }: {
    contactsAmount?: number
    insightsAmount?: number
    calendarsAmount?: number
    accessToCalendar?: number
    eventsInCalendar?: number
    timeBlocksInCalendar?: number
    meetingsInCalendar?: number
    goalsAmount?: number
    privacyPolicyFirstAcceptance?: Date
    privacyPolicyLatestAcceptance?: Date
    termsAndConditionsFirstAcceptance?: Date
    termsAndConditionsLatestAcceptance?: Date
  } = {}): UserStats {
    return new UserStats({
      contactsAmount: contactsAmount ?? this.contactsAmount,
      insightsAmount: insightsAmount ?? this.insightsAmount,
      calendarsAmount: calendarsAmount ?? this.calendarsAmount,
      accessToCalendar: accessToCalendar ?? this.accessToCalendar,
      eventsInCalendar: eventsInCalendar ?? this.eventsInCalendar,
      timeBlocksInCalendar: timeBlocksInCalendar ?? this.timeBlocksInCalendar,
      meetingsInCalendar: meetingsInCalendar ?? this.meetingsInCalendar,
      goalsAmount: goalsAmount ?? this.goalsAmount,
      privacyPolicyFirstAcceptance: privacyPolicyFirstAcceptance ?? this.privacyPolicyFirstAcceptance,
      privacyPolicyLatestAcceptance: privacyPolicyLatestAcceptance ?? this.privacyPolicyLatestAcceptance,
      termsAndConditionsFirstAcceptance: termsAndConditionsFirstAcceptance ?? this.termsAndConditionsFirstAcceptance,
      termsAndConditionsLatestAcceptance: termsAndConditionsLatestAcceptance ?? this.termsAndConditionsLatestAcceptance
    })
  }

  toMap(): { [key: string]: any } {
    return {
      contactsAmount: this.contactsAmount,
      insightsAmount: this.insightsAmount,
      calendarsAmount: this.calendarsAmount,
      accessToCalendar: this.accessToCalendar,
      eventsInCalendar: this.eventsInCalendar,
      timeBlocksInCalendar: this.timeBlocksInCalendar,
      meetingsInCalendar: this.meetingsInCalendar,
      goalsAmount: this.goalsAmount,
      privacyPolicyFirstAcceptance: this.privacyPolicyFirstAcceptance?.getTime(),
      privacyPolicyLatestAcceptance: this.privacyPolicyLatestAcceptance?.getTime(),
      termsAndConditionsFirstAcceptance: this.termsAndConditionsFirstAcceptance?.getTime(),
      termsAndConditionsLatestAcceptance: this.termsAndConditionsLatestAcceptance?.getTime()
    }
  }

  static fromMap(map: { [key: string]: any }): UserStats {
    return new UserStats({
      contactsAmount: map["contactsAmount"] != null ? map["contactsAmount"] : undefined,
      insightsAmount: map["insightsAmount"] != null ? map["insightsAmount"] : undefined,
      calendarsAmount: map["calendarsAmount"] != null ? map["calendarsAmount"] : undefined,
      accessToCalendar: map["accessToCalendar"] != null ? map["accessToCalendar"] : undefined,
      eventsInCalendar: map["eventsInCalendar"] != null ? map["eventsInCalendar"] : undefined,
      timeBlocksInCalendar: map["timeBlocksInCalendar"] != null ? map["timeBlocksInCalendar"] : undefined,
      meetingsInCalendar: map["meetingsInCalendar"] != null ? map["meetingsInCalendar"] : undefined,
      goalsAmount: map["goalsAmount"] != null ? map["goalsAmount"] : undefined,
      privacyPolicyFirstAcceptance: map["privacyPolicyFirstAcceptance"] != null ? new Date(map["privacyPolicyFirstAcceptance"]) : undefined,
      privacyPolicyLatestAcceptance: map["privacyPolicyLatestAcceptance"] != null ? new Date(map["privacyPolicyLatestAcceptance"]) : undefined,
      termsAndConditionsFirstAcceptance:
        map["termsAndConditionsFirstAcceptance"] != null ? new Date(map["termsAndConditionsFirstAcceptance"]) : undefined,
      termsAndConditionsLatestAcceptance:
        map["termsAndConditionsLatestAcceptance"] != null ? new Date(map["termsAndConditionsLatestAcceptance"]) : undefined
    })
  }
}
