import { Avatar, Chip } from "@mui/material"
import { CloseRounded } from "@mui/icons-material"

export function ContactChip({ contact, onContactRemoved }: { contact: any; onContactRemoved: (contactRemoved: any) => void }) {
  return (
    <Chip
      label={
        <div
          style={{
            display: "-webkit-box",
            overflow: "hidden",
            WebkitLineClamp: 1
          }}
        >
          {contact.name}
        </div>
      }
      style={{ backgroundColor: "white", color: "black", marginTop: 4, border: "1px solid black" }}
      onDelete={() => onContactRemoved(contact)}
      deleteIcon={
        <Avatar style={{ background: "black", height: "20px", width: "20px" }}>
          <CloseRounded style={{ color: "white", height: "16px", width: "16px" }} />
        </Avatar>
      }
    />
  )
}
