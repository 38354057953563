// MUI
import { Icon, Paper, Stack } from "@mui/material"

// Material Dashboard
import MDTypography from "components/MDTypography"
import MDBox from "components/MDBox"
import { parseMilestone } from "forge/core/knowledgeParsing"
import { ForgeAvatarGroup } from "forge/core/components/ForgeAvatar"
import { useContext } from "react"
import { ForgeEvent } from "types/forge-event"
import MDButton from "components/MDButton"
import { AlertsContext } from "forge/home/services/AlertsContext"

function AlertCard({ alert }: { alert: ForgeEvent }): JSX.Element {
  // Context
  const { createContactFromAlert } = useContext(AlertsContext)

  return (
    <MDBox mb={2}>
      <Paper elevation={2} style={{ borderRadius: 12 }}>
        <MDBox width="100%" display="flex" flexDirection="column" bgColor={"grey-100"} p={2}>
          <Stack direction="column">
            <MDBox lineHeight={0}>
              <MDTypography variant="h6" fontWeight="medium">
                {parseMilestone(alert)}
              </MDTypography>
            </MDBox>
            {alert.contacts?.length > 0 && (
              <MDBox mb={1} lineHeight={0}>
                <MDTypography variant="caption">Related to {alert.contacts[0]?.name}</MDTypography>
              </MDBox>
            )}
          </Stack>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
          >
            {alert.contacts?.length > 0 && (
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <ForgeAvatarGroup contacts={alert.contacts} />
              </MDBox>
            )}
            {alert.contacts?.length !== 0 && (alert as any).type === "AlertType.contactFound" && (
              <MDButton
                variant="text"
                color={"info"}
                onClick={() => createContactFromAlert(alert)}
                size="small"
                style={{ textTransform: "none", fontWeight: "400", padding: 0, fontSize: "0.875rem" }}
              >
                Add {alert.contacts[0]?.name}&nbsp;<Icon>arrow_forward</Icon>
              </MDButton>
            )}
          </MDBox>
        </MDBox>
      </Paper>
    </MDBox>
  )
}

// Setting default values for the props of AlertCard
AlertCard.defaultProps = {
  alert: null
}

export default AlertCard
