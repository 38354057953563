import { collection, onSnapshot, Unsubscribe } from "firebase/firestore"
import { firestoreDb } from "firebase.init"
import { User } from "firebase/auth"
import { UserProfileData } from "types/user/user-profile-data"
import { ForgeOrganizationMember } from "forge/organization/types/member"
import { ForgeEncryption } from "forge/core/services/encryption"

class OrganizationMembersFirestore {
  private user: User
  private userProfileData: UserProfileData
  private encryptionService: ForgeEncryption

  constructor(user: User, userProfileData: UserProfileData, encryptionService: ForgeEncryption) {
    this.user = user
    this.userProfileData = userProfileData
    this.encryptionService = encryptionService
  }

  getMembersLive = (onEvent: (members: ForgeOrganizationMember[]) => void): Unsubscribe => {
    const docRef = collection(firestoreDb, `organizations/${this.userProfileData.organization?.id}/members`)

    return onSnapshot(docRef, async snapshot => {
      const members = snapshot.docs
        .map(doc => {
          try {
            return ForgeOrganizationMember.fromFirestore(doc)
          } catch (error) {
            return null
          }
        })
        .filter(e => e)
      return onEvent(members)
    })
  }
}

export default OrganizationMembersFirestore
