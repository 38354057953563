import { collection, DocumentChange, DocumentData, onSnapshot, Unsubscribe } from "firebase/firestore"
import { firestoreDb } from "firebase.init"
import { User } from "firebase/auth"
import { UserProfileData } from "types/user/user-profile-data"
import { ForgeOrganizationTeam } from "forge/organization/types/team"
import { ForgeEncryption } from "forge/core/services/encryption"

class OrganizationTeamsFirestore {
  private user: User
  private userProfileData: UserProfileData
  private encryptionService: ForgeEncryption

  constructor(user: User, userProfileData: UserProfileData, encryptionService: ForgeEncryption) {
    this.user = user
    this.userProfileData = userProfileData
    this.encryptionService = encryptionService
  }

  getTeamsLive = (onEvent: (teams: ForgeOrganizationTeam[], changes: DocumentChange<DocumentData, DocumentData>[]) => void): Unsubscribe => {
    const docRef = collection(firestoreDb, `organizations/${this.userProfileData.organization?.id}/teams`)

    return onSnapshot(docRef, async snapshot => {
      const teams = snapshot.docs.map(doc => ForgeOrganizationTeam.fromFirestore(doc))
      return onEvent(teams, snapshot.docChanges())
    })
  }
}

export default OrganizationTeamsFirestore
