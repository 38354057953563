import { DocumentReference, Timestamp } from "firebase/firestore"

export abstract class ForgeEvent {
  ref?: DocumentReference
  name: string
  startDate: Timestamp
  endDate?: Timestamp
  contacts?: any[]
  opportunityId?: string
  isProcessing: boolean

  constructor(
    name: string,
    startDate: Timestamp,
    isProcessing: boolean,
    ref?: DocumentReference,
    endDate?: Timestamp,
    contacts?: any[],
    opportunityId?: string
  ) {
    this.name = name
    this.startDate = startDate
    this.isProcessing = isProcessing
    this.ref = ref
    this.endDate = endDate
    this.contacts = contacts
    this.opportunityId = opportunityId
  }
}
