export const CARDS_TOOLTIPS = {
  RESEARCH: {
    description:
      "The Research card provides a quick and comprehensive summary of a contact's professional background, leveraging data gathered from their LinkedIn profile. With this feature, you can engage contacts with a deeper understanding of their professional journey and interests.",
    benefits:
      "Get a concise summary of a contact's career history, skills, and expertise. Identify mutual connections and shared interests for more personalized outreach. Keep track of any career changes, new skills, or recent achievements directly from LinkedIn."
  },
  LISTEN: {
    description:
      "The Listen card offers a curated list of insightful questions designed to help you learn more about your contacts and build stronger connections. You’ll have the right questions at your fingertips to create more authentic and impactful interactions.",
    benefits:
      "Choose from a variety of questions to spark meaningful conversations and discover shared interests. Use targeted questions to deepen your understanding of their goals, challenges, and aspirations. Show genuine interest in your contacts' perspectives, helping to strengthen trust and foster long-term connections."
  },
  RECALL: {
    description:
      "The Recall card gives you easy access to all the information you've previously gathered about a contact, helping you stay informed and prepared for every interaction, ensuring that you’re always prepared with accurate, personalized information to enhance your interactions and build lasting relationships.",
    benefits:
      "Quickly review key details such as career history, interests, and past conversations, all in one place. Refresh your memory on the contact's preferences, goals, and challenges to tailor your approach. Demonstrate attentiveness by recalling important details."
  },
  COMMITMENTS: {
    description: "The Commitments card helps you stay organized and accountable by tracking tasks or actions you've promised to take for a contact.",
    benefits:
      "Easily record and manage commitments you've made to a contact. Stay on top of your promises to maintain trust and demonstrate reliability in your relationships."
  },
  CONVERSATIONS: {
    description:
      "The Conversations card serves as a central space to log past discussions and manage upcoming calls or meetings with your contact, you can build deeper relationships by staying organized and always being ready for your next interaction",
    benefits:
      "Log key details of your past conversations to maintain continuity and context for future interactions. View and manage upcoming calls, meetings, or follow-ups with your contact, so you’re always prepared."
  },
  ICEBREAKERS: {
    description:
      "The Icebreakers card surfaces relevant news and updates related to your contact’s interests, industry, or background, helping you start conversations with ease, you can effortlessly stay connected, spark engaging conversations, and create a positive, thoughtful impression through timely, personalized content.",
    benefits:
      "Use fresh news and insights as conversation starters to break the ice and build rapport effortlessly. Share articles, updates, or stories that show you’re keeping track of their world, reinforcing your commitment to the relationship. "
  },
  MILESTONES: {
    description:
      "The Milestones card helps you keep track of important dates and events in your contact's life, such as birthdays, work anniversaries, or other significant achievements.",
    benefits:
      "Track important milestones like birthdays, anniversaries, and achievements to show you care. Acknowledge these events with thoughtful gestures, and use them to create personalized messages or offers, making your interactions feel more meaningful and sincere."
  }
}
