import { Add, Remove } from "@mui/icons-material"
import { Avatar, Button, Menu, MenuItem, Stack, TextField, capitalize } from "@mui/material"
import MDTypography from "components/MDTypography"
import PlacesAutocomplete from "forge/core/components/PlacesAutocomplete"
import { useEffect, useState } from "react"
import { ContactField } from "types/contact/contact-field"

export interface ContactFieldType {
  type: string
  addText: string
  hint: string
  defaultOption: string
  options: string[]
  inputType?: "none" | "text" | "tel" | "url" | "email" | "numeric" | "decimal" | "search" | undefined
}

function EditContactMultiField({
  fieldType,
  existingValues,
  onEdited
}: {
  fieldType: ContactFieldType
  existingValues: any[]
  onEdited: (values: ContactField[]) => void
}) {
  const [values, setValues] = useState<ContactField[]>(existingValues)

  useEffect(() => {
    onEdited(values)
  }, [values])

  const addNewField = () => {
    setValues([
      ...values,
      new ContactField({
        favorite: false,
        favoriteType: null,
        fieldType: fieldType.type,
        sourceValue: null,
        type: fieldType.defaultOption,
        value: null
      })
    ])
  }

  const removeField = (indexToRemove: number) => {
    setValues(values.filter((_, index) => index !== indexToRemove))
  }

  const onFieldEdited = (value: ContactField, index: number) => {
    // Clone the original array
    const updatedValues = [...values]
    // Update the value property of the item at the specified index
    updatedValues[index] = value
    setValues(updatedValues)
  }

  return (
    <Stack direction="column" spacing={1} mt={1}>
      {values.map((e, index) => (
        <EditField key={index} onRemove={() => removeField(index)} setValue={value => onFieldEdited(value, index)} fieldType={fieldType} value={e} />
      ))}
      <AddField onAdd={addNewField} fieldType={fieldType} />
    </Stack>
  )
}

function AddField({ onAdd, fieldType }: { onAdd: () => void; fieldType: ContactFieldType }) {
  return (
    <Stack direction="row" spacing={1} alignItems="center" style={{ marginTop: 16, cursor: "pointer" }} onClick={onAdd}>
      <Avatar sx={{ width: 24, height: 24 }} style={{ background: "cornflowerblue" }}>
        <Add />
      </Avatar>
      <MDTypography pl={1} variant="h6" fontWeight="regular">
        {fieldType.addText}
      </MDTypography>
    </Stack>
  )
}

function EditField({
  onRemove,
  setValue,
  fieldType,
  value
}: {
  onRemove: () => void
  setValue: (newValue: ContactField) => void
  fieldType: ContactFieldType
  value: ContactField
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const [type, setType] = useState<string>(value?.type ?? fieldType.defaultOption)
  const onTypeEdited = (type?: string) => {
    if (type) {
      setType(type)
      value.type = type
      setValue(value)
    }
    setAnchorEl(null)
  }

  const onValueEdited = (newValue: string) => {
    value.value = newValue
    setValue(value)
    handleValidation(newValue)
  }

  const [error, setError] = useState(false)
  const handleValidation = (value: string) => {
    let regex
    switch (fieldType.type) {
      case "email":
        regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        break
      case "phone":
        regex = /^[0-9+()-]*$/
        break
      default:
        return
    }

    setError(!regex.test(value))
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center" style={{ marginTop: 8 }}>
      <Avatar sx={{ width: 24, height: 24 }} style={{ background: "black", cursor: "pointer" }} onClick={onRemove}>
        <Remove />
      </Avatar>
      <div>
        <Button
          id="basic-button"
          size="small"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          style={{
            paddingLeft: 5,
            paddingRight: 5,
            fontSize: "0.9rem",
            width: "80px",
            textTransform: "none"
          }}
        >
          {capitalize(type)}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => onTypeEdited()}
          MenuListProps={{
            "aria-labelledby": "basic-button"
          }}
        >
          {fieldType.options.map(e => (
            <MenuItem onClick={() => onTypeEdited(e)}>{capitalize(e)}</MenuItem>
          ))}
        </Menu>
      </div>
      {fieldType.inputType ? (
        <TextField
          variant="outlined"
          placeholder={fieldType.hint}
          style={{ width: "100%" }}
          value={value.value}
          inputMode={fieldType.inputType}
          // type={fieldType.inputType === "tel" ? "number" : null}
          onChange={e => onValueEdited(e.target.value)}
          error={error}
          helperText={error ? `Invalid ${fieldType.type}` : ""}
        />
      ) : (
        <PlacesAutocomplete
          onSelection={place => onValueEdited(place.structured_formatting.main_text)}
          enableLabel={false}
          label={fieldType.hint}
          initialValue={
            value.value
              ? {
                  description: value.value,
                  structured_formatting: {
                    main_text: value.value,
                    secondary_text: ""
                  }
                }
              : null
          }
        />
      )}
    </Stack>
  )
}

// Setting default values for the props of EditContactMultiField
EditContactMultiField.defaultProps = {
  handleCloseDrawer: null
}

export default EditContactMultiField
