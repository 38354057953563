import { ForgeOpportunity } from "types/pipeline/opportunity"
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions"
import { Call } from "types/pipeline/call"
import { UserProfileData } from "types/user/user-profile-data"

class CallsApi {
  private userProfileData: UserProfileData

  constructor(userProfileData: UserProfileData) {
    this.userProfileData = userProfileData
  }

  createCall = async (call: Call, opportunity?: ForgeOpportunity): Promise<any> => {
    const functions = getFunctions()
    if (process.env.REACT_APP_DEV === "true") {
      connectFunctionsEmulator(functions, "0.0.0.0", 5001)
    }
    const callable = httpsCallable(functions, "pipeline-createcall")

    let result
    try {
      result = await callable({
        call: call.toMap(),
        opportunity: opportunity?.toMap(),
        integration: this.userProfileData.pipelineUserConfig.integration
      })
    } catch (error) {
      console.error(error)
    }

    return result?.data
  }

  updateCall = async (call: Call, opportunity?: ForgeOpportunity): Promise<any> => {
    const functions = getFunctions()
    if (process.env.REACT_APP_DEV === "true") {
      connectFunctionsEmulator(functions, "0.0.0.0", 5001)
    }
    const callable = httpsCallable(functions, "pipeline-updatecall")

    let result
    try {
      result = await callable({
        call: call.toMap(),
        opportunity: opportunity?.toMap(),
        integration: this.userProfileData.pipelineUserConfig.integration
      })
    } catch (error) {
      console.error(error)
    }

    return result?.data
  }

  deleteCall = async (call: Call): Promise<boolean> => {
    const functions = getFunctions()
    if (process.env.REACT_APP_DEV === "true") {
      connectFunctionsEmulator(functions, "0.0.0.0", 5001)
    }
    const callable = httpsCallable(functions, "pipeline-deletecall")

    try {
      let result = await callable({
        call: call.toMap(),
        integration: this.userProfileData.pipelineUserConfig.integration
      })
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }
}

export default CallsApi
